import { Button, Modal, Pagination, Table } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import SubHeading from '../../../components/SubHeading'
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { ErrorMessage, Field, Formik,Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import {PaginationTheme} from '../../../theme/PaginationTheme';
const API_URL = process.env.REACT_APP_API_URL;
function Fabrics() {

    const [openModal , setOpenModal] = useState(false);
    const [search , setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [data, setData] = useState([ ]);
    const [recordId , setRecordId] = useState('');



      const onPageChange = (page) => {
        setCurrentPage(page);
        getRecord(page);
      };
    
      const getRecord = async (page) => {
        try {
          const response = await axios.get(`${API_URL}/api/fabrics`, {
            params: {
              page,
              pageSize,
              search,
            },
          });
          setData(response.data.data);
    
          // Calculate total pages based on the total items and page size
          const totalItems = response.data.totalItems; // Replace with the actual key in your API response
          const calculatedTotalPages = Math.ceil(totalItems / pageSize);
          setTotalPages(calculatedTotalPages);
        } catch (error) {
          console.error(error);
        }
      };
    
      useEffect(() => {
        getRecord(currentPage);
      }, [currentPage, pageSize, search]);


      const deleteData = async (id) => {
        try {
          const response = await axios.post(`${API_URL}/api/fabrics/delete`, {
            id: id,
          });
          
          if (response.status === 200) {
            toast.success("Record Deleted Successfully", {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          } else {
            console.error("Deletion failed with status:", response.status);
          }
          setOpenModal(false);
          getRecord(currentPage);
    
    
        } catch (error) {
          console.error(error);
        }
      };


  return (
    <div>



<Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
<Modal.Header className='dark:bg-white dark:text-black'  />
<Modal.Body className='dark:bg-white dark:text-black'>
  <div className="text-center">
    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-black" />
    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
      Are you sure you want to remove this record?
    </h3>
    <div className="flex justify-center gap-4">
      <Button color="failure"onClick={() => { deleteData(recordId); setOpenModal(false)}}>
        {"Yes, I'm sure"}
      </Button>
      <Button color="gray" onClick={() => setOpenModal(false)}>
        No, cancel
      </Button>
    </div>
  </div>
</Modal.Body>
</Modal>


            <SubHeading title={'Fabrics' } />


<div className='px-10'>
            <Formik initialValues={
                {
                    name:''
                }
            }
            validationSchema={
              Yup.object({
                  name: Yup.string().required('Name is required')
              })
          }
            onSubmit={async (values, { resetForm }) => {
              console.log(values);
              try {
                const response = await axios.post(`${API_URL}/api/fabrics`, {
                  data: {
                    name: values.name
                  }
                });
            
                if (response.status === 200 && response.data.message === "Saved Successfully") {
                  resetForm();
                  getRecord(currentPage);
                  toast.success("Saved Successfully", {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                  });
                } else if (response.status === 200 && response.data.message === "Data already exists") {
                  toast.error("Data already exists", {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                  });
                } else {
                  toast.error("Failed to Save", {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                  });
                }
              } catch (error) {
                console.error(error);
                // Handle error (e.g., network issue, server error)
                toast.error("Failed to Save. Please try again.", {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              }
            }}
            >
                <Form >


 
<Table className='mb-10'>

<Table.Head>
<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Enter Fabrics</Table.HeadCell>
<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Action</Table.HeadCell> 
</Table.Head>
<Table.Body className="divide-y">
<Table.Row className="bg-white dark:border-gray-200 dark:bg-gray-300 dark:text-black">
 

 <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black  w-[20%]">


  <Field name="name"  type="text" className=' bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:border-blue-500 block w-full p-2.5 '/>
    <ErrorMessage name="name" component="div" className="text-red-500" />
  </Table.Cell>

  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
  <Button type='submit' className='flex justify-left mt-2 mb-2 bg-blue-600 dark:bg-blue-600 hover:bg-blue-700 text-white rounded w-20 h-10'>save</Button>

  </Table.Cell>

 
</Table.Row>

</Table.Body>
</Table>


</Form>
</Formik>
</div>

<div className='px-10'>

<div className='flex  justify-end'>

<div className='mb-8 w-96 '>

    <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-black">Search</label>
    <div class="relative">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input value={search} onChange={(e) => { setSearch(e.target.value); setCurrentPage(1)  }} type="search" id="default-search" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-white dark:border-gray-200 dark:placeholder-gray-400 dark:text-black " placeholder="Search..." required/>
      
    </div>

</div>

    </div>

            <Table className=' dark:text-black'>
 
    <Table.Head>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Name</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Created At</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'></Table.HeadCell>
    </Table.Head>
    <Table.Body className="divide-y">
      {data.map((row, index) => (
        <Table.Row  className="bg-white dark:border-gray-200 dark:bg-gray-300">
          
            <Table.Cell>
              {row.name}
            </Table.Cell>
            <Table.Cell>
              {row.createdat}
            </Table.Cell>
                    
            <Table.Cell>
            <button
              onClick={() => { setRecordId(row._id); setOpenModal(true)}}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 rounded"
            >
             Delete
            </button>
            </Table.Cell>        
        </Table.Row>
       ))} 
     
    
    </Table.Body>
  </Table>

  </div>

  <Pagination theme={PaginationTheme} currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
   

    </div>
  )
}

export default Fabrics