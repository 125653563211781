// MarketDispatch
import React, { useEffect, useState } from 'react'
import SubHeading from '../../components/SubHeading'
import Card from '../../components/Card'
import { Tabs,TextInput,Button,Table,Dropdown, Pagination, Modal } from 'flowbite-react';
import SearchInput from '../../components/SearchInput'
import { IoMdQrScanner } from "react-icons/io";
import axios from 'axios';
import { PaginationTheme } from '../../theme/PaginationTheme';
import { ModalTheme } from '../../theme/ModalTheme';
import useScanDetection from 'use-scan-detection-react18';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { Article } from '@mui/icons-material';
import formatDateTime from '../../helpers/Datetime';
import { jsPDF } from 'jspdf'; 
import autoTable from 'jspdf-autotable';
import { FaRegFilePdf } from 'react-icons/fa';
const API_URL = process.env.REACT_APP_API_URL;
function MarketDispatch() {

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [data, setData] = useState([]);
    const [openModal , setOpenModal] = useState(false);
  
  
    const [issuedround, setIssuedround] = useState(0);
    const [pendinground, setPendinground] = useState(0);
  
  
    const [todayissuedround, settodayIssuedround] = useState(0);
    const [todaypendinground, settodayPendinground] = useState(0);
  
  
    const [monthissuedround, setmonthIssuedround] = useState(0);
    const [monthpendinground, setmonthPendinground] = useState(0);
  
  
    // Function to handle page change
    const onPageChange = (page) => {
      setCurrentPage(page);
    };
  
  
  const getRecords = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/marketdispatch`, {
        params: {
          page: currentPage,
          pageSize: pageSize,
        },
      });
  
      setData(response.data.data);
  
      setIssuedround(response.data.issuedround);
      setPendinground(response.data.pendinground);
     
      settodayIssuedround(response.data.todayissuedround);
      settodayPendinground(response.data.todaypendinground);
     
      setmonthIssuedround(response.data.monthissuedround);
      setmonthPendinground(response.data.monthpendinground);
  
      // Calculate total pages based on the total items and page size
      const totalItems = response.data.totalItems; // Replace with the actual key in your API response
      const calculatedTotalPages = Math.ceil(totalItems / pageSize);
      setTotalPages(calculatedTotalPages);
    } catch (error) {
      console.error(error);
    }
  };
  
  
  useEffect(() => {
    getRecords();
  }, [currentPage, pageSize]);
  
  
  
  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  
  const d = new Date();
  let monthname = month[d.getMonth()];
  
  
  useScanDetection({
    onComplete: async (barcode) =>  {
      if(openModal){
     const Code = barcode.replace(/Shift/g, '');
     //splite Code by ,
     const data = Code.split(',');
     const bookid = data[0];
     const designid = data[1];
     const id = data[2];
     const issueno = data[3];
     
    
  
     await  axios.post(`${API_URL}/api/marketdispatch/assign`, {bookid,designid,id,issueno})
     .then((response) => {
  
    
      setOpenModal(true)
     toast.success("Issued Successfully");
     getRecords()
     })
     .catch((error) => {
      console.log(error)
        toast.error(error.response.data.error)
      });
    }
    else{toast.error("Scanner not activated")}
    },
  });
  
  const columns = [
    { header: 'B#', size: 120 },
    { header: 'D#', size: 120 },
    { header: 'Fabric', size: 120 },
    { header: 'Articles', size: 120 },
    { header: 'Article Type', size: 120 },
    { header: 'Created', size: 160 },
    { header: 'Round Number', size: 180 },
    { header: 'Status', size: 140 },
    { header: 'Total Received', size: 200 },
    { header: 'Total Cutting Issued', size: 160 },
    { header: 'Per Round', size: 160 },
    { header: 'Total', size: 160 },
    { header: 'Check', size: 160 },
  ];
  
  const handleExportRows = (rows, bName) => {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a2',
    });
  
    doc.setFontSize(22);
    
    const pageWidth = doc.internal.pageSize.getWidth();
    const title = "Market";
    const titleTextWidth = doc.getTextWidth(title);
    const titleXOffset = (pageWidth - titleTextWidth) / 2;
    doc.text(title, titleXOffset, 25);
  
    // Process table data
    const tableData = rows.map((row) => {
      return row.createdat_array.map((createdAt, index) => [
        index === 0 ? row._id.bookid : '', // B#
        row.designid.indexOf(row.designid[index]) === index ? row.designid[index] : '', // D#
        row.data_Fabric.indexOf(row.data_Fabric[index]) === index ? row.data_Fabric[index] : '', // Fabric
        row.data_Article.indexOf(row.data_Article[index]) === index ? row.data_Article[index] : '', // Articles
        row.data_Article_type.indexOf(row.data_Article_type[index]) === index ? row.data_Article_type[index] : '', // Article Type
        formatDateTime(createdAt, 'DD/MM/YYYY hh:mm A'), // Created
        row.roundno_array[index], // Round Number
        row.status_array[index] === "Active"
          ? "Out"
          : row.status_array[index] === "Pending"
          ? "In"
          : "Out", // Status
        row.data_issued_round.indexOf(row.data_issued_round[index]) === index
          ? row.data_issued_round[index]
          : '', // Total Received
        index === 0 ? row.count : '', // Total Cutting Issued
        row.data_PerSuit.indexOf(row.data_PerSuit[index]) === index
          ? row.data_PerSuit[index]
          : '', // Per Round
        row.data_PerSuit.indexOf(row.data_PerSuit[index]) === index
          ? row.roundno_array.length * row.data_PerSuit[index]
          : '', // Total
        row.check_array[index], // Check (you can customize how this will appear)
      ]);
    }).flat();
  
    // Column headers
    const tableHeaders = columns.map((c) => c.header);
  
    // Generate table with autoTable
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: 30,
      styles: {
        overflow: 'linebreak',
        columnWidth: 'wrap',
      },
      columnStyles: {
        2: { columnWidth: 'auto' },
      },
    });
  
    // Save the PDF
    doc.save(`${bName}.pdf`);
  };

  return (
    <div>
    <SubHeading title={'Market Store'} />
  
  
    <Modal theme={ModalTheme} show={openModal} size="md" onClose={() => setOpenModal
  (false)} popup>
        <Modal.Header className=''  />
        <Modal.Body className=''>
        <p className='text-2xl text-center font-bold mb-5'>SCAN BARCODE</p>
        <div className='flex justify-center'>
          <img src='/gif/scanner.gif' height={300} width={300} />
        </div> 
        </Modal.Body>
      </Modal>
  
  
  
  
  
      <div className='flex justify-end  mb-2 text-lg mr-10 '>
      <ul className='text-left flex gap-5 bg-white rounded-lg p-5'>
        <li>{monthname} :  </li>
        <li><span  className='font-bold p-2 text-white bg-green-500 rounded-lg'> {monthissuedround} </span> </li>
        <li><span  className='font-bold p-2  text-white bg-red-500 rounded-lg'> {monthpendinground} </span> </li>
        <li><span  className='font-bold p-2  text-white bg-gray-500 rounded-lg'> {monthpendinground+monthissuedround} </span> </li>
      </ul> 
  </div>
  
  <div className='flex justify-end  mb-2 text-lg mr-10 '>
      <ul className='text-left flex gap-5 bg-white rounded-lg p-5'>
        <li>Today :  </li>
        <li><span  className='font-bold p-2 text-white bg-green-500 rounded-lg'> {todayissuedround} </span> </li>
        <li><span  className='font-bold p-2  text-white bg-red-500 rounded-lg'> {todaypendinground} </span> </li>
        <li><span  className='font-bold p-2  text-white bg-gray-500 rounded-lg'> {todaypendinground+todayissuedround} </span> </li>
      </ul>
      
  </div>
  
  
  <div className='flex justify-end  mb-5 text-lg mr-10 '>
      <ul className='text-left flex gap-5 bg-white rounded-lg p-5'>
        <li>Total : </li>
        <li><span  className='font-bold p-2 text-white bg-green-500 rounded-lg'> {issuedround} </span> </li>
        <li><span  className='font-bold p-2  text-white bg-red-500 rounded-lg'> {pendinground} </span> </li>
        <li><span  className='font-bold p-2  text-white bg-gray-500 rounded-lg'> {pendinground+issuedround} </span> </li>
      </ul>
      
  </div>
  
  
  
    <div className="overflow-x-auto mt-5">
  
  
  <div className='flex items-center pb-4 ml-10 justify-start'>
  
    <Formik  initialValues={{
        bookid : '', designid : '', article : ''
    }}
    onSubmit={ async(values) => {
        try {
            const response = await axios.get(`${API_URL}/api/marketdispatch`, {
              params: {
                page: 1,
                pageSize: pageSize,
                bookid : values.bookid,
                designid : values.designid,
                article : values.article
              },
            });
      
            setData(response.data.data);
            
      setIssuedround(response.data.issuedround);
      setPendinground(response.data.pendinground);
     
      settodayIssuedround(response.data.todayissuedround);
      settodayPendinground(response.data.todaypendinground);
     
      setmonthIssuedround(response.data.monthissuedround);
      setmonthPendinground(response.data.monthpendinground);
  
      
            // Calculate total pages based on the total items and page size
            const totalItems = response.data.totalItems; // Replace with the actual key in your API response
            const calculatedTotalPages = Math.ceil(totalItems / pageSize);
            setTotalPages(calculatedTotalPages);
          } catch (error) {
            console.error(error);
          }
    }}
    >
        <Form className='flex items-center gap-2'>
        <button
              onClick={() => {
                setOpenModal(true);
              }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded"
            >     
              <IoMdQrScanner   size={35} />
            </button>
            <Field name="bookid"  placeholder="Book Id"  className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
            <Field name="designid"  placeholder="Design Id"  className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
            <Field name="article"  placeholder="Article Number"   className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
            <Button type="submit">Filter</Button>
        </Form>
    </Formik>
    
    <button
  className="flex justify-center mt-2 ml-2 mb-2 bg-red-500 hover:bg-red-700 text-white rounded w-20 h-10 items-center gap-1"
  onClick={() => {
    handleExportRows(data, 'Market-Dispatch Data'); 
  }}
>
  <FaRegFilePdf /> PDF
</button>
  
  </div>


  <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">

      
{data.map((row, index) => (
  <>
  <thead class="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
   <tr className='text-center'>
      
   <th scope="col" class="px-6 py-3">
                #
            </th>
      <th scope="col" class="px-6 py-3">
          B#
      </th>
      <th scope="col" class="px-6 py-3">
          D#
      </th>
      <th scope="col" class="px-6 py-3">
          Fabric
      </th>
      <th scope="col" class="px-6 py-3">
          Articles
      </th>
      <th scope="col" class="px-6 py-3">
          Article Type
      </th>
      <th scope="col" class="px-6 py-3">
       Created  
      </th>
      <th scope="col" class="px-6 py-3">
       Round Number 
      </th>
      <th scope="col" class="px-6 py-3">
       Status 
      </th>
      <th scope="col" class="px-6 py-3">
      Total Received
      </th>
      <th scope="col" class="px-6 py-3">
      Total Cutting Issued
      </th>

      <th scope="col" class="px-6 py-3">
      Per Round
      </th>
      <th scope="col" class="px-6 py-3">
      Total
      </th>
      <th scope="col" class="px-6 py-3">
      Check
      </th>

      
  </tr>
  </thead>
  <tbody >
  {
    row.createdat_array.map((createdAt, index) =>{ 
      row.roundno_array.sort((a, b) => a - b);
      return(
   
  <tr  className={`  text-center  border-gray-300 border-b  `}>
    {/* if machine is same only show one time */}
    <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{index+1}</td>
    
      {index === 0 ? (
        <td className={`  whitespace-nowrap font-medium text-gray-900 dark:text-black`}>{row._id.bookid}</td>
      ) :  <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}
        
    
  {/* if bookid same only show one time */}


{row.designid.indexOf(row.designid[index]) === index  ? (
    <td className={` whitespace-nowrap font-medium text-gray-900 dark:text-black`}>{row.designid[index]} </td>
  ) : <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}

{row.data_Fabric.indexOf(row.data_Fabric[index]) === index  ? (
    <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.data_Fabric[index]}</td>
  ) : <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}

{row.data_Article.indexOf(row.data_Article[index]) === index  ? (
    <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"> {row.data_Article[index]} </td>
  ) : <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}

{row.data_Article_type.indexOf(row.data_Article_type[index]) === index  ? (
    <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"> {row.data_Article_type[index]} </td>
  ) : <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}



    <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{formatDateTime(createdAt, 'DD/MM/YYYY hh:mm A')} </td>
   
    <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.roundno_array[index]}  </td>

    <td className="flex justify-center whitespace-nowrap font-medium text-gray-900 dark:text-black"><div    className={`w-16 px-3 py-1 ${row.status_array[index] === "Active" ? "bg-green-500 text-white" : row.status_array[index]  === " Pending" ? "bg-yellow-500 text-black" :  "bg-red-500  text-white"}  rounded-xl`}>{row.status_array[index]  == "Pending" ? "In" : "Out"}</div> </td>
   

{row.data_issued_round.indexOf(row.data_issued_round[index]) === index  ? (
<td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.data_issued_round[index]}</td>
) : <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}


{index === 0 ? (
        <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.count}</td>
      ) :  <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}

{row.data_PerSuit.indexOf(row.data_PerSuit[index]) === index  ? (
<td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.data_PerSuit[index]}</td>
) : <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}

{row.data_PerSuit.indexOf(row.data_PerSuit[index]) === index  ? (
<td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.roundno_array.length * row.data_PerSuit[index]}</td>
) : <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}


<td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
 <Formik
    enableReinitialize
    initialValues={{ status: row.check_array[index], id: row.id_array[index]}}
    onSubmit={async(values) => {
      await axios.post(`${API_URL}/api/marketdispatch/checked`, values);
      getRecords();
    }}
  >
    {({ submitForm, setFieldValue, values }) => (
      <Form>
        <Field
          name="status"
          type="checkbox"
          checked={values.status}
          onChange={(e) => {
            const { checked } = e.target;
            setFieldValue('status', checked); // Update the checkbox value
            submitForm(); // Trigger form submission
          }}
        />
      </Form>
    )}
  </Formik>
 </td>


  </tr>
     )})}
     
     </tbody>
  </>
 ))} 



    {data.length === 0 && (
   <tbody>
  <tr>
    <td class="px-6 py-4" colSpan={7}>
      No data found
    </td>
  </tr>
   </tbody>
)}     
        
       
         
     
  </table>

  
  {data.length > 0 && (
          <Pagination
           theme={PaginationTheme}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        )}
  </div>
  
  
  
  
  
  
  
  </div>
  
  )
}

export default MarketDispatch