

import React, { useEffect, useState } from 'react'
import SubHeading from '../../components/SubHeading'
import Card from '../../components/Card'
import axios from 'axios';
import { useParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const CuttingArticle = () => {

  const [productionData, setProductionData] = useState([]);

  const params = useParams();

  useEffect(() => {

    const getProduction = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/punching/production/article/`+encodeURIComponent(params.key));
         setProductionData(response.data.data[0][decodeURIComponent(params.key)]);

         

         Object.keys(response.data.data[0][decodeURIComponent(params.key)]).map((key, index) => {
         
          checkCuttingisSuccess(key);  
        });
       
      } catch (error) {
        console.error(error);
      }
    };
    getProduction();

  }, []);



  const [checkData , setCheckData] = useState([]);

  const checkCuttingisSuccess = async (title) => {
    try {
      const response = await axios.get(`${API_URL}/api/cutting/` + encodeURIComponent(params.key) + `/${encodeURIComponent(title)}`);

      const market_response = await axios.get(`${API_URL}/api/market/` + encodeURIComponent(params.key) + "/" + encodeURIComponent(title));
      const market_data = market_response.data.data;
   
  
      const cuttingData = response.data.data[title];
  
      // Initialize isSuccess to true
      let isSuccess = true;

      const filteredMarketData = Object.fromEntries(Object.entries(cuttingData).map(([category, subCategories]) => {
        return [category, Object.fromEntries(Object.entries(subCategories).map(([subCategory, value]) => {
          const filteredMarketValue = market_data.filter(element => element["Fabric"] === subCategory).reduce((acc, element) => acc + parseFloat(element["Yard"]), 0);
          return [subCategory, {
            
            value: filteredMarketValue
          }];
        }))];
      }));

    
  
      Object.keys(filteredMarketData).forEach(category => {
        // Get the inner object for the category
        const categoryObject = cuttingData[category];
        Object.keys(categoryObject).forEach(subCategory => {
          // Calculate total for each subCategory


          const subTotal = calculateTotal(subCategory, cuttingData);

          // Check if subTotal is less than 0, if so, set isSuccess to false
          if (subTotal < 0 || subTotal === 0) {
            isSuccess = false;
          }
          
        });
      });
  
    
      let ckdata = {title , isSuccess};
  
      setCheckData([...checkData, ckdata ]);
      return isSuccess; 
  
    } catch(error) {
      console.error(error);
      return false;
    }
  };
  

  const calculateTotal = (property, getCuttingData) => {
    console.log(property,' ',getCuttingData)
    const total = ["Required", "Market", "Reject","BCategory","Shortfall"].reduce((acc, category) => {
      const value = getCuttingData[category] ? parseFloat(getCuttingData[category][property]) || 0 : 0;
      
      // Adjust the calculation based on your formula
      if (category === "Required") {
        acc -= value;
      } else if (category === "Market") {
        acc += value;
      } else if (category === "Reject") {
        acc -= value;
      }
      else if (category === "BCategory") {
        acc -= value;
      }
      else if (category === "Shortfall") {
        acc -= value;
      }
      return acc;
    }, 0); 

    return total;
  };


  return (
    <div>
        <SubHeading title={'Cutting '+decodeURIComponent(params.key)} />


        <div className="px-10 flex flex-wrap align-items-center gap-20 justify-center ">
    

  {Object.keys(productionData).map((key, index) => {

    return (
      <div key={index} className={checkData.filter(data => data.title === key)[0]?.isSuccess ? "flex justify-center items-center  " : "flex justify-center items-center   "}>
        <Card key={index} value={key} url={`/Cutting/${encodeURIComponent(params.key)}/${encodeURIComponent(key)}`} />
      </div>
    );
  })}


     </div>
    </div>
  )
}

export default CuttingArticle
