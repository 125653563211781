function formatDateTime(dateString, format = 'DD/MM/YYYY HH:mm A') {
  const date = new Date(dateString);

  const padZero = (num) => (num < 10 ? '0' + num : num);

  const formats = {
    'DD/MM/YYYY': () => `${padZero(date.getDate())}/${padZero(date.getMonth() + 1)}/${date.getFullYear()}`,
    'DD/MM/YYYY HH:mm:ss': () => `${padZero(date.getDate())}/${padZero(date.getMonth() + 1)}/${date.getFullYear()} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`,
    'DD/MM/YYYY hh:mm A': () => {
      let hours = date.getHours();
      const amPm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12;  // Convert hours to 12-hour format
      return `${padZero(date.getDate())}/${padZero(date.getMonth() + 1)}/${date.getFullYear()} ${hours}:${padZero(date.getMinutes())} ${amPm}`;
    }
    // Add more formats as needed
  };

  if (!formats[format]) {
    throw new Error(`Unsupported format: ${format}`);
  }

  return formats[format]();
}

export default formatDateTime;
