import React from 'react'
import SubHeading from '../../components/SubHeading'



function PercumintDepartment() {
  return (
    <div>

<SubHeading title={'Percumint' } />


    <section className='flex justify-center gap-4'>

        <div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-250 border">
                    <tr>
                        <th className='px-14 py-3  '>viscose</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap'> 0.5</td>
                    </tr>
                </tbody>
            </table>
        </div>
       
        <div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-250 border">
                    <tr>
                    <th className='px-14 py-3'>viscose</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap'> 0.5</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-250 border">
                    <tr>
                    <th className='px-14 py-3'>viscose</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap'> 0.5</td>
                    </tr>
                </tbody>
            </table>
        </div>


        <div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-250 border">
                    <tr>
                    <th className='px-14 py-3'>viscose</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap'> 0.5</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </section>




<section className='mt-16'>

        <div>
            <table className='w-full text-sm text-left rtl:text-right text-gray-500'>
                <thead className='text-xs text-gray-700 uppercase bg-gray-50'> 
                    <tr >
                        <th colSpan={2} className='px-10 py-3 text-center  border'>Shahneeer</th>
                        <th className='px-10 py-3 border'>Des No 1877</th>
                        <th className='px-14 py-3 border'>viscose</th>

                        <th className='px-2 py-3 border'>S1</th>
                        <th className='px-2 py-3 border'>S2</th>
                        <th className='px-2 py-3 border'>S3</th>
                        <th className='px-2 py-3 border'>S4</th>
                        <th className='px-2 py-3 border'>S1</th>
                        <th className='px-2 py-3 border'>S2</th>
                        <th className='px-2 py-3 border'>S3</th>
                        <th className='px-2 py-3 border'>S4</th>
                        <th className='px-14 py-3 border'>viscose</th>
                        <th className='px-14 py-3 border'>viscose</th>



                    </tr>
                    <tr>
             


                    </tr>
                </thead>
                <tbody>
                    <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                
        
                    <th className='px-10 py-3   border'>sno</th>
                    <th className='px-10 py-3   border'>article</th>
                    <th className='px-14 py-3   border'>T Head</th>
                    <th className='px-14 py-3   border'>Round</th>

                    <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                    <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                    <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                    <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

                    <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                    <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                    <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                    <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

                    <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'>1</td>
                    <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'>1</td>
        

                    



                        </tr>


       <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>

                <td className='px-10 py-4 font-medium text-gray-900 whitespace-nowrap border'>1</td>
                <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'>a</td>
                <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>
                <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>
                
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            </tr>

            <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>

            <td className='px-10 py-4 font-medium text-gray-900 whitespace-nowrap border'>2</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'>a</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>

            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

            <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            </tr>


            <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>

            <td className='px-10 py-4 font-medium text-gray-900 whitespace-nowrap border'>3</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'>a</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>

            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

            <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            </tr>



            <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>

            <td className='px-10 py-4 font-medium text-gray-900 whitespace-nowrap border'>4</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'>a</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>

            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

            <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            </tr>




            <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>

            <td className='px-10 py-4 font-medium text-gray-900 whitespace-nowrap border'>5</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'>a</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>

            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

            <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            </tr>



            <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>

            <td className='px-10 py-4 font-medium text-gray-900 whitespace-nowrap border'>6</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'>a</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>

            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

            <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            </tr>



            <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>

            <td className='px-10 py-4 font-medium text-gray-900 whitespace-nowrap border'>7</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'>a</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>

            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

            <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            </tr>


            <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>

            <td className='px-10 py-4 font-medium text-gray-900 whitespace-nowrap border'>8</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'>a</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>
            <td className='px-14 py-4 font-medium text-gray-900 whitespace-nowrap border'> 0.5</td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            </tr>



            <tr className='bg-white  dark:bg-gray-800 dark:border-gray-700'>

            <td className='px-10 py-4 font-medium text-gray-900 whitespace-nowrap '></td>
            <td rowSpan={2} className='px-14 py-4 text-2xl font-medium text-gray-900 whitespace-nowrap '>LOT 860 </td>
            <td className='px-14 py-4  font-small text-gray-900 whitespace-nowrap '> </td>
            <td className='px-14 py-4 text-xl font-medium	text-gray-900 whitespace-nowrap border'> 80 Percent</td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

            <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

            </tr>

            

            <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>

            <td className='px-10 py-4 font-medium text-gray-900 whitespace-nowrap '></td>
            
            <td className='px-14 py-4  font-small text-gray-900 whitespace-nowrap '> </td>
            <td className='px-14 py-4 text-xl font-medium	text-gray-900 whitespace-nowrap border'> 80 Percent</td>

            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

            <td className='px-2  py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

            <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>
            <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap border'> </td>

            </tr>

                </tbody>
            </table>



        </div>



</section>




<section className='mt-16'>

        <div>
            <table className=' text-sm text-left rtl:text-right text-gray-500'>
             
                <tbody>
     

       <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
            <td className='px-24 py-4 text-xl  whitespace-nowrap border text-black'>Required</td>
            <td className='px-20 py-4 text-xl  whitespace-nowrap border'></td>
            </tr> 

            <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
            <td className='px-24 py-4 text-xl  whitespace-nowrap border text-black'>Purcahse</td>
            <td className='px-20 py-4 text-xl  whitespace-nowrap border'></td>
            </tr>

            <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
            <td className='px-24 py-4 text-xl whitespace-nowrap border text-black'>Return</td>
            <td className='px-20 py-4 text-xl whitespace-nowrap border'></td>
            </tr>

            <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
            <td className='px-24 py-4 font-bold text-2xl text-black whitespace-nowrap border'>Total</td>
            <td className='px-20 py-4  text-xl whitespace-nowrap border'></td>
            </tr>


                </tbody>
            </table>



        </div>



</section>

    </div>

    
  )
}

export default PercumintDepartment