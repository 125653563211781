import React,{useEffect, useState , useRef } from 'react'
import SubHeading from '../../components/SubHeading'
import { FaCut, FaEye, FaPen, FaRegFilePdf } from "react-icons/fa";
import { Table,Button,Modal, Tooltip} from 'flowbite-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { MdFileDownloadDone } from "react-icons/md";
import { IoIosQrScanner } from "react-icons/io";
import { FaBarcode } from "react-icons/fa6";
import Barcode from 'react-barcode';
import { ModalTheme } from '../../theme/ModalTheme';
import useScanDetection from 'use-scan-detection-react18';
import { QRCode } from 'react-qrcode-logo';
import { FaQrcode } from "react-icons/fa";
import { jwtDecode } from 'jwt-decode'
import { RiFileDamageFill } from "react-icons/ri";
import { AiOutlineFileDone } from "react-icons/ai";
import generatePDF from 'react-to-pdf';
import "./print.css";
import { jsPDF } from 'jspdf'; 
import autoTable from 'jspdf-autotable';
// import imag1 from "../../../public/new/main-logo.png"
const API_URL = process.env.REACT_APP_API_URL;


function CuttingArticleId() {
  const user = jwtDecode(localStorage.getItem('token'));

  const params = useParams();
  const navigate = useNavigate();

  const targetRef = useRef();
  

   const data1 =  [{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }];
    
  const [data, setData] = useState([ ]);
 

  const [getCuttingData, setGetCutting] = useState();


  const [data_, setData_] = useState([]);
  const [round , setRound] = useState();
  const [article , setArticle] = useState();

  
  const [department, setDepartment] = useState([]);
  const [machines, setMachines] = useState([]);
  

  const getDepartmentMachineDropDownRecords = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/department/machines`);
          
      setDepartment(response.data.department_data.map((item) => ({ title: item.name, value: item.name })));
      setMachines(response.data.machine_data.map((item) => ({ title: item.name, value: item.name, head: item.head , department : item.department })));
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    getDepartmentMachineDropDownRecords();

    const getProduction = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/production/` + encodeURIComponent(params.key) + "/" + encodeURIComponent(params.title));
        setData_(response.data["data"][0]["data"]);
       

        const spliceData =  response.data["data"][0]["data"];

     

        const initailValues = [...new Set(spliceData.map(element =>  element["Fabric"]))].filter(uniqueValue => 
          uniqueValue !== null && uniqueValue !== undefined && uniqueValue !== '' 
        );
        
        const record = Array.isArray(response.data["data"][0]["data"]) ? spliceData : [data1];
         
        const market_response = await axios.get(`${API_URL}/api/market/`+encodeURIComponent(params.key)+"/"+encodeURIComponent(params.title));
        const market_data = market_response.data.data;

        const cutting_response = await axios.get(`${API_URL}/api/cutting/`+encodeURIComponent(params.key)+"/"+encodeURIComponent(params.title));    
       
        const cuttingData= cutting_response.data.data !== null ?   cutting_response.data.data[params.title] :null;
          

          console.log('record',record);
          console.log('initailValues',initailValues);
          console.log('cuttingData',cuttingData)
        const mydt = {
          "Required": Object.fromEntries( initailValues.map( (element_,index) => [element_, 
           
            //  record.filter(element => element["Fabric"] === element_).reduce((acc, element) => acc + parseFloat(element["Yard/Meter"]), 0)
            (record ?? null)
            .filter(element => element["Fabric"] === element_)
            .reduce((acc, element) => acc + parseFloat(element["Yard/Meter"]), 0)

          ])),
          "Market": Object.fromEntries(initailValues.map(element => [element,
            market_data.filter(element_ => element_["Fabric"] === element).reduce((acc, element) => acc + parseFloat(element["Yard"]), 0)
          ])),
          "Reject": Object.fromEntries(initailValues.map(element => [
            element,
            cuttingData !== null 
              ? Array.isArray(cuttingData) ? cuttingData.filter(element_ => element_["Fabric"] === element) : null
              : []
          ])),
          // "BCategory": Object.fromEntries(initailValues.map(element => [
          //   element,
          //   cuttingData  ?  cuttingData.filter(item => item["Fabric"] === element) :0 // Update logic if needed
          // ])),
          // "Shortfall": Object.fromEntries(initailValues.map(element => [
          //   element,
          //   cuttingData ?    cuttingData.filter(item => item["Fabric"] === element):0  // Update logic if needed
          // ])),
          "BCategory": Object.fromEntries(initailValues.map(element => [
            element,
            Array.isArray(cuttingData) ? cuttingData.filter(item => item["Fabric"] === element) : 0 // Update logic if needed
          ])),
          "Shortfall": Object.fromEntries(initailValues.map(element => [
            element,
            Array.isArray(cuttingData) ? cuttingData.filter(item => item["Fabric"] === element) : 0 // Update logic if needed
          ])),
          "Total": Object.fromEntries(initailValues.map(element => [element, ""])),
        }

        setGetCutting(mydt)

        
      } catch (error) {
        console.error(error);
      }
    };


    const getCutting = async () => {
      try {
    
        const response = await axios.get(`${API_URL}/api/cutting/`+encodeURIComponent(params.key)+"/"+encodeURIComponent(params.title));
     // console.log(response.data.data[params.title]); 
     

      setGetCutting(response.data.data[params.title]);

      
      } catch(error) {
        console.error(error);
      }
    };



    getProduction();
    getCutting();
  }, []);
  
  const handleInputChange = (category, property, value) => {
    setGetCutting(prevData => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [property]: value,
      },
      
    }));
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/cutting/${encodeURIComponent(params.key)}/${encodeURIComponent(params.title)}`, getCuttingData);
      
      // Check the response and handle accordingly
      if (response.data && response.data.message) {
 
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });

      } else {
        console.log('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Error saving data:', error);
      // Handle error appropriately, e.g., show an error message to the user
    }
  };

  const calculateTotal = (property, getCuttingData) => {
  
    const total = ["Required", "Market", "Reject","BCategory","Shortfall"].reduce((acc, category) => {
      const value = getCuttingData[category] ? parseFloat(getCuttingData[category][property]) || 0 : 0;
      
      // Adjust the calculation based on your formula
      if (category === "Required") {
        acc -= value;
      } else if (category === "Market") {
        acc += value;
      } else if (category === "Reject") {
        acc -= value;
      }
      else if (category === "BCategory") {
        acc -= value;
      }
      else if (category === "Shortfall") {
        acc -= value;
      }
    
      return acc;
    }, 0);  
    return total;
  };
  
  const [OpenQRcodeModal, setOpenQRcodeModal] = useState(false);
  const [qrcodeData, setQrcodeData] = useState(null);

  const saveDepartment = async() => {
    
    try{

      const response = await axios.post(`${API_URL}/api/cutting/assign_department/${encodeURIComponent(params.key)}/${encodeURIComponent(params.title)}`, {
        data: data_,
      });
     if(response.status === 200){
      
      toast.success("Saved Successfully", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

      handleSaveClick();
    }
    else{
      toast.error("Failed to Save", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      }
    }
    catch(error){
      console.error(error);
     
    }
    
  }

  const toggleIssueRound = (index) => {
    const newData = [...data_];
    newData[index]["isIssueRoundEnabled"] = !newData[index]["isIssueRoundEnabled"];
    setData_(newData);
  };

  const [openModalDamageRound,setopenModalDamageRound] =useState(false);
  const [openDamageRoundData,setopenDamageRoundData] =useState({});
  const [ShowData,setShowData] =useState(true);


  useScanDetection({
    onComplete: async (barcode) =>  {
      if(openModalDamageRound){
     const Code = barcode.replace(/Shift/g, '');
     //splite Code by ,
     const data = Code.split(',');
     const bookid = data[0];
     const designid = data[1];
     const id = data[2];
     const issueno = data[3];
     
        console.log(bookid,designid,id,issueno)

     if(openDamageRoundData.id == id && params.key === bookid && params.title === designid ){


     await  axios.post(`${API_URL}/api/cutting/damage_round`, {bookid,designid,id,issueno})
     .then((response) => {

      const recordIndex = data_.findIndex(record => record.id == id);
        
      const newData = [...data_];
    //  if(newData[recordIndex]["Damage Round Issue"] >= newData[recordIndex]["Round"]){
    //    toast.error("Damage Round Issue cannot be greater than Round")
    //    return;
    //  }
     newData[recordIndex]["Damage Round Issue"] += 1;
     setData_(newData);
     const fabric = newData[recordIndex]["Fabric"];
     const calVal =  (newData[recordIndex]["Yard/Meter"] / newData[recordIndex]["Round"]) * newData[recordIndex]["Damage Round Issue"]
      getCuttingData.Reject[fabric] = calVal

       handleSaveClick();
       saveDepartment();
       setopenModalDamageRound(true);
     toast.success("Damage Round Added Successfully");
      
     })
     .catch((error) => {
        toast.error("Already Damage Round Added",)
       
      });
       

      } else{toast.error("This barcode is invalid for this field")}
    }
    else{toast.error("Scanner not activated")}
    
    },
  });
  

  const columns = [
    { header: 'Fabric', size: 120 },
    { header: 'Article', size: 120 },
    { header: 'Head', size: 120 },
    { header: 'Quantity', size: 120 },
    { header: 'PR-Suit', size: 120 },
    { header: 'Round', size: 120 },
    { header: 'Y/M', size: 120 },
    { header: 'Round Final', size: 160 },
    // { header: 'Remaining Round', size: 180 },
    { header: 'Issue Round', size: 140 },
    // { header: 'Lot Number', size: 140 },

    // { header: 'Damage Round Issue', size: 200 },
    // { header: 'Department', size: 160 }
  ];
  
  const handleExportRows = (rows, bName) => {
    
    
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a3'
    });
  
    const imgBase64 = "/new/nav-main-logo.png"; // Replace with your image's base64 string
    const imgWidth = 50;  // Adjust width
    const imgHeight = 20; // Adjust height
  
    // Add the image at the top
    doc.addImage(imgBase64, 'PNG', (doc.internal.pageSize.getWidth() - imgWidth) / 2, 5, imgWidth, imgHeight);
  
    doc.setFontSize(18);
    const BName = bName +  "- Lot -"  + header.lot;
    const pageWidth = doc.internal.pageSize.getWidth();
    const bNameTextWidth = doc.getTextWidth(BName);
    const bNameXOffset = (pageWidth - bNameTextWidth) / 2;
    const title = "Cutting";
    const titleTextWidth = doc.getTextWidth(title);
    const titleXOffset = (pageWidth - titleTextWidth) / 2;
  
    // Adjust position for text to appear below the image
    doc.text(BName, bNameXOffset, imgHeight + 15); // Place text 15mm below image
    doc.text(title, titleXOffset, imgHeight + 30); // Place the title below the BName
  
    const tableData = rows.map((row) => {
      if (row) {
        return columns.map((e) => {
          if (e.header === "Article") {
            return row[e.header] + " " + row["Article-type"];
          } else if (e.header === "Round Final") {
            return row[e.header] + " " + row["RF-type"]; // Concatenate RF-type with Round Final
          } else if (e.header === "Quantity") {
            return row[e.header] + " " + row["Quantity-type"]; // Concatenate RF-type with Round Final
          } 
          else if (e.header === "Y/M") {
            return row["Yard/Meter"] + " " + row["Y/M-type"]; // Concatenate RF-type with Round Final
          } 
          else if (e.header === "Remaining Round") {
            return parseInt(row["Round"])-parseInt(row["Issue Round"]); // Concatenate RF-type with Round Final
          } 
          else if (e.header === "PR-Suit") {
            return row["PR-Suit"]+ "  " + row["PR-type"]; // Concatenate RF-type with Round Final
          } 
          // else if (e.header === "Lot Number") {
          //   return header.lot; 
          // } 
          else {
            return row[e.header];
          }
        });
      }
      return [];
    });
  
    const tableHeaders = columns.map((c) => c.header);
  
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: imgHeight + 40, // Ensure the table starts below the image and text
      styles: {
        fontSize: 13,
        cellPadding: 2,
        // overflow: 'linebreak',
        // columnWidth: 'wrap',
      },
      columnStyles: {
        2: { columnWidth: 'auto' },
      },
    });
  
    doc.save(`${BName}.pdf`);
  };
  
  console.log(data_,"dataComes");
  

  const Mini_Table_Column=["Required", "Market", "Reject","BCategory","Shortfall", "Total"]
  

  const handleExportMiniTab = (rows, bName, K_Name) => {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a3',
    });
  
    const imgBase64 = '/new/nav-main-logo.png'; // Replace with your image's base64 string
    const imgWidth = 50;  // Adjust width
    const imgHeight = 20; // Adjust height
  
    // Add the image at the top
    doc.addImage(imgBase64, 'PNG', (doc.internal.pageSize.getWidth() - imgWidth) / 2, 5, imgWidth, imgHeight);
  
    doc.setFontSize(18);
    const BName = bName;
    const pageWidth = doc.internal.pageSize.getWidth();
    const bNameTextWidth = doc.getTextWidth(BName);
    const bNameXOffset = (pageWidth - bNameTextWidth) / 2;
    const title = "Cutting";
    const titleTextWidth = doc.getTextWidth(title);
    const titleXOffset = (pageWidth - titleTextWidth) / 2;
  
    // Adjust position for text to appear below the image
    doc.text(BName, bNameXOffset, imgHeight + 15); // Place text 15mm below image
    doc.text(title, titleXOffset, imgHeight + 30); // Place the title below the BName
  
    const tableHeaders = [['Category', ...K_Name]];
    const tableData = Mini_Table_Column.map((category, index) => {
      return [category, ...K_Name.map((fabric) =>
        category === "Total"
          ? calculateTotal(fabric, getCuttingData)
          : getCuttingData[category] && getCuttingData[category][fabric]
          ? getCuttingData[category][fabric]
          : 0
      )];
    });
  
    autoTable(doc, {
      head: tableHeaders,
      body: tableData,
      startY: imgHeight + 40, // Ensure the table starts below the image and text
      styles: {
        fontSize: 14,
        cellPadding: 5,
        overflow: 'linebreak',
        columnWidth: 'wrap',
      },
      columnStyles: {
        0: { columnWidth: 'auto' },
      },
    });
  
    doc.save(`${BName}.pdf`);
  };
  
  const [header , setHeader] = useState([]);
  const getProduction = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/production/` + encodeURIComponent(params.key) + "/" + encodeURIComponent(params.title));
      setHeader(response.data["data"][0]["header"]);
    } catch (error) {
      console.error(error);
    }
  };

 

  useEffect(()=>{
    getProduction()
  },[])
  
  return (
  


    <div>

<Modal theme={ModalTheme} show={openModalDamageRound} size="md" onClose={() => setopenModalDamageRound(false)} popup>
        <Modal.Header className=''  />
        <Modal.Body className=''>
        <p className='text-2xl text-center font-bold mb-5'>SCAN BARCODE</p>
        <div className='flex justify-center'>
        
          <img src='/gif/scanner.gif' height={300} width={300} />
        </div> 
        </Modal.Body>
      </Modal>


<Modal show={OpenQRcodeModal} theme={ModalTheme} size="lg" onClose={() => setOpenQRcodeModal(false)} popup>
        <Modal.Header className='dark:bg-white dark:text-black'  />
        <Modal.Body className='dark:bg-white dark:text-black'>
          <div className="text-center justify-center flex">
          {/* <Barcode   size={200} value={qrcodeData} displayValue={false}  id="barcode-image" /> */}
          <QRCode value={qrcodeData} />
          </div>
          
          <div className="mt-3">
            <div className="flex justify-center gap-4">
              <a target='_blank' className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' href={`/BarcodePrint/${round}/${encodeURIComponent(qrcodeData)}/${encodeURIComponent(article)}`}>
                {"Print"}
              </a>
              <Button color="gray" onClick={() => setOpenQRcodeModal(false)}>
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


   {ShowData&&( <SubHeading title={params.key+' - '+params.title} />)} 

    

<div id='market' className="overflow-x-auto mt-5 mb-5">

 {ShowData&&(<h2 class="text-4xl font-bold dark:text-black mb-4">Cutting</h2>)} 

<div className='flex justify-center gap-2'>

<Link to={`/Cutting/${ encodeURIComponent(params.key)}/${ encodeURIComponent(params.title)}/cuttingreport/data/record`}>
<Button className='p-2 mt-2 mb-2 bg-blue-500 hover:bg-blue-700 text-white rounded  h-10'><FaCut  size={25} />&nbsp;Cutting Report</Button>
</Link>
{(user?.user?.role !== 'production' || user?.role !== 'production') && 
<Link to={`/Cutting/${ encodeURIComponent(params.key)}/${ encodeURIComponent(params.title)}/scanner`}>
<Button className='p-2 mt-2 mb-2 bg-blue-500 hover:bg-blue-700 text-white rounded  h-10'><IoIosQrScanner size={25} />&nbsp;Scanner</Button>
</Link>
}
</div>


<div className='px-10'>

<div className='flex justify-left gap-2'>
{(user?.user?.role !== 'production' || user?.role !== 'production') &&
<Button onClick={saveDepartment} className='flex justify-left mt-2 mb-2 bg-blue-500 hover:bg-blue-700 text-white rounded w-20 h-10'>save</Button>
}
<button className='flex justify-center mt-2 mb-2 bg-red-500 hover:bg-red-700 text-white rounded w-20 h-10 items-center gap-1' 
  onClick={()=>{
    handleExportRows(data_,`${params.key+' - '+params.title}`)
  }
  //   async () => {
  //   setShowData(false)
  //   const hiddenElements = document.querySelectorAll(".pdf-content");
  //   hiddenElements.forEach(element => {
  //     element.style.position = "static";
  //     element.style.left = "0";
  //     element.style.visibility = "visible";
  //     element.style.color = "black"; // Ensure text is black for PDF
  //   });

  //   try {
  //     await generatePDF(targetRef, { filename: `${params.key}-${params.title}.pdf` });
  //     setShowData(true)
  //   } catch (error) {
  //     setShowData(true)
  //     console.log(error);
  //   }

  //   // Hide the elements again after PDF generation
  //   hiddenElements.forEach(element => {
  //     element.style.position = "absolute";
  //     element.style.left = "-10000px"; // Move off-screen again
  //     element.style.color = "white";   // Change text color back to white
  //   });
  // }
  }
>
  <FaRegFilePdf /> PDF
</button>


</div>

<div ref={targetRef}   >
<h2 class="text-4xl pdf-content font-bold dark:text-black mb-4 m-3 " style={{ position: 'absolute', left: '-10000px' }} >{params.key + ' - ' + params.title}</h2>
<h2 className="pdf-content text-4xl font-bold dark:text-black  m-3" style={{ position: 'absolute', left: '-10000px' }}>Cutting</h2>
<br/>
<Table className=' dark:text-black m-3 ' >
    <Table.Head>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black text-nowrap'>Fabric</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black text-nowrap'>Article</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black text-nowrap'>Head</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black text-nowrap'>Quantity</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black text-nowrap'>PR-Suit</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black text-nowrap'>Round</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black text-nowrap'>Y/M</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black text-nowrap'>Round Final</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black text-nowrap'>Remaining Round</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black text-nowrap'>Issue Round</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black text-nowrap'>Damage Round Issue</Table.HeadCell>
      
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Deparment</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Lot Number</Table.HeadCell>
      {/* <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Machine</Table.HeadCell> */}
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'></Table.HeadCell>
    </Table.Head>
    <Table.Body className="divide-y">
      {data_.map((row, index) => (
        <Table.Row key={index} className="bg-white dark:border-gray-200 dark:bg-gray-300">
          
            <Table.Cell className='text-nowrap' >{row["Fabric"]}</Table.Cell>
            <Table.Cell className='text-nowrap' >{row["Article"]+ "  " + row["Article-type"]}</Table.Cell>
            <Table.Cell className='text-nowrap' >{row["Head"]}</Table.Cell>
            <Table.Cell className='text-nowrap' >{row["Quantity"]+ "  " + row["Quantity-type"]}</Table.Cell>
            <Table.Cell className='text-nowrap' >{row["PR-Suit"]+ "  " + row["PR-type"]}</Table.Cell>
            <Table.Cell className='text-nowrap' >{row["Round"]}</Table.Cell>
            <Table.Cell className='text-nowrap' >{row["Yard/Meter"]+ "  " + row["Y/M-type"]}</Table.Cell>
            <Table.Cell className='text-nowrap' >{row["Round Final"] + "  " + row["RF-type"]}</Table.Cell>
            {/* <Table.Cell>
                <input type='number' value={row["Remaining Round"]}
                onChange={(e)=>{
                  const newData = [...data_];
                  newData[index]["Remaining Round"] = parseInt(e.target.value);
                  setData_(newData);
                }}
                className="p-2 bg-[#F9FAFB] border border-gray-300 text-gray-900 text-sm  rounded-lg w-40 h-12"  />
            </Table.Cell> */}
             <Table.Cell className='text-nowrap' >{parseInt(row["Round"])-parseInt(row["Issue Round"])}</Table.Cell>
            <Table.Cell className='flex items-center justify-center gap-3'>
                <input type='number' value={row["Issue Round"]}
                onChange={(e)=>{
                  const newData = [...data_];
                  newData[index]["Issue Round"] = parseInt(e.target.value);
                  setData_(newData);
                }}
                disabled={!row["isIssueRoundEnabled"]} 
                className="p-2 bg-[#F9FAFB] border border-gray-300 text-gray-900 text-sm  rounded-lg w-40 h-12"  />
               
                {/* {row["isIssueRoundEnabled"] ? (
              <MdFileDownloadDone
                color='green'
                className='cursor-pointer'
                onClick={() => toggleIssueRound(index)}
                size={30}
              />
            ): (
              <FaPen color='red' className='cursor-pointer'
              onClick={() => toggleIssueRound(index)}
              size={20} />
            )} */}
              
                
            </Table.Cell>
            <Table.Cell >
              <div className='flex gap-2'>
                <input type='number' disabled value={row["Damage Round Issue"]}
                onChange={(e)=>{
                  const newData = [...data_];
                  newData[index]["Damage Round Issue"] = e.target.value;
                  setData_(newData);
                }}
                className="p-2 bg-[#F9FAFB] border border-gray-300 text-gray-900 text-sm  rounded-lg w-40 h-12"  />
               {(user?.user?.role !== 'production' || user?.role !== 'production') &&
           <button onClick={()=>{setopenDamageRoundData(row);setopenModalDamageRound(true);}}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded"
            >
              <IoIosQrScanner size={25} />
            </button>
}
            </div>
            </Table.Cell>
            <Table.Cell>
              <select                
                className="bg-[#F9FAFB] border border-gray-300 text-gray-900 text-sm  rounded-lg w-60 h-12"
                value={row["Department"]}
                onChange={(e) => {
                  const newData = [...data_];
                  newData[index]["Department"] = e.target.value;
                  setData_(newData);
               
                }}
              >
                <option selected>Select Department</option>
                {
                  department.map((item) => (
                    <option value={item.value}>{item.title}</option>
                  ))
                }
               
              </select>
            </Table.Cell>
            <Table.Cell className='text-nowrap' >{header.lot}</Table.Cell>

            
            {/* <Table.Cell>
              <select                
                className="bg-[#F9FAFB] border border-gray-300 text-gray-900 text-sm  rounded-lg w-60 h-12"
                value={row["Machine"]}
                onChange={(e) => {
                  const newData = [...data_];
                  newData[index]["Machine"] = e.target.value;
                  setData_(newData);
               
                }}
              >
                <option selected>Select Machine</option>
                {
                  machines.filter(machine => machine.department === row["Department"]).map((item) => (
                    <option value={item.value+'-'+item.head}>{item.title}-{item.head}</option>
                  ))
                }
               
              </select>
            </Table.Cell> */}
            <Table.Cell className=''>
            {(user?.user?.role !== 'production' || user?.role !== 'production') &&

   <div className='flex items-center' >    
        <button
              onClick={() => {
                setOpenQRcodeModal(true);
                setQrcodeData(
                  params.key+','+params.title  + "," +row["id"]
                );
                setRound(row["Round"]);
                setArticle(row["Article"]+ "  " + row["Article-type"]);
               }}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-2 rounded mr-2"
            >
             
              <FaQrcode  size={35} />
            </button>
           
            <button
              onClick={() => {
                navigate(`/Cutting/damage/${ encodeURIComponent(params.key)}/${ encodeURIComponent(params.title)}/${row["id"]}`)  
               }}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 rounded mr-2"
            >
              <RiFileDamageFill   size={35} />
            </button>

            <button
                onClick={() => {
                  navigate(`/Cutting/issue/${ encodeURIComponent(params.key)}/${ encodeURIComponent(params.title)}/${row["id"]}`)  
                 }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded"
            >
              <AiOutlineFileDone    size={35} />
            </button>
      
  </div>
}
            </Table.Cell>        
        </Table.Row>
      ))}
     
    
    </Table.Body>
</Table>
</div>

  </div>

          </div> 


<div className='px-10'>
<button className='flex justify-center mt-2 mb-2 bg-red-500 hover:bg-red-700 text-white rounded w-20 h-10 items-center gap-1' 
  onClick={()=>{
    handleExportMiniTab(data_,`${params.key+' - '+params.title}`,[...new Set(data_.map(element => element["Fabric"]))])
  }
  }
>
  <FaRegFilePdf /> PDF
</button>
{getCuttingData ? (
  <div className='mt-5 overflow-scroll'>
    <Table>
    <Table.Head>
  <Table.HeadCell className='dark:bg-gray-400 dark:text-black'></Table.HeadCell>
  {[...new Set(data_.map(element => element["Fabric"]))].map((uniqueValue, index) => (
    <Table.HeadCell key={index} className='dark:bg-gray-400 dark:text-black'>
      {uniqueValue}
    </Table.HeadCell>
  ))}
</Table.Head>
    {/* <Table.Body className="divide-y">
    {["Required", "Market", "Reject", "Total"].map((category, index) => (
    <Table.Row key={index} className="bg-white dark:border-gray-200 dark:bg-gray-300 dark:text-black">
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{category}</Table.Cell>
      {getCuttingData[category] && Object.keys(getCuttingData[category]).map((property, propIndex) => (
        <Table.Cell key={propIndex} className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
         
          <input
            disabled={category === "Total" || category === "Required" || category === "Market"}
            className='h-10 w-30 border border-gray-300'
            type="number"
            value={category === "Total" ? calculateTotal(property,getCuttingData) : getCuttingData[category][property]}
            onChange={(e) => handleInputChange(category, property, e.target.value)}
          />
        </Table.Cell>
      ))}
    </Table.Row>
  ))}
    </Table.Body> */}


  <Table.Body className="divide-y">
  {["Required", "Market", "Reject","BCategory","Shortfall", "Total"].map((category, index) => (
    <Table.Row key={index} className="bg-white dark:border-gray-200 dark:bg-gray-300 dark:text-black">
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{category}</Table.Cell>
      {([...new Set(data_.map(element => element["Fabric"]))]).map((uniqueValue, propIndex) => (
        console.log("uniqueValue",getCuttingData),
        <Table.Cell key={propIndex} className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
          
          <input
            disabled={category === "Total" || category === "Required" || category === "Market"}
            className={  category === "Total" ? calculateTotal(uniqueValue, getCuttingData) > 0 ? 'h-10 w-30 border pl-2 border-gray-300 bg-green-300' : 'h-10 w-30 border border-gray-300 bg-red-300' : 'h-10 w-30 border pl-2 border-gray-300'}
            type="number"
            value={category === "Total" ? calculateTotal(uniqueValue, getCuttingData) : ( getCuttingData[category] && getCuttingData[category][uniqueValue] ? getCuttingData[category][uniqueValue] : 0)}
            onChange={(e) => handleInputChange(category, uniqueValue, e.target.value)}
          />
        </Table.Cell>
      ))}
    </Table.Row>
  ))}
</Table.Body>


    </Table>
  </div>
) : <></>}
</div> 


            
    </div>
  )
}

export default CuttingArticleId








