import React from 'react';
import LinkButton from '../../components/LinkButton';
import SubHeading from '../../components/SubHeading';
import { IoIosColorPalette } from "react-icons/io";

const Setting = () => {
  return (
    <div>
      <SubHeading title={'Setting'} />

      <div className="flex  flex-col md:flex-row gap-8 justify-center items-center ">
        <LinkButton
          url={'/Setting/ArticlesTypes'}
          buttonName={'Article Types'}
        />

      <LinkButton
          url={'/Setting/Colors'}
          buttonName={'Colors'}
        />


        <LinkButton
          buttonName={'Quantity Types (P)'}
          url={'/Setting/ProductionQuantityTypes'}
        />


      
        <LinkButton url={'/Setting/Fabrics'} buttonName={'Fabrics'} />

        </div>

      <div className="flex flex-col md:flex-row gap-8 justify-center items-center mt-5">


      <LinkButton buttonName={'Pr-Suit Types'} url={'/Setting/PrsuitTypes'} />

      <LinkButton
          buttonName={'Quantity Types (M)'}
          url={'/Setting/MarketQuantityTypes'}
        />

<LinkButton
          buttonName={'Production Types'}
          url={'/Setting/ProductionTypes'}
        />

<LinkButton
          buttonName={'Departments'}
          url={'/Setting/Departments'}
        />
     
       
      </div>

      <div className="flex flex-col md:flex-row gap-8 justify-center items-center mt-5">


<LinkButton buttonName={'Machines'} url={'/Setting/Machines'} />

<LinkButton buttonName={'Users'} url={'/Setting/Users'} />

<LinkButton buttonName={'Roles'} url={'/Setting/Roles'} />

<LinkButton buttonName={'Vendors'} url={'/Setting/Vendors'} />

      </div>

      
    </div>
  );
};

export default Setting;
