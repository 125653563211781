
import React, { useEffect, useState } from 'react'
import SubHeading from '../../components/SubHeading'
import axios from 'axios';
import useScanDetection from 'use-scan-detection-react18';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

function ReceivingScanner() {

  useScanDetection({
    onComplete: async (barcode) =>  { 
    const Code = barcode.replace(/Shift/g, '');
    const data = Code.split(',');
    const bookid = data[0];
    const designid = data[1];
    const id = data[2];
    const issueno = data[3];
    await  axios.post(`${API_URL}/api/production/issue_round`, {bookid,designid,id,issueno})
    .then((response) => {    
    toast.success("Issued Successfully");
    })
    .catch((error) => {
      console.log(error)
        toast.error(error.response.data.error)
      });  
    },
  });


  return (
    <div>
      <SubHeading title={'Receiving Department'} />
<div className='flex items-center justify-center h-100' >             
              <div className='flex justify-center'>
                <img src='/gif/scanner.gif' height={300} width={300} />
              </div> 
</div>
    </div>
  );
}

export default ReceivingScanner