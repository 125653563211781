import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'flowbite-react';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const ImageSlider = ({ model, modelUniqueValue }) => {
 

  const [startIndex, setStartIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState([]);
  const [openModal, setOpenModal] = useState(false);


  const [images, setImages] = useState([]);

  const fetchImages = async () => {
    try {
      const response = await axios.get(`${API_URL}/images/${model}/${modelUniqueValue}`);
      console.log('Images fetched successfully:', response);
      setImages(response.data); // Use response.data
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching images:', error.message);
    }
  };

  
  useEffect(() => {
  
    fetchImages();
  }, [model, modelUniqueValue]);

  const handleRemove = async (imageId) => {
    try {
      // Implement the logic to remove the image on the server
      await axios.delete(`${API_URL}/remove-image/${imageId}`);
      // Update the state to reflect the removal
      setImages((prevImages) => prevImages.filter((image) => image._id !== imageId));
    } catch (error) {
      console.error('Error removing image:', error.message);
    }
  };

  const handleClickPrev = () => {
    setStartIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleClickNext = () => {
    setStartIndex((prevIndex) =>
      Math.min(images.length - 4, prevIndex + 1)
    );
  };

  const handleImageClick = (img) => {
    console.log(`clicked`);
    setOpenModal(true);
    setSelectedImage(img);
  };

  // useEffect(() => {
  //   fetchImages();
  //   }, [images]);



  const visibleImages = images.slice(startIndex, startIndex + 4);

  return (
    <>
      <div className="grid gap-2 w-[50%]">
        <div className="grid grid-cols-4 gap-2">
          {visibleImages.map((image, index) => (
            <div key={index}     onClick={() => handleImageClick(image)} >
              <img
          
                className="h-[150px] w-[200px] max-w-full rounded-lg cursor-pointer"
                src={`${API_URL}${image.url}`}
                alt={`Image ${index + 1}`}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-center items-center pt-4">
          <button
            type="button"
            className="flex justify-center items-center me-4 h-full cursor-pointer group focus:outline-none"
            onClick={handleClickPrev}
          >
            {/* Previous button content */}
            <span class="text-gray-400 hover:text-gray-900 dark:hover:text-white group-focus:text-gray-900 dark:group-focus:text-white">
                <svg class="rtl:rotate-180 w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5H1m0 0 4 4M1 5l4-4"/>
                </svg>
                <span class="sr-only">Previous</span>
            </span>
            {/* ... */}
          </button>
          <button
            type="button"
            className="flex justify-center items-center h-full cursor-pointer group focus:outline-none"
            onClick={handleClickNext}
          >
            {/* Next button content */}
            <span class="text-gray-400 hover:text-gray-900 dark:hover:text-white group-focus:text-gray-900 dark:group-focus:text-white">
                <svg class="rtl:rotate-180 w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                </svg>
                <span class="sr-only">Next</span>
            </span>
            {/* ... */}
          </button>
        </div>
      </div>


<Modal show={openModal} size="lg" onClose={() => setOpenModal(false)} popup>
<Modal.Header className='dark:bg-white dark:text-black'  />
<Modal.Body className='dark:bg-white dark:text-black'>
  <div className="text-center">
  <img
            className="max-w-full max-h-full"
            src={ `${API_URL}${selectedImage.url}`}
            alt="Selected Image"
          />
  </div>
</Modal.Body>
<Modal.Footer className='flex justify-center gap-4'>
<Button color="failure"onClick={() => {  handleRemove(selectedImage._id);  setOpenModal(false)}}>
        {"Remove"}
      </Button>
      <Button color="gray" onClick={() => setOpenModal(false)}>
        Close
      </Button>
          </Modal.Footer>
</Modal>
         

    </>
  );
};

export default ImageSlider;
