import React,{useEffect, useState} from 'react'
import SubHeading from '../../components/SubHeading'
import { FaCut, FaEye, FaPen } from "react-icons/fa";
import { Table,Button,Modal, Tooltip, Pagination} from 'flowbite-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { MdFileDownloadDone } from "react-icons/md";
import { IoIosQrScanner } from "react-icons/io";
import { FaBarcode } from "react-icons/fa6";
import Barcode from 'react-barcode';
import { ModalTheme } from '../../theme/ModalTheme';
import useScanDetection from 'use-scan-detection-react18';
import { QRCode } from 'react-qrcode-logo';
import { FaQrcode } from "react-icons/fa";
import { jwtDecode } from 'jwt-decode'
import { RiFileDamageFill } from "react-icons/ri";
import { AiOutlineFileDone } from "react-icons/ai";
import { ErrorMessage, Field, Formik,Form } from 'formik';
import * as Yup from 'yup';
import { PaginationTheme } from '../../theme/PaginationTheme';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
const API_URL = process.env.REACT_APP_API_URL;


function CuttingReport() {
  const user = jwtDecode(localStorage.getItem('token'));

  const params = useParams();
  const navigate = useNavigate();


  const [openModal , setOpenModal] = useState(false);
  const [search , setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([ ]);
  const [recordId , setRecordId] = useState('');

    const [totalmachine, setTotalmachine] = useState(0);
    const [totalcutting, setTotalcutting] = useState(0);

    const onPageChange = (page) => {
      setCurrentPage(page);
      getRecord(page);
    };
  
    const getRecord = async (page) => {
      try {
        const response = await axios.get(`${API_URL}/api/cuttingreport`, {
          params: {
            page,
            pageSize,
            bookid : params.key,
            designid: params.title,
          },
        });
        setData(response.data.data);
        setTotalcutting(response.data.totals.totalMarket);
        setTotalmachine(response.data.totals.totalCutting);

  
        // Calculate total pages based on the total items and page size
        const totalItems = response.data.totalItems; // Replace with the actual key in your API response
        const calculatedTotalPages = Math.ceil(totalItems / pageSize);
        setTotalPages(calculatedTotalPages);
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      getRecord(currentPage);
    }, [currentPage, pageSize, search]);


    const deleteData = async (id) => {
      try {
        const response = await axios.post(`${API_URL}/api/cuttingreport/delete`, {
          id: id,
        });
        
        if (response.status === 200) {
          toast.success("Record Deleted Successfully", {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        } else {
          console.error("Deletion failed with status:", response.status);
        }
        setOpenModal(false);
        getRecord(currentPage);
  
  
      } catch (error) {
        console.error(error);
      }
    };

 


  
  return (
  


    <div>


<Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
<Modal.Header className='dark:bg-white dark:text-black'  />
<Modal.Body className='dark:bg-white dark:text-black'>
  <div className="text-center">
    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-black" />
    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
      Are you sure you want to remove this record?
    </h3>
    <div className="flex justify-center gap-4">
      <Button color="failure"onClick={() => { deleteData(recordId); setOpenModal(false)}}>
        {"Yes, I'm sure"}
      </Button>
      <Button color="gray" onClick={() => setOpenModal(false)}>
        No, cancel
      </Button>
    </div>
  </div>
</Modal.Body>
</Modal>


        <SubHeading title={params.key+' - '+params.title} />

<div id='market' className="overflow-x-auto mt-5 mb-5">

<h2 class="text-4xl font-bold dark:text-black mb-4">Cutting Report</h2>

<div className='px-10'>
            <Formik initialValues={
                {
                    market:'',cutting:''
                }
            } 
            validationSchema={
                Yup.object({
                    market: Yup.number().required('Market Value is required'),
                    cutting: Yup.number().required('Cutting Value is required')
                })
            }
            onSubmit={async (values, { resetForm }) => {
              console.log(values);
              try {
                const response = await axios.post(`${API_URL}/api/cuttingreport`, {
                  data: {
                    market: values.market,
                    cutting: values.cutting,
                    bookid: params.key,
                    designid: params.title
                  }
                });
            
          
                  resetForm();
                  getRecord(currentPage);
                  toast.success("Saved Successfully", {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                  });
              
              } catch (error) {
                console.error(error);
                // Handle error (e.g., network issue, server error)
                toast.error(error.response.data.error, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              }
            }}
            >
                <Form >


 
<Table className='mb-10'>

<Table.Head>
<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Enter Market Value</Table.HeadCell>
<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Enter Cutting Value</Table.HeadCell>
<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Action</Table.HeadCell> 
</Table.Head>
<Table.Body className="divide-y">
<Table.Row className="bg-white dark:border-gray-200 dark:bg-gray-300 dark:text-black">
 
<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black  w-[20%]">
  <Field name="market"  type="number" className=' bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:border-blue-500 block w-full p-2.5 '/>
    <ErrorMessage name="market" component="div" className="text-red-500" />
  </Table.Cell>

 <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black  w-[20%]">
  <Field name="cutting"  type="number" className=' bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:border-blue-500 block w-full p-2.5 '/>
    <ErrorMessage name="cutting" component="div" className="text-red-500" />
  </Table.Cell>

  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
  <Button type='submit' className='flex justify-left mt-2 mb-2 bg-blue-600 dark:bg-blue-600 hover:bg-blue-700 text-white rounded w-20 h-10'>save</Button>

  </Table.Cell>

 
</Table.Row>

</Table.Body>
</Table>


</Form>
</Formik>
</div>

<div className='flex justify-end  mb-2 text-lg mr-10 '>
      <ul className='text-left flex gap-5 bg-white rounded-lg p-5'>
        <li>Market Total :  </li>
        <li><span  className='font-bold p-2 text-black rounded-lg'> {totalmachine} </span> </li>
        <li>Cutting Total :  </li>
        <li><span  className='font-bold p-2 text-black rounded-lg'> {totalcutting} </span> </li>

      </ul>

      
  </div>

<div className='px-10'>

<div className='flex  justify-end'>



    </div>

            <Table className=' dark:text-black'>
 
    <Table.Head>
    <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Sno.</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Market</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Cutting</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Created At</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'></Table.HeadCell>
    </Table.Head>
    <Table.Body className="divide-y">
      {
      data.map((row, index) => (
        <Table.Row  className="bg-white dark:border-gray-200 dark:bg-gray-300">
           <Table.Cell>
           {((currentPage - 1) * pageSize) + index + 1}
            </Table.Cell>
            <Table.Cell>
              {row.market}
            </Table.Cell>
            <Table.Cell>
              {row.cutting}
            </Table.Cell>
            <Table.Cell>
              {row.createdat}
            </Table.Cell>
                    
            <Table.Cell>
            <button
              onClick={() => { setRecordId(row._id); setOpenModal(true)}}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 rounded"
            >
             Delete
            </button>
            </Table.Cell>        
        </Table.Row>
       ))} 
     
    
    </Table.Body>
  </Table>
  </div>

  <Pagination theme={PaginationTheme} currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
   


</div> 




            
    </div>
  )
}

export default CuttingReport








