import React,{useEffect, useState} from 'react'
import SubHeading from '../../../components/SubHeading'
import Card from '../../../components/Card'
import LinkButton from '../../../components/LinkButton'
import { CiCirclePlus } from "react-icons/ci";
import { FaCut } from "react-icons/fa";
import { CiShop } from "react-icons/ci";

import { FaArrowDownShortWide } from "react-icons/fa6";
import { Modal,Button} from 'flowbite-react';
import { IoIosAdd, IoIosColorFill, IoIosRemove } from 'react-icons/io';
import SearchInput from '../../../components/SearchInput'
import Spreadsheet from 'react-spreadsheet';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

import {Route, Link, Routes, useParams} from 'react-router-dom';
import axios from 'axios';

import {
  Formik,
  useFormik,
  FormikHelpers,
  FormikProps,
  Form,
  FieldArray,
  Field,
  ErrorMessage,
  FieldProps,
} from 'formik';
import * as Yup from 'yup';
import ExcelForm from '../../../components/ExcelForm';
import FileUploadForm from '../../../components/FileUploadForm';
import ImageSlider from '../../../components/ImageSlider';

const API_URL = process.env.REACT_APP_API_URL;




function ProductionArticleTable() {

    const [Total , setTotal] = useState(0);
    const [Days , setDays] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const params = useParams();
     const data1 =  [{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }];
    const [data, setData] = useState([ ]);
  

    const addRecord = async (value) => {
        try {
          const response = await axios.post(`${API_URL}/api/punching/production/article/table`, {
            data: {
              title: decodeURIComponent(params.title),
              key: decodeURIComponent(params.key),
              value : value
            }
          });
          alert("Saved Successfully");
         
        } catch (error) {
          alert(error);
        }
      };

      //marketQuantityTypes_prsuitTypes_fabrics_productionQuantityTypes_colors_articleTypes
      const [prsuitTypes, setPrsuitTypes] = useState([]);
      const [fabrics, setFabrics] = useState([]);
      const [productionQuantityTypes, setProductionQuantityTypes] = useState([]);
      const [productionTypes, setProductionTypes] = useState([]);
      const [colors, setColors] = useState([]);
      const [articlesTypes, setArticlesTypes] = useState([]);

      const getAllDropDownRecords = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/marketQuantityTypes_prsuitTypes_fabrics_productionQuantityTypes_colors_articlesTypes_productionTypes`);

          setPrsuitTypes(response.data.prsuitTypes_data.map((item) => ({ title: item.name, value: item.name })));          
          setFabrics(response.data.fabrics_data.map((item) => ({ title: item.name, value: item.name })));
          setProductionQuantityTypes(response.data.productionQuantityTypes_data.map((item) => ({ title: item.name, value: item.name })));
          setColors(response.data.colors_data.map((item) => ({ title: item.name, value: item.name })));
          setArticlesTypes(response.data.articlesTypes_data.map((item) => ({ title: item.name, value: item.name })));
          setProductionTypes(response.data.productionTypes_data.map((item) => ({ title: item.name, value: item.name })));
        } catch (error) {
          console.error(error);
        }
      };
      
    useEffect(() => {
        const getProduction = async () => {
          try {
            const response = await axios.get(`${API_URL}/api/punching/production/article/`+encodeURIComponent(params.key)+"/"+encodeURIComponent(params.title));
          //  console.log(Array.isArray(response.data.data[0].value));

            setData(Array.isArray(response.data.data[0].value)==false ? [data1] :  response.data.data[0].value);

            const totalSumDays = sumLastElement(Array.isArray(response.data.data[0].value)==false ? [data1] :  response.data.data[0].value);
            const totalSum = sum2LastElement(Array.isArray(response.data.data[0].value)==false ? [data1] :  response.data.data[0].value);
            setTotal(totalSum)
            setDays(totalSumDays)

            getAllDropDownRecords();

          } catch (error) {
            console.error(error);
          }
        };
        getProduction();
      }, []);
    


  const removeRow = () => {
    if (data.length > 0) {
      const newData = [...data];
      newData.pop(); // Remove the last row
      setData(newData);

      const totalSumDays = sumLastElement(newData);
      const totalSum = sum2LastElement(newData);
      setTotal(totalSum)
      setDays(totalSumDays)

      setOpenModal(false)
    }
  };
  
  const addRow = () => {
    if (!data || !data[0] || typeof data[0].length === 'undefined') {
      // Handle the case where data is undefined or data[0] is undefined or data[0].length is undefined
      setData( [data1]);
      return;
    }
  
    const numColumns = data[0].length;
    const newEmptyRow = Array.from({ length: numColumns }, () => ({ value: '' }));
    const newData = [...data, newEmptyRow];
    setData(newData);
  };
  
  const submit = ()=>{
    console.log(data);
    addRecord(data);
  }

  const sumLastElement = (array) => {
    return array.map((row) => {
      const lastElement = row[row.length - 1];
      const value = parseInt(lastElement.value, 10) || 0; // Parse value as an integer (assuming values are numbers)
      return value;
    }).reduce((acc, curr) => acc + curr, 0);
  };

  const sum2LastElement = (array) => {
    return array.map((row) => {
      const lastElement = row[row.length - 2];
      const value = parseInt(lastElement.value, 10) || 0; // Parse value as an integer (assuming values are numbers)
      return value;
    }).reduce((acc, curr) => acc + curr, 0);
  };

  const handleChange = (changes) => {
    setData(changes);
    const totalSumDays = sumLastElement(changes);
    const totalSum = sum2LastElement(changes);
    setTotal(totalSum)
    setDays(totalSumDays)
  };





  return (
    <div>





        <SubHeading title={"Production"} />


            <div id='market' className="overflow-x-auto mt-5">

<h2 class="text-2xl font-bold dark:text-black mb-4">{params.key+' - '+params.title }</h2>




          </div>

     
      <FileUploadForm bookid={params.key} designid={params.title} />
    
  


<ExcelForm  bookid={params.key} designid={params.title} colors={colors} productionTypes = {productionTypes} columns={[
      
        {heading:"Sno",title:"Sno",type:"sno"},
        {heading:"Fabric", title:"Fabric",type:"select", option:
          fabrics
       
       },
        { heading:"Article", title:"Article",subTitle:"Article-type",type:"number_with_select" ,option:
          articlesTypes
          } ,
        { heading:"PR-Suit", title:"PR-Suit",subTitle:"PR-type",type:"text_with_select" ,option:
        prsuitTypes

          },

        { heading:"GIVEN RATE", title:"Rate",type:"decimal"},
        { heading:"Stiches", title:"Stiches",type:"number"},
        { heading:"Head", title:"Head",type:"number"},
        { heading:"Round", title:"Round",type:"number"},
        { heading:"C/Rate", title:"C/Rate",type:"number"},
        { heading:"Yard/Meter", title:"Yard/Meter",subTitle:"Y/M-type",type:"number_with_select" ,option:[{title:"YARD",value:"YARD"},{title:"METER",value:"METER"},{title:"PCS",value:"PCS"}]  } ,
        { heading:"Round Final", title:"Round Final",subTitle:"RF-type",type:"number_with_text" ,option:[{title:"PALLA",value:"PALLA"},{title:"+8 HEAD",value:"+8 HEAD"},{title:"+7 HEAD",value:"+7 HEAD"},{title:"R",value:"R"}]  } ,
        { heading:"Quantity", title:"Quantity",subTitle:"Quantity-type",type:"number_with_select" ,option:productionQuantityTypes} ,
        { heading:"Per Round", title:"PreSuit",subTitle:"PreSuit-type",type:"number_with_text"},
        { heading:"Total Stiches", title:"Total Stiches",type:"number"},
        { heading:"Days", title:"Days",type:"number"},
        { heading:"Total Cost", title:"Cost",type:"number"},
       
        ]}/>

            
    </div>
  )
}

export default ProductionArticleTable