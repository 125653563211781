import React, { useEffect, useState } from 'react'
import SubHeading from '../../components/SubHeading'
import axios from 'axios';
import useScanDetection from 'use-scan-detection-react18';
import { toast } from 'react-toastify';
import { useLocation, useParams } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL;

function InScanner() {

const param=useLocation();
console.log(param,"state");


    useScanDetection({
        onComplete: async (barcode) => {           
                const Code = barcode.replace(/Shift/g, '');
                const data = Code.split(',');
                const bookid = data[0];
                const designid = data[1];
                const id = data[2];
                const issueno = data[3];

                await axios.post(`${API_URL}/api/cropping/dispatch`, { bookid, designid, id, issueno })
                    .then((response) => {
                        toast.success("Dispatch Successfully");
                    })
                    .catch((error) => {
                        toast.error(error.response.data.error,)
                    });

        },
    });


    return (
        <div>
            <SubHeading title={'Cropping Department In Scanner'} />
     
                    <p className='text-2xl text-center font-bold mb-5'>SCAN BARCODE</p>
                    <p className='text-md text-center font-semibold mb-5'>Assign to {param?.state?.search} </p>
                    <div className='flex justify-center'>

                        <img src='/gif/scanner.gif' height={300} width={300} />
                    </div>
             
                

        </div>

    )
}

export default InScanner


