import React from 'react';
import LinkButton from '../../components/LinkButton';
import SubHeading from '../../components/SubHeading';
import { jwtDecode } from 'jwt-decode'
import { MdCallReceived } from "react-icons/md";

const Index = () => {
  const user = jwtDecode(localStorage?.getItem('token'));
  
  console.log(user, "==userrrrrrr");
  

  return (
<>

    <div>

      <SubHeading title={'Dashboard'} />

      {(user?.user?.role === 'punching' || user?.role === 'punching') ? 
    <div>
         <div className="flex  flex-col md:flex-row gap-8 justify-center items-center ">
        <LinkButton
          url={'/Punching'}
          buttonName={'Planning Department'}
          icon={<img src="/Images/punching.png" alt="punching" width={50} height={50} />}
        />
        </div>
    </div>
:
(user?.user?.role === 'cutting' || user?.role === 'cutting') ? 
    <div>
       <div className="flex  flex-col md:flex-row gap-8 justify-center items-center ">
       <LinkButton
          buttonName={'Cutting Department'}
          url={'/Cutting'}
          icon={<img src="/Images/cutting.png" alt="cutting" width={50} height={50} />}
        />
   <LinkButton
        url={'/MarketReceived'}
        buttonName={'Market Received'}
        icon={<img src="/Images/punching.png" alt="Market Received" width={50} height={50} />}
      />
        </div>
    </div>

:
(user?.user?.role === 'market' || user?.user === 'market') ? 
    <div>
       <div className="flex  flex-col md:flex-row gap-8 justify-center items-center ">
       <LinkButton
          url={'/MarketReceived'}
          buttonName={'Market Received'}
          icon={<img src="/Images/punching.png" alt="Market Received" width={50} height={50} />}
        />

        </div>
    </div>

:
(user?.user?.role === 'market department' || user?.role === 'market department') ? 
    <div>
       <div className="flex  flex-col md:flex-row gap-8 justify-center items-center ">
       <LinkButton buttonName={'Market Department'} url={'/Market'} icon={<img src="/Images/market.png" alt="market" width={50} height={50} />} />


        </div>
    </div>

:
(user?.user?.role === 'production' || user?.role === 'production') ? 
    <div>
       <div className="flex  flex-col md:flex-row gap-8 justify-center items-center ">
       <LinkButton url={'/Production'} buttonName={'Production Department'} icon={<img src="/Images/production.png" alt="production" width={50} height={50} />} />
       <LinkButton
          buttonName={'Cutting Department'}
          url={'/Cutting'}
          icon={<img src="/Images/cutting.png" alt="cutting" width={50} height={50} />}
        />
        </div>
       
    </div>

:
(user?.user?.role === 'receiving' || user?.role === 'receiving') ? 

    <div>
       <div className="flex  flex-col md:flex-row gap-8 justify-center items-center ">
         <LinkButton buttonName={'Receiving Department'} url={'/Receiving'} icon={<MdCallReceived  alt="percuiment" size={50} />} />
        </div>
       
    </div>


:
(user?.user?.role === 'cropping' || user?.role === 'cropping') ? 

    <div>
       <div className="flex  flex-col md:flex-row gap-8 justify-center items-center ">
            <LinkButton buttonName={'Cropping Department'} url={'/Cropping'} icon={<img src="/Images/cropping.png" alt="cropping" width={50} height={50} />} />
            <LinkButton buttonName={'Finishing Department'} url={'/Finishing'} icon={<img src="/Images/finishing.png" alt="finishing" width={50} height={50} />} />
        </div>
       
    </div>

      :
      <div>

        
      <div className="flex flex-wrap  flex-col md:flex-row gap-8 justify-center items-center ">
        <LinkButton
          url={'/Punching'}
          buttonName={'Planning Department'}
          icon={<img src="/Images/punching.png" alt="punching" width={50} height={50} />}
        />

<LinkButton
          url={'/MarketReceived'}
          buttonName={'Market Received'}
          icon={<img src="/Images/punching.png" alt="Market Received" width={50} height={50} />}
        />


        <LinkButton
          buttonName={'Cutting Department'}
          url={'/Cutting'}
          icon={<img src="/Images/cutting.png" alt="cutting" width={50} height={50} />}
        />
        <LinkButton url={'/Production'} buttonName={'Production Department'} icon={<img src="/Images/production.png" alt="production" width={50} height={50} />} />

       
      <LinkButton buttonName={'Receiving Department'} url={'/Receiving'} icon={<MdCallReceived  alt="percuiment" size={50} />} />
     

      <LinkButton buttonName={'Cropping Department'} url={'/Cropping'} icon={<img src="/Images/cropping.png" alt="cropping" width={50} height={50} />} />
     
        <LinkButton buttonName={'Finishing Department'} url={'/Finishing'} icon={<img src="/Images/finishing.png" alt="finishing" width={50} height={50} />} />

        <LinkButton buttonName={'Market Department'} url={'/Market'} icon={<img src="/Images/market.png" alt="market" width={50} height={50} />} />



      <LinkButton buttonName={'Percuiment Department'} url={'/Percumint'} icon={<img src="/Images/procurement.png" alt="percuiment" width={50} height={50} />} />

      <LinkButton buttonName={'Gate Exit Department'} icon={<img src="/Images/exit.png" alt="exit" width={50} height={50} />} />
    
      </div>
        
      </div>
      }

    </div>


  </>
  );
};

export default Index;
