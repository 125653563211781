import React,{ useEffect, useState } from 'react';
import { ErrorMessage, useFormik } from 'formik';
import axios from 'axios';
import ImageGallery from './ImageGallery';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Button } from 'flowbite-react';
import ImageSlider from './ImageSlider';
const API_URL = process.env.REACT_APP_API_URL;
const FileUploadForm = ({bookid,designid}) => {

  const [galleryKey, setGalleryKey] = useState(Date.now()); // State variable to force re-render

  const formik = useFormik({
    initialValues: {
      model: 'production',
      modelUniqueValue: bookid.replace(/#/g, '')+designid.replace(/#/g, ''),
      file: null,
    },

    validationSchema: Yup.object({
      file: Yup.mixed().required('File is required'),
    }),
    onSubmit: async (values,{ resetForm }) => {
      const formData = new FormData();
      formData.append('model', values.model);
      formData.append('modelUniqueValue',values.modelUniqueValue);
      formData.append('file', values.file);

      try {
        const response = await axios.post(`${API_URL}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setGalleryKey(Date.now()); 
        formData.delete('file');
        toast.success('File uploaded successfully.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        resetForm();
        
        console.log('File uploaded successfully:');
      } catch (error) {
        console.error('Error uploading file:', error.message);
      }
    },
  });



  return (
    <>
    <div className='flex flex-col items-center justify-center'>
    <form className='flex flex-row items-center w-full justify-center' onSubmit={formik.handleSubmit}>
      
      
      <div >
        
        <input
          type="file"
          id="file"
          name="file"
          className='text-black rounded-lg border border-gray-300 p-2 mr-2'
          accept="image/*"

          onChange={(event) => formik.setFieldValue('file', event.target.files[0])}
        />
        {formik.touched.file && formik.errors.file ? (
          <div className="text-red-500">{formik.errors.file}</div>
        ) : null}
       
      </div>
      <div>
        <Button type="submit">Upload</Button>
      </div>
    </form><br/>

    <ImageSlider  key={galleryKey}  model={"production"} modelUniqueValue={bookid.replace(/#/g, '')+designid.replace(/#/g, '')} />
    </div>
    </>
    
  );
};

export default FileUploadForm;