import axios from 'axios';
import { PaginationTheme } from '../../theme/PaginationTheme';
import { ModalTheme } from '../../theme/ModalTheme';
import useScanDetection from 'use-scan-detection-react18';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { Article } from '@mui/icons-material';
import formatDateTime from '../../helpers/Datetime';
import React, { useEffect, useState } from 'react'
import SubHeading from '../../components/SubHeading'
import Card from '../../components/Card'
import { Tabs,TextInput,Button,Table,Dropdown, Pagination, Modal } from 'flowbite-react';
import SearchInput from '../../components/SearchInput'
import { IoMdQrScanner } from "react-icons/io";
import { useParams } from 'react-router-dom';



const API_URL = process.env.REACT_APP_API_URL;

function Issueround() {


    const [data, setData] = useState([]);
    const [openModal , setOpenModal] = useState(false);
    const [bookdata , setBookdata] = useState({});

    //get id from param
    const { id,bookid,designid } = useParams();

 useEffect(() => {
    getRecords();
  }, []);

const getRecords = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/getissueround`, {
        params: {

          id : id,
          bookid : decodeURIComponent(bookid),
          designid :decodeURIComponent( designid)
        },
      });
      const pdata = response.data["productionresult"][0]["data"];
      setBookdata(pdata.filter((d) => d.id === parseInt(id))[0]);

      setData(response.data.data);


      // Calculate total pages based on the total items and page size
  
    } catch (error) {
      console.error(error);
    }
  };


const [select , setSelect] = useState('Issue Round');


const getPendingRounds = () => {
  const totalRounds = bookdata.Round || 0;
  const issuedRounds = data.map((item) => parseInt(item.issueno, 10));
  const pendingRounds = Array.from({ length: totalRounds }, (_, index) => index + 1)
    .filter(round => !issuedRounds.includes(round));

  return pendingRounds;
};

const pendingRounds = getPendingRounds();

let pendingsno = 0;


  return (
    <div>
    <SubHeading title={select === 'Issue Round'?'Issued Round':'Pending Round'} />


<div className="mt-4 flex justify-between items-center px-10">
    <select className=" rounded-md border-gray-300 h-10 text-sm focus:border-indigo-500 focus:ring-indigo-500"
    onChange={(e) => setSelect(e.target.value)}
    >
  <option value="Issue Round">Issue Round</option>
  <option value="Pending Round">Pending Round</option>
</select>


<div className='text-right'>
<div className='text-lg text-black'>Total Round: {bookdata.Round}</div>
<div className='text-lg text-black'>{select === 'Issue Round' ?  `Total Issue Round : ${data.length}` : `Total Pending Round : ${pendingRounds.length}` }</div>
</div>


</div>

{select === 'Issue Round' ?
<div className="overflow-x-auto mt-5">
  <div className='px-10'>


            <Table className=' dark:text-black'>
 
    <Table.Head>

    
    <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>#</Table.HeadCell>
    <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Book Number</Table.HeadCell>
      
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Design Number</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Article</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Round Number</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Datetime</Table.HeadCell>
   
    </Table.Head>
    <Table.Body className="divide-y">
      {data.map((row, index) => (
        <Table.Row  className="bg-white dark:border-gray-200 dark:bg-gray-300">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{index+1}</Table.Cell>
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.bookid}</Table.Cell>
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.designid}</Table.Cell>
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">  {bookdata.Article}      {bookdata["Article-type"]}</Table.Cell>
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.issueno}</Table.Cell>
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.createdat ? formatDateTime(row.createdat, 'DD/MM/YYYY hh:mm A') : 'N/A'}</Table.Cell>
        </Table.Row>
       ))} 

       {data.length === 0 && (
        <Table.Row>
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black" colSpan={7}>
            No data found
          </Table.Cell>
        </Table.Row>
      )}
     
    
    </Table.Body>
  </Table>
  </div>
</div>
:
<div className="overflow-x-auto mt-5">
<div className='px-10 '>


<Table className=' dark:text-black'>

<Table.Head>


<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>#</Table.HeadCell>
<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Book Number</Table.HeadCell>

<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Design Number</Table.HeadCell>
<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Article</Table.HeadCell>
<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Round Number</Table.HeadCell>


</Table.Head>
<Table.Body className="divide-y">
{Array.from({ length: bookdata.Round }).map((_, index) => {
              const round = index + 1;
              const roundExists = data.some((d) => d.issueno == round);
            
              if (!roundExists) {
                pendingsno = pendingsno + 1
                return (
                  <Table.Row key={index} className="bg-white dark:border-gray-200 dark:bg-gray-300">
                       <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{pendingsno}</Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{bookid}</Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{designid}</Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{bookdata.Article} {bookdata["Article-type"]}</Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{round}</Table.Cell>
                  </Table.Row>
                );
              } else {
                return null; // Skip rendering if the round is found in data
              }
            })}



</Table.Body>
</Table>

</div>
</div>
}


  </div>

  )
}

export default Issueround