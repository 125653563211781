import React, { useEffect, useState } from 'react'
import SubHeading from '../../components/SubHeading'
import Card from '../../components/Card'
import { Tabs, TextInput, Button, Table, Dropdown, Pagination, Modal } from 'flowbite-react';
import SearchInput from '../../components/SearchInput'
import { IoIosQrScanner, IoMdQrScanner } from "react-icons/io";
import { MdPendingActions } from "react-icons/md";

import axios from 'axios';
import { PaginationTheme } from '../../theme/PaginationTheme';
import { ModalTheme } from '../../theme/ModalTheme';
import useScanDetection from 'use-scan-detection-react18';
import { toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import formatDateTime from '../../helpers/Datetime';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import { FaRegFilePdf } from 'react-icons/fa';
import MyLoader from '../../components/Loader/MyLoader';
import { Link } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

function Cropping() {


    const [vendors, setVendors] = useState([]);

    const [openModal, setOpenModal] = useState(false);
    const [openDispatchModal, setOpenDispatchModal] = useState(false);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const [pageSize, setPageSize] = useState(10);

    const [totalPages, setTotalPages] = useState(1);

    const [data, setData] = useState([]);
    const [recordId, setRecordId] = useState('');

    const [totalcrate, setTotalcrate] = useState(0);
    const [issuedround, setIssuedround] = useState(0);
    const [pendinground, setPendinground] = useState(0);

    const [todaytotalcrate, settodayTotalcrate] = useState(0);
    const [todayissuedround, settodayIssuedround] = useState(0);
    const [todaypendinground, settodayPendinground] = useState(0);

    const [monthtotalcrate, setmonthTotalcrate] = useState(0);
    const [monthissuedround, setmonthIssuedround] = useState(0);
    const [monthpendinground, setmonthPendinground] = useState(0);

    const [pendingData, setPendingData] = useState([]);
    const [pendingmodel, setPendingmodel] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);

    const getVendor = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/vendors/all`);
            setVendors(response.data.data);

        } catch (error) {
            console.error(error);
        }
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
        getRecord(page, search);
    };


    const getRecord = async (page, search) => {
        setIsDataLoading(true);
        try {
            const response = await axios.get(`${API_URL}/api/cropping`, {
                params: {
                    page,
                    pageSize,
                    search
                },
            });
            setData(response.data.data);
            setTotalcrate(response.data.totalcrate);
            setIssuedround(response.data.issuedround);
            setPendinground(response.data.pendinground);
            settodayTotalcrate(response.data.todaytotalcrate);
            settodayIssuedround(response.data.todayissuedround);
            settodayPendinground(response.data.todaypendinground);
            setmonthTotalcrate(response.data.monthtotalcrate);
            setmonthIssuedround(response.data.monthissuedround);
            setmonthPendinground(response.data.monthpendinground);
            
            // Calculate total pages based on the total items and page size
            const totalItems = response.data.totalItems; // Replace with the actual key in your API response
            const calculatedTotalPages = Math.ceil(totalItems / pageSize);
            setTotalPages(calculatedTotalPages);
            setIsDataLoading(false);
        } catch (error) {
            console.error(error);
            setIsDataLoading(false);
        }
    };


    const getPendingRecord = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/cropping/pending`);

            setPendingData(response.data.data);
            console.log(response.data.data, "pendingData");
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getRecord(currentPage, search);
    }, [currentPage, pageSize]);





    useEffect(() => {
        getVendor();
        getRecord(currentPage, search);
    }, []);


    useScanDetection({
        onComplete: async (barcode) => {
            if (openModal) {
                setOpenDispatchModal(false)
                const Code = barcode.replace(/Shift/g, '');

                const data = Code.split(',');
                const bookid = data[0];
                const designid = data[1];
                const id = data[2];
                const issueno = data[3];

                await axios.post(`${API_URL}/api/cropping/issue`, { bookid, designid, id, issueno, vendor: search })
                    .then((response) => {

                        getRecord(1, search)
                        setOpenModal(true)
                        toast.success("Issued Successfully");

                    })
                    .catch((error) => {
                        toast.error(error.response.data.error,)
                        setOpenModal(true)

                    });



            }
            else if (openDispatchModal) {
                setOpenModal(false)
                const Code = barcode.replace(/Shift/g, '');
                //splite Code by ,
                const data = Code.split(',');
                const bookid = data[0];
                const designid = data[1];
                const id = data[2];
                const issueno = data[3];

                await axios.post(`${API_URL}/api/cropping/dispatch`, { bookid, designid, id, issueno })
                    .then((response) => {

                        getRecord(1, search)
                        setOpenDispatchModal(true)
                        toast.success("Dispatch Successfully");

                    })
                    .catch((error) => {
                        toast.error(error.response.data.error,)
                        setOpenDispatchModal(true)
                    });

            }
            else { toast.error("Scanner not activated") }

        },
    });


    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const d = new Date();
    let monthname = month[d.getMonth()];

    const [selectmonth, setSelectmonth] = useState('');

    const [filterdatetotalcrate, setFilterdatetotalcrate] = useState(0);
    const [filterdateissuedround, setFilterdateissuedround] = useState(0);
    const [filterdatependinground, setFilterdatependinground] = useState(0);

    const getFilterMonthTotals = async () => {
        const monthindex = month.indexOf(selectmonth)
        const response = await axios.get(`${API_URL}/api/cropping/filterbymonth/totalrate/issuedrate/pendingrate`, {
            params: {
                month: monthindex,
                search: search
            },
        });
        console.log(response);
        setFilterdatetotalcrate(response.data.totalcrate);
        setFilterdateissuedround(response.data.issuedround);
        setFilterdatependinground(response.data.pendinground);
    }

    useEffect(() => {
        getFilterMonthTotals();
    }, [search]);

    const handlemonthChangefilter = async (event) => {

        const monthindex = month.indexOf(event.target.value)

        setSelectmonth(event.target.value);
        const response = await axios.get(`${API_URL}/api/cropping/filterbymonth/totalrate/issuedrate/pendingrate`, {
            params: {
                month: monthindex,
                search: search
            },
        });
        console.log(response);
        setFilterdatetotalcrate(response.data.totalcrate);
        setFilterdateissuedround(response.data.issuedround);
        setFilterdatependinground(response.data.pendinground);
    };


    const handleExportTablePDF = (tableIndex) => {
        const doc = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: 'a2', // Adjust format if needed
        });
      
        const imgBase64 = '/new/nav-main-logo.png'; // Replace with your image's base64 string
        const imgWidth = 60;  // Adjust width
        const imgHeight = 20; // Adjust height
      
        // Add the image at the top (centered)
        doc.addImage(imgBase64, 'PNG', (doc.internal.pageSize.getWidth() - imgWidth) / 2, 5, imgWidth, imgHeight);
      
        doc.setFontSize(22);
      
        // Table specific title, adjust the Y-position to be below the image
        const title = `Cropping - Table ${tableIndex + 1}`;
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleTextWidth = doc.getTextWidth(title);
        const titleXOffset = (pageWidth - titleTextWidth) / 2;
        doc.text(title, titleXOffset, imgHeight + 15); // Adjust title Y-position to account for the image
      
        // Extract headers and data for the specific table
        const tableHeaders = [
          '#',
          'Vendor',
          'B#',
          'D#',
          'Fabric',
          'Articles',
          'Article Type',
          'Created',
          'Round Number',
          'Rate',
          'Total',
        ];
      
        console.log(pendingData[tableIndex].createdat_array,pendingData[tableIndex],"dataTest");
      
        const tableData = pendingData[tableIndex].createdat_array.map((createdAt, idx) => [
          idx + 1,
          idx === 0 ? pendingData[tableIndex]._id.vendor:"", // Adding Vendor name here
          idx === 0 ? pendingData[tableIndex]._id.bookid : '',
          pendingData[tableIndex].designid.indexOf(pendingData[tableIndex].designid[idx]) === idx
            ? pendingData[tableIndex].designid[idx]
            : '',
          pendingData[tableIndex].data_Fabric.indexOf(pendingData[tableIndex].data_Fabric[idx]) === idx
            ? pendingData[tableIndex].data_Fabric[idx]
            : '',
          pendingData[tableIndex].data_Article.indexOf(pendingData[tableIndex].data_Article[idx]) === idx
            ? pendingData[tableIndex].data_Article[idx]
            : '',
          pendingData[tableIndex].data_Article_type.indexOf(pendingData[tableIndex].data_Article_type[idx]) === idx
            ? pendingData[tableIndex].data_Article_type[idx]
            : '',
          formatDateTime(createdAt, 'DD/MM/YYYY hh:mm A'),
          pendingData[tableIndex].roundno_array[idx],
          pendingData[tableIndex].data[idx],
          idx === 0 ? pendingData[tableIndex].count:" ",
        ]);
      
        // Generate PDF Table with updated font size and padding
        autoTable(doc, {
          head: [tableHeaders],
          body: tableData,
          startY: imgHeight + 30, // Ensure the table starts below the image and title
          styles: {
            fontSize: 14,       // Increased font size for table content
            cellPadding: 5,     // Added padding inside table cells
            overflow: 'linebreak',
            columnWidth: 'wrap',
          },
          columnStyles: {
            2: { columnWidth: 'auto' },
          },
        });
      
        // Calculate the position for the "Total Rate" heading
        const totalRateText = `Total Rate: ${calculateTotalRate(pendingData[tableIndex].data)}`;
        const totalRateX = doc.internal.pageSize.getWidth() - 50; // Position it on the right side
        const totalRateY = doc.autoTable.previous.finalY + 10; // Position it below the table
        
        doc.setFontSize(16); // Set font size for the "Total Rate" heading
        doc.text(totalRateText, totalRateX, totalRateY); // Add the text
      
        // Save the PDF with a unique name
        doc.save(` -${tableIndex + 1}.pdf`);
      };
      
      // Helper function to calculate the total rate
      const calculateTotalRate = (data) => {
        return data.reduce((total, rate) => total + rate, 0);
      };
      
  
  const handleFilter = async (values) => {
    try {
      setIsDataLoading(true);
      const response = await axios.get(`${API_URL}/api/cropping`, {
        params: {
          page: 1,
          pageSize: pageSize,
          bookid: values.bookid,
          designid: values.designid,
          article: values.article,
          search: search
        },
      });
setTimeout(()=>{

  setData(response.data.data);
  setTotalcrate(response.data.totalcrate);
  setIssuedround(response.data.issuedround);
  setPendinground(response.data.pendinground);
  settodayTotalcrate(response.data.todaytotalcrate);
  settodayIssuedround(response.data.todayissuedround);
  settodayPendinground(response.data.todaypendinground);
  setmonthTotalcrate(response.data.monthtotalcrate);
  setmonthIssuedround(response.data.monthissuedround);
  setmonthPendinground(response.data.monthpendinground);
  
  setIsDataLoading(false);
  // Calculate total pages based on the total items and page size
  const totalItems = response.data.totalItems; // Replace with the actual key in your API response
  const calculatedTotalPages = Math.ceil(totalItems / pageSize);
  setTotalPages(calculatedTotalPages);
}, 1000)
    } catch (error) {
      console.error(error);
    }
  }

    return (
        <div>
            <SubHeading title={'Cropping Department'} />

            <div className='flex justify-center' >
      <Link to={`/cropping/outscanner`}>
<Button className='p-2 mt-2 mb-2 bg-blue-500 hover:bg-blue-700 text-white rounded  h-10'><IoIosQrScanner size={25} />&nbsp;Out Scanner</Button>
</Link>
{search !== '' && (
      <Link to={`/cropping/inscanner`} state={{search:search}} >
<Button className='p-2 mt-2 mb-2 bg-blue-500 ml-2 hover:bg-blue-700 text-white rounded  h-10'><IoIosQrScanner size={25} />&nbsp;In Scanner</Button>
</Link>
)}
      </div>

            <Modal theme={ModalTheme} show={openModal} size="md" onClose={() => setOpenModal
                (false)} popup>
                <Modal.Header className='' />
                <Modal.Body className=''>

                    <p className='text-2xl text-center font-bold mb-5'>SCAN BARCODE</p>
                    <p className='text-md text-center font-semibold mb-5'>Assign to {search}</p>
                    <div className='flex justify-center'>

                        <img src='/gif/scanner.gif' height={300} width={300} />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal theme={ModalTheme} show={openDispatchModal} size="md" onClose={() => setOpenDispatchModal
                (false)} popup>
                <Modal.Header className='' />
                <Modal.Body className=''>
                    <p className='text-2xl text-center font-bold mb-5'>SCAN BARCODE</p>
                    <p className='text-md text-center font-semibold mb-5'>Dispatch</p>
                    <div className='flex justify-center'>

                        <img src='/gif/scanner.gif' height={300} width={300} />
                    </div>
                </Modal.Body>
            </Modal>


            <Modal theme={ModalTheme} show={pendingmodel} size="6xl" onClose={() => setPendingmodel
                (false)} popup>
                <Modal.Header className='' />
                <Modal.Body className=''>
                    <p className='text-2xl text-center font-bold mb-5'>PENDING</p>

                    <div className=' overflow-x-auto'>


                        {/* <Table className=' dark:text-black '>

<Table.Head>
<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Vendor</Table.HeadCell>
<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Book Number</Table.HeadCell>

<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Design Number</Table.HeadCell>
<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Article</Table.HeadCell>
<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Round No.</Table.HeadCell>



<Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Date</Table.HeadCell>

</Table.Head>
<Table.Body className="divide-y">
{pendingData.sort((a, b) => a._id.vendor.localeCompare(b._id.vendor)).map((row, index) => (
<Table.Row  className="bg-white dark:border-gray-200 dark:bg-gray-300">
<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row._id.vendor}</Table.Cell>
<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row._id.bookid}</Table.Cell>
<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row._id.designid}</Table.Cell>
<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row._id.data_Article + " - " + row._id.data_Article_type }</Table.Cell>
       
<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black"> <ul className="list-disc">
          {row.roundno_array.map((roundno, index) => (
          <li key={index} className='flex gap-2 mb-2 items-center'> 
            {roundno}  
          </li>
        ))}
      </ul></Table.Cell>




       
       <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black"> <ul className="list-disc">
          {row.createdat_array.map((createdAt, index) => (
          <li key={index}>
            {formatDateTime(createdAt, 'DD/MM/YYYY hh:mm A')}
          </li>
        ))}
      </ul></Table.Cell>

</Table.Row>

))} 

{pendingData.length === 0 && (
<Table.Row>
<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black" colSpan={7}>
No data found
</Table.Cell>
</Table.Row>
)}


</Table.Body>
</Table> */}



                        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                            {pendingData
                                .sort((a, b) => a._id.vendor.localeCompare(b._id.vendor))
                                .map((row, tableIndex) => (
                                    console.log(row, "data"),
                                    <div key={tableIndex}>
                                        {/* Button to download the table as PDF */}
                                        <button
                                            className="flex justify-center mt-2 ml-2 mb-2 bg-red-500 hover:bg-red-700 text-white rounded w-[200px] mb-3 h-10 items-center gap-1"
                                            onClick={() => handleExportTablePDF(tableIndex)}
                                        >
                                            <FaRegFilePdf /> Export Table {tableIndex + 1} PDF
                                        </button>

                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
                                            <tr className="text-center">
                                                <th scope="col" className="px-6 py-3">#</th>
                                                <th scope="col" className="px-6 py-3">Vendor</th>
                                                <th scope="col" className="px-6 py-3">B#</th>
                                                <th scope="col" className="px-6 py-3">D#</th>
                                                <th scope="col" className="px-6 py-3">Fabric</th>
                                                <th scope="col" className="px-6 py-3">Articles</th>
                                                <th scope="col" className="px-6 py-3">Articles Type</th>
                                                <th scope="col" className="px-6 py-3">Created</th>
                                                <th scope="col" className="px-6 py-3">Round Number</th>
                                                <th scope="col" className="px-6 py-3">Rate</th>
                                                <th scope="col" className="px-6 py-3">Total</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {row.createdat_array
                                                .map((createdAt, index) => ({
                                                    createdAt,
                                                    index,
                                                    bookid: row.bookid[index],
                                                    designid: row.designid[index],
                                                    data_Fabric: row.data_Fabric[index],
                                                    data_Article: row.data_Article[index] || '', // Ensuring data_Article is defined
                                                    data_Article_type: row.data_Article_type[index],
                                                    roundno: row.roundno_array[index],
                                                    data: row.data[index]
                                                }))
                                                .sort((a, b) => {
                                                    // Ensuring both data_Article values are strings before comparing
                                                    const articleA = a.data_Article ? a.data_Article.toString() : '';
                                                    const articleB = b.data_Article ? b.data_Article.toString() : '';
                                                    return articleA.localeCompare(articleB);
                                                })
                                                .map((item, idx) => (
                                                    <tr className="border-gray-300 border-b text-center" key={idx}>
                                                        <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{idx + 1}</td>

                                                        {/* Only show the vendor in the very first row */}
                                                        {idx === 0 ? (
                                                            <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row._id.vendor}</td>
                                                        ) : (
                                                            <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                                                        )}

                                                        {/* Conditional display for bookid */}
                                                        {idx === 0 || item.bookid !== row.bookid[idx - 1] ? (
                                                            <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{item.bookid}</td>
                                                        ) : (
                                                            <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                                                        )}

                                                        {/* Conditional display for designid */}
                                                        {idx === 0 || item.designid !== row.designid[idx - 1] ? (
                                                            <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{item.designid}</td>
                                                        ) : (
                                                            <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                                                        )}

                                                        <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{item.data_Fabric}</td>

                                                        <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{item.data_Article}</td>

                                                        <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{item.data_Article_type}</td>

                                                        <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                                                            {formatDateTime(item.createdAt, 'DD/MM/YYYY hh:mm A')}
                                                        </td>

                                                        <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{item.roundno}</td>

                                                        <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{item.data * 1}</td>

                                                        {/* Only show the total in the very first row */}
                                                        {idx === 0 ? (
                                                            <td className="whitespace-nowrap text-gray-900 text-lg font-bold dark:text-black">{row.count}</td>
                                                        ) : (
                                                            <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                                                        )}
                                                    </tr>
                                                ))}
                                        </tbody>

                                        <div className='flex justify-end mt-2' >
                                        <div>
  <span className='font-bold text-xs  ' > Total Rate : {row.data.reduce((a, b) => a + b, 0)} </span> 
</div>
</div>

                                    </div>



                                ))}
                        </table>


                    </div>




                </Modal.Body>
            </Modal>

            <div className='flex justify-end  mb-2 text-lg mr-10 '>
                <ul className='text-left flex gap-5 bg-white rounded-lg p-5'>
                    <li>{monthname} Amount : <span className='font-bold text-black'> {monthtotalcrate} </span> </li>
                    <li><span className='font-bold p-2 text-white bg-green-500 rounded-lg'> {monthissuedround} </span> </li>
                    <li><span className='font-bold p-2  text-white bg-red-500 rounded-lg'> {monthpendinground} </span> </li>
                    <li><span className='font-bold p-2  text-white bg-gray-500 rounded-lg'> {monthpendinground + monthissuedround} </span> </li>
                </ul>
            </div>
            <div className='flex justify-end  mb-2 text-lg mr-10 '>
                <ul className='text-left flex gap-5 bg-white rounded-lg p-5'>
                    <li>Today Amount : <span className='font-bold text-black'> {todaytotalcrate} </span> </li>
                    <li><span className='font-bold p-2 text-white bg-green-500 rounded-lg'> {todayissuedround} </span> </li>
                    <li><span className='font-bold p-2  text-white bg-red-500 rounded-lg'> {todaypendinground} </span> </li>
                    <li><span className='font-bold p-2  text-white bg-gray-500 rounded-lg'> {todaypendinground + todayissuedround} </span> </li>
                </ul>
            </div>
            <div className='flex justify-between  mb-2 text-lg mr-10 ml-10'>

                <ul className='text-left flex gap-5 bg-white rounded-lg p-5'>
                    <li> <select name="" id="" value={selectmonth}
                        onChange={(e) => handlemonthChangefilter(e)}
                    >
                        <option value="">Select Month</option>
                        {month.map((month, index) => (
                            <option key={index} value={month}>{month}</option>
                        ))}
                    </select>
                        {selectmonth != '' && <> Amount : <span className='font-bold text-black'> {filterdatetotalcrate} </span> </>} </li>
                    {selectmonth != '' && <> <li><span className='font-bold p-2 text-white bg-green-500 rounded-lg'> {filterdateissuedround} </span> </li> </>}
                    {selectmonth != '' && <> <li><span className='font-bold p-2  text-white bg-red-500 rounded-lg'> {filterdatependinground} </span> </li> </>}

                </ul>


                <ul className='text-left flex gap-5 bg-white rounded-lg p-5'>
                    <li>Total Amount : <span className='font-bold text-black'> {totalcrate} </span> </li>
                    <li><span className='font-bold p-2 text-white bg-green-500 rounded-lg'> {issuedround} </span> </li>
                    <li><span className='font-bold p-2  text-white bg-red-500 rounded-lg'> {pendinground} </span> </li>
                    <li><span className='font-bold p-2  text-white bg-gray-500 rounded-lg'> {issuedround + pendinground} </span> </li>
                </ul>

            </div>
            <div className="overflow-x-auto mt-5 ">
                <div className='flex items-center pb-4 justify-between items-center mx-10'>
                    <Formik initialValues={{
                        bookid: '', designid: '', article: ''
                    }}
                        onSubmit={async (values) => {
                            try {
                                const response = await axios.get(`${API_URL}/api/cropping`, {
                                    params: {
                                        page: 1,
                                        pageSize: pageSize,
                                        bookid: values.bookid,
                                        designid: values.designid,
                                        article: values.article,
                                        search: search
                                    },
                                });

                                setData(response.data.data);
                                setTotalcrate(response.data.totalcrate);
                                setIssuedround(response.data.issuedround);
                                setPendinground(response.data.pendinground);
                                settodayTotalcrate(response.data.todaytotalcrate);
                                settodayIssuedround(response.data.todayissuedround);
                                settodayPendinground(response.data.todaypendinground);
                                setmonthTotalcrate(response.data.monthtotalcrate);
                                setmonthIssuedround(response.data.monthissuedround);
                                setmonthPendinground(response.data.monthpendinground);

                                // Calculate total pages based on the total items and page size
                                const totalItems = response.data.totalItems; // Replace with the actual key in your API response
                                const calculatedTotalPages = Math.ceil(totalItems / pageSize);
                                setTotalPages(calculatedTotalPages);
                            } catch (error) {
                                console.error(error);
                            }
                        }}
                    >
                        <Form className='flex items-center gap-2'>

                            <Field name="bookid" placeholder="Book Id" className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
                            <Field name="designid" placeholder="Design Id" className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
                            <Field name="article" placeholder="Article Number" className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
                            <Button type="submit">Filter</Button>
                        </Form>
                    </Formik>




                    <div className="flex items-center  gap-4 pr-2 ">

                        <button
                            onClick={() => {
                                setPendingmodel(true);
                                getPendingRecord();
                            }}
                            className="flex items-center gap-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-2 rounded"
                        >

                            PENDING <MdPendingActions size={30} />
                        </button>


                        <button
                            onClick={() => {
                                setOpenDispatchModal(true);
                            }}
                            className="flex items-center gap-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-2 rounded"
                        >

                            OUT <IoMdQrScanner size={30} />
                        </button>


                        {search !== '' && (
                            <button
                                onClick={() => {
                                    setOpenModal(true);
                                }}
                                className="flex items-center gap-2  bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 rounded"
                            >

                                IN    <IoMdQrScanner size={30} />
                            </button>
                        )}
                        <select value={search} onChange={(e) => {
                            setSearch(e.target.value);
                            getRecord(1, e.target.value);
                        }} className='bg-[#F9FAFB] border border-gray-300 text-gray-900 text-sm  rounded-lg w-60 h-12   '>
                            <option value="" selected>All Vendor</option>
                            {vendors.map((vendor) => (
                                <option value={vendor.name}>{vendor.name}</option>
                            ))}
                        </select>

                    </div>



                </div>



                    {isDataLoading ? <MyLoader /> : 
                <div className='px-10'>

                        <Table className=' dark:text-black'>

                            <Table.Head>
                                <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Vendor</Table.HeadCell>
                                <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Book Number</Table.HeadCell>

                                <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Design Number</Table.HeadCell>

                                <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Fabric</Table.HeadCell>

                                <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Article</Table.HeadCell>



                                <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Round No.</Table.HeadCell>
                                <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Cropping Status</Table.HeadCell>

                                <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Rate</Table.HeadCell>
                                <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Total Amount</Table.HeadCell>
                                <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Date</Table.HeadCell>

                            </Table.Head>
                            <Table.Body className="divide-y">
  {data
    .sort((a, b) => a.roundno - b.roundno) // Sort data by roundno
    .map((row, index) => (
      <Table.Row
        key={index}
        className="bg-white dark:border-gray-200 dark:bg-gray-300"
      >
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
          {row.vendor}
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
          {row.bookid}
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
          {row.designid}
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
          {row.data.Fabric}
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
          {row.data.Article + " " + row.data["Article-type"]}
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
          {row.roundno}
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
          <div
            className={`px-3 py-1 ${
              !row.dispatch ? "bg-red-500 text-white" : "bg-green-500 text-white"
            } rounded-xl`}
          >
            {!row.dispatch ? "In" : "Out"}
          </div>
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
          {row.data["C/Rate"]}
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
          {row.data["C/Rate"] * 1}
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
          {formatDateTime(row.createdat, "DD/MM/YYYY hh:mm A")}
        </Table.Cell>
      </Table.Row>
    ))}

  {data.length === 0 && (
    <Table.Row>
      <Table.Cell
        className="whitespace-nowrap font-medium text-gray-900 dark:text-black"
        colSpan={7}
      >
        No data found
      </Table.Cell>
    </Table.Row>
  )}
</Table.Body>

                        </Table>
                </div>
                    }


                {data.length > 0 && (
                    <Pagination theme={PaginationTheme} currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
                )}


            </div>







        </div>

    )
}

export default Cropping


