import React, { Fragment, useEffect, useState } from "react";
import SubHeading from "../../components/SubHeading";
import Card from "../../components/Card";
import {
  Tabs,
  TextInput,
  Button,
  Table,
  Dropdown,
  Pagination,
  Modal,
} from "flowbite-react";
import SearchInput from "../../components/SearchInput";
import { IoIosQrScanner, IoMdQrScanner } from "react-icons/io";
import { Link, useSearchParams } from "react-router-dom";

import axios from "axios";
import { PaginationTheme } from "../../theme/PaginationTheme";
import { ModalTheme } from "../../theme/ModalTheme";
import useScanDetection from "use-scan-detection-react18";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import { Article } from "@mui/icons-material";
import formatDateTime from "../../helpers/Datetime";
import MyLoader from "../../components/Loader/MyLoader";
import ButtonGroup from "../../components/ButtonGroup/Index";
import ProductionIndex from "../ProductionDepartment/Index";

const API_URL = process.env.REACT_APP_API_URL;

const itemsForButtonGroup = [
  { id: "receiving", title: "Receiving" },
  { id: "production-summary", title: "Production Summary" },
];

function ReceivingDepartment() {
  const [searchParams, setSearchParams] = useSearchParams();
  // const [currentPage, setCurrentPage] = useState(1);
  // const [pageSize, setPageSize] = useState(100);
  // const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    searchParams.get("tab") || itemsForButtonGroup[0]?.id
  );

  const [issuedround, setIssuedround] = useState(0);
  const [pendinground, setPendinground] = useState(0);

  const [todayissuedround, settodayIssuedround] = useState(0);
  const [todaypendinground, settodayPendinground] = useState(0);

  const [monthissuedround, setmonthIssuedround] = useState(0);
  const [monthpendinground, setmonthPendinground] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);

  // const onPageChange = (page) => {
  //   setCurrentPage(page);
  // };

  const getRecords = async () => {
    try {
      setIsDataLoading(true);
      const response = await axios.get(`${API_URL}/api/production/filter`, {
        params: {
          // page: currentPage,
          // pageSize: pageSize,
        },
      });

      setData(response.data.data);

      setIssuedround(response.data.issuedround);
      setPendinground(response.data.pendinground);

      settodayIssuedround(response.data.todayissuedround);
      settodayPendinground(response.data.todaypendinground);

      setmonthIssuedround(response.data.monthissuedround);
      setmonthPendinground(response.data.monthpendinground);
      setIsDataLoading(false);
      // Calculate total pages based on the total items and page size
      // const totalItems = response.data.totalItems;
      // const calculatedTotalPages = Math.ceil(totalItems / pageSize);
      // setTotalPages(calculatedTotalPages);
    } catch (error) {
      console.error(error);
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    getRecords();
  }, []);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  let monthname = month[d.getMonth()];

  useScanDetection({
    onComplete: async (barcode) => {
      if (openModal) {
        const Code = barcode.replace(/Shift/g, "");
        //splite Code by ,
        const data = Code.split(",");
        const bookid = data[0];
        const designid = data[1];
        const id = data[2];
        const issueno = data[3];

        await axios
          .post(`${API_URL}/api/production/issue_round`, {
            bookid,
            designid,
            id,
            issueno,
          })
          .then((response) => {
            setOpenModal(true);
            toast.success("Issued Successfully");
            getRecords();
          })
          .catch((error) => {
            console.log(error);
            toast.error(error.response.data.error);
          });
      } else {
        toast.error("Scanner not activated");
      }
    },
  });

  const handleTabsChange = (id) => {
    // Add or update query params without changing the route
    setSearchParams({ tab: id });
    setSelectedTab(id);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Define how many rows per page you want

  // Calculate total pages
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  // Slice the data for pagination
  const paginatedData = data?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  return (
    <div>
      <SubHeading title={"Receiving Department"} />
      <div className="flex justify-center">
        <ButtonGroup
          items={itemsForButtonGroup}
          selected={selectedTab}
          onChange={handleTabsChange}
        />
      </div>
      <div className="flex justify-center">
        <Link to={`/receiving/scanner`}>
          <Button className="p-2 mt-2 mb-2 bg-blue-500 hover:bg-blue-700 text-white rounded  h-10">
            <IoIosQrScanner size={25} />
            &nbsp;Scanner
          </Button>
        </Link>
      </div>
      {selectedTab === itemsForButtonGroup[0]?.id && (
        <>
          <Modal
            theme={ModalTheme}
            show={openModal}
            size="md"
            onClose={() => setOpenModal(false)}
            popup
          >
            <Modal.Header className="" />
            <Modal.Body className="">
              <p className="text-2xl text-center font-bold mb-5">
                SCAN BARCODE
              </p>
              <div className="flex justify-center">
                <img src="/gif/scanner.gif" height={300} width={300} />
              </div>
            </Modal.Body>
          </Modal>

          <div className="flex justify-end  mb-2 text-lg mr-10 ">
            <ul className="text-left flex gap-5 justify-between bg-white rounded-lg p-5 w-[20%]">
              <li className="text-nowrap">{monthname} : </li>
              <li className="flex items-center justify-evenly w-[100%]">
                <li>
                  <span className="font-bold p-2 text-white bg-green-500 rounded-lg">
                    {" "}
                    {monthissuedround}{" "}
                  </span>{" "}
                </li>
                <li>
                  <span className="font-bold p-2  text-white bg-red-500 rounded-lg">
                    {" "}
                    {monthpendinground}{" "}
                  </span>{" "}
                </li>
                <li>
                  <span className="font-bold p-2  text-white bg-gray-500 rounded-lg">
                    {" "}
                    {monthissuedround + monthpendinground}{" "}
                  </span>{" "}
                </li>
              </li>
            </ul>
          </div>

          <div className="flex justify-end  mb-2 text-lg mr-10 ">
            <ul className="text-left flex gap-5 justify-between bg-white rounded-lg p-5  w-[20%]">
              <li className="text-nowrap">Today : </li>
              <li className="flex items-center justify-evenly w-[100%]">
                <li>
                  <span className="font-bold p-2 text-white bg-green-500 rounded-lg">
                    {" "}
                    {todayissuedround}{" "}
                  </span>{" "}
                </li>
                <li>
                  <span className="font-bold p-2  text-white bg-red-500 rounded-lg">
                    {" "}
                    {todaypendinground}{" "}
                  </span>{" "}
                </li>
                <li>
                  <span className="font-bold p-2  text-white bg-gray-500 rounded-lg">
                    {" "}
                    {todayissuedround + todaypendinground}{" "}
                  </span>{" "}
                </li>
              </li>
            </ul>
          </div>

          <div className="flex justify-end  mb-5 text-lg mr-10 ">
            <ul className="text-left flex justify-between gap-5 bg-white rounded-lg p-5 w-[20%] ">
              <li className="text-nowrap">Total : </li>
              <li className="flex items-center justify-evenly w-[100%]">
                <li>
                  <span className="font-bold p-2 text-white bg-green-500 rounded-lg">
                    {" "}
                    {issuedround}{" "}
                  </span>{" "}
                </li>
                <li>
                  <span className="font-bold p-2  text-white bg-red-500 rounded-lg">
                    {" "}
                    {pendinground}{" "}
                  </span>{" "}
                </li>
                <li>
                  <span className="font-bold p-2  text-white bg-gray-500 rounded-lg">
                    {" "}
                    {issuedround + pendinground}{" "}
                  </span>{" "}
                </li>
              </li>
            </ul>
          </div>

          <div className="overflow-x-auto mt-5">
            <div className="flex items-center pb-4 ml-10 justify-start">
              <Formik
                initialValues={{
                  bookid: "",
                  designid: "",
                  article: "",
                }}
                onSubmit={async (values) => {
                  try {
                    const response = await axios.get(
                      `${API_URL}/api/production/filter`,
                      {
                        params: {
                          // page: 1,
                          // pageSize: pageSize,
                          bookid: values.bookid,
                          designid: values.designid,
                          article: values.article,
                        },
                      }
                    );

                    setData(response.data.data);

                    setIssuedround(response.data.issuedround);
                    setPendinground(response.data.pendinground);

                    settodayIssuedround(response.data.todayissuedround);
                    settodayPendinground(response.data.todaypendinground);

                    setmonthIssuedround(response.data.monthissuedround);
                    setmonthPendinground(response.data.monthpendinground);

                    // Calculate total pages based on the total items and page size
                    // const totalItems = response.data.totalItems; // Replace with the actual key in your API response
                    // const calculatedTotalPages = Math.ceil(totalItems / pageSize);
                    // setTotalPages(calculatedTotalPages);
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                <Form className="flex items-center gap-2">
                  <button
                    onClick={() => {
                      setOpenModal(true);
                    }}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded"
                  >
                    <IoMdQrScanner size={35} />
                  </button>
                  <Field
                    name="bookid"
                    placeholder="Book Id"
                    className="text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                  />
                  <Field
                    name="designid"
                    placeholder="Design Id"
                    className="text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                  />
                  <Field
                    name="article"
                    placeholder="Article Number"
                    className="text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                  />
                  <Button type="submit">Filter</Button>
                </Form>
              </Formik>
            </div>
            <div class="relative">
              {isDataLoading ? (
                <MyLoader />
              ) : (
                <>
                  <Table>
                    <Table.Head className="text-xs text-center text-gray-700 uppercase bg-gray-50 sticky top-0">
                      <Table.HeadCell>#</Table.HeadCell>
                      <Table.HeadCell>Machine</Table.HeadCell>
                      <Table.HeadCell>B#</Table.HeadCell>
                      <Table.HeadCell>D#</Table.HeadCell>
                      <Table.HeadCell>Fabric</Table.HeadCell>
                      <Table.HeadCell>Articles</Table.HeadCell>
                      <Table.HeadCell>Article Type</Table.HeadCell>
                      <Table.HeadCell>Created</Table.HeadCell>
                      <Table.HeadCell>Round Number</Table.HeadCell>
                      <Table.HeadCell>Status</Table.HeadCell>
                      <Table.HeadCell>Total Received</Table.HeadCell>
                      <Table.HeadCell>Total Cutting Issued</Table.HeadCell>
                    </Table.Head>
                    <Table.Body>
                      {paginatedData?.map((row, index) => (
                        <>
                          {row.createdat_array.map((createdAt, idx) => {
                            row.roundno_array.sort((a, b) => a - b);
                            return (
                              <Table.Row
                                key={idx}
                                className="border-gray-300 border-b text-center"
                              >
                                <Table.Cell>{idx + 1}</Table.Cell>
                                {idx === 0 ? (
                                  <Table.Cell>{row._id.machine}</Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                                {row.bookid.indexOf(row.bookid[idx]) === idx ? (
                                  <Table.Cell>{row.bookid[idx]}</Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                                {row.designid.indexOf(row.designid[idx]) ===
                                idx ? (
                                  <Table.Cell>{row.designid[idx]}</Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                                {row.data_Fabric.indexOf(
                                  row.data_Fabric[idx]
                                ) === idx ? (
                                  <Table.Cell>
                                    {row.data_Fabric[idx]}
                                  </Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                                {row.data_Article.indexOf(
                                  row.data_Article[idx]
                                ) === idx ? (
                                  <Table.Cell>
                                    {row.data_Article[idx]}
                                  </Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                                {row.data_Article_type.indexOf(
                                  row.data_Article_type[idx]
                                ) === idx ? (
                                  <Table.Cell>
                                    {row.data_Article_type[idx]}
                                  </Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                                <Table.Cell>
                                  {formatDateTime(
                                    createdAt,
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                </Table.Cell>
                                <Table.Cell>
                                  {row.roundno_array[idx]}
                                </Table.Cell>
                                <Table.Cell>
                                  <div
                                    className={`w-20 px-3 py-1 ${
                                      row.status_array[idx] === "Active"
                                        ? "bg-green-500 text-white"
                                        : row.status_array[idx] === "Pending"
                                        ? "bg-red-500 text-white"
                                        : "bg-red-500 text-white"
                                    } rounded-xl`}
                                  >
                                    {row.status_array[idx] === "Pending"
                                      ? "In"
                                      : "Out"}
                                  </div>
                                </Table.Cell>
                                {idx === 0 ? (
                                  <Table.Cell>{row.count}</Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                                {row.data_issued_round.indexOf(
                                  row.data_issued_round[idx]
                                ) === idx ? (
                                  <Table.Cell>
                                    {row.data_issued_round[idx]}
                                  </Table.Cell>
                                ) : (
                                  <Table.Cell></Table.Cell>
                                )}
                              </Table.Row>
                            );
                          })}
                        </>
                      ))}
                      {paginatedData?.length === 0 && (
                        <Table.Row>
                          <Table.Cell colSpan={12}>No data found</Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>

                  {/* Pagination Component */}
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
      {selectedTab === itemsForButtonGroup[1]?.id && (
        <ProductionIndex isHideHeading={true} />
      )}
    </div>
  );
}

export default ReceivingDepartment;

// import React, { useEffect, useState } from 'react'
// import SubHeading from '../../components/SubHeading'
// import Card from '../../components/Card'
// import { Tabs,TextInput,Button,Table,Dropdown, Pagination, Modal } from 'flowbite-react';
// import SearchInput from '../../components/SearchInput'
// import { IoMdQrScanner } from "react-icons/io";

// import axios from 'axios';
// import { PaginationTheme } from '../../theme/PaginationTheme';
// import { ModalTheme } from '../../theme/ModalTheme';
// import useScanDetection from 'use-scan-detection-react18';
// import { toast } from 'react-toastify';
// import { Field, Form, Formik } from 'formik';
// import { Article } from '@mui/icons-material';
// import formatDateTime from '../../helpers/Datetime';
// import MyLoader from '../../components/Loader/MyLoader';

// const API_URL = process.env.REACT_APP_API_URL;

// function ReceivingDepartment() {

//     // const [currentPage, setCurrentPage] = useState(1);
//     // const [pageSize, setPageSize] = useState(100);
//     // const [totalPages, setTotalPages] = useState(1);
//     const [data, setData] = useState([]);
//     const [openModal, setOpenModal] = useState(false);
//     const [isDataLoading, setIsDataLoading] = useState(false);

//     const [issuedround, setIssuedround] = useState(0);
//     const [pendinground, setPendinground] = useState(0);

//     const [todayissuedround, settodayIssuedround] = useState(0);
//     const [todaypendinground, settodayPendinground] = useState(0);

//     const [monthissuedround, setmonthIssuedround] = useState(0);
//     const [monthpendinground, setmonthPendinground] = useState(0);

//     // const onPageChange = (page) => {
//     //   setCurrentPage(page);
//     // };

// const getRecords = async () => {
//     try {
//       setIsDataLoading(true);
//       const response = await axios.get(`${API_URL}/api/production/filter`, {
//         params: {
//           // page: currentPage,
//           // pageSize: pageSize,
//         },
//       });

//       setData(response.data.data);

//       setIssuedround(response.data.issuedround);
//       setPendinground(response.data.pendinground);

//       settodayIssuedround(response.data.todayissuedround);
//       settodayPendinground(response.data.todaypendinground);

//       setmonthIssuedround(response.data.monthissuedround);
//       setmonthPendinground(response.data.monthpendinground);

//       setIsDataLoading(false);
//       // Calculate total pages based on the total items and page size
//       // const totalItems = response.data.totalItems;
//       // const calculatedTotalPages = Math.ceil(totalItems / pageSize);
//       // setTotalPages(calculatedTotalPages);
//     } catch (error) {
//       console.error(error);
//       toast.error(error.message || "Something went wrong");
//       setIsDataLoading(false);
//     }
//   };

//   useEffect(() => {
//     getRecords();
//   }, []);

//   const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

//   const d = new Date();
//   let monthname = month[d.getMonth()];

// useScanDetection({
//   onComplete: async (barcode) =>  {
//     if(openModal){
//    const Code = barcode.replace(/Shift/g, '');
//    //splite Code by ,
//    const data = Code.split(',');
//    const bookid = data[0];
//    const designid = data[1];
//    const id = data[2];
//    const issueno = data[3];

//    await  axios.post(`${API_URL}/api/production/issue_round`, {bookid,designid,id,issueno})
//    .then((response) => {

//     setOpenModal(true)
//    toast.success("Issued Successfully");
//    getRecords()
//    })
//    .catch((error) => {
//     console.log(error)
//       toast.error(error.response.data.error)
//     });
//   }
//   else{toast.error("Scanner not activated")}
//   },
// });

//   return (
//     <div>
//     <SubHeading title={'Receiving Department'} />

//       <Modal theme={ModalTheme} show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
//         <Modal.Header className=''  />
//         <Modal.Body className=''>
//         <p className='text-2xl text-center font-bold mb-5'>SCAN BARCODE</p>
//         <div className='flex justify-center'>
//           <img src='/gif/scanner.gif' height={300} width={300} />
//         </div>
//         </Modal.Body>
//       </Modal>

//       <div className='flex justify-end  mb-2 text-lg mr-10 '>
//       <ul className='text-left flex gap-5 justify-between bg-white rounded-lg p-5 w-[20%]'>
//         <li className='text-nowrap' >{monthname} :  </li>
//         <li className='flex items-center justify-evenly w-[100%]'  >
//         <li><span  className='font-bold p-2 text-white bg-green-500 rounded-lg'> {monthissuedround} </span> </li>
//         <li><span  className='font-bold p-2  text-white bg-red-500 rounded-lg'> {monthpendinground} </span> </li>
//         <li><span  className='font-bold p-2  text-white bg-gray-500 rounded-lg'> {monthissuedround+monthpendinground} </span> </li>
//         </li>
//       </ul>

//   </div>

//   <div className='flex justify-end  mb-2 text-lg mr-10 '>
//       <ul className='text-left flex gap-5 justify-between bg-white rounded-lg p-5  w-[20%]'>
//         <li className='text-nowrap' >Today :  </li>
//         <li className='flex items-center justify-evenly w-[100%]' >
//         <li><span  className='font-bold p-2 text-white bg-green-500 rounded-lg'> {todayissuedround} </span> </li>
//         <li><span  className='font-bold p-2  text-white bg-red-500 rounded-lg'> {todaypendinground} </span> </li>
//         <li><span  className='font-bold p-2  text-white bg-gray-500 rounded-lg'> {todayissuedround+todaypendinground} </span> </li>
//         </li>
//       </ul>

//   </div>

//   <div className='flex justify-end  mb-5 text-lg mr-10 '>
//       <ul className='text-left flex justify-between gap-5 bg-white rounded-lg p-5 w-[20%] '>
//         <li className='text-nowrap' >Total : </li>
//         <li className='flex items-center justify-evenly w-[100%]' >
//         <li><span  className='font-bold p-2 text-white bg-green-500 rounded-lg'> {issuedround} </span> </li>
//         <li><span  className='font-bold p-2  text-white bg-red-500 rounded-lg'> {pendinground} </span> </li>
//         <li><span  className='font-bold p-2  text-white bg-gray-500 rounded-lg'> {issuedround+pendinground} </span> </li>
//         </li>
//       </ul>

//   </div>

//     <div className="overflow-x-auto mt-5">

// <div className='flex items-center pb-4 ml-10 justify-start'>

//     <Formik  initialValues={{
//         bookid : '', designid : '', article : ''
//     }}
//     onSubmit={ async(values) => {
//         try {
//             const response = await axios.get(`${API_URL}/api/production/filter`, {
//               params: {
//                 // page: 1,
//                 // pageSize: pageSize,
//                 bookid : values.bookid,
//                 designid : values.designid,
//                 article : values.article
//               },
//             });

//             setData(response.data.data);

//       setIssuedround(response.data.issuedround);
//       setPendinground(response.data.pendinground);

//       settodayIssuedround(response.data.todayissuedround);
//       settodayPendinground(response.data.todaypendinground);

//       setmonthIssuedround(response.data.monthissuedround);
//       setmonthPendinground(response.data.monthpendinground);

//             // Calculate total pages based on the total items and page size
//             // const totalItems = response.data.totalItems; // Replace with the actual key in your API response
//             // const calculatedTotalPages = Math.ceil(totalItems / pageSize);
//             // setTotalPages(calculatedTotalPages);
//           } catch (error) {
//             console.error(error);
//           }
//     }}
//     >
//         <Form className='flex items-center gap-2'>
//         <button
//               onClick={() => {
//                 setOpenModal(true);
//               }}
//               className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded"
//             >
//               <IoMdQrScanner   size={35} />
//             </button>
//             <Field name="bookid"  placeholder="Book Id"  className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
//             <Field name="designid"  placeholder="Design Id"  className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
//             <Field name="article"  placeholder="Article Number"   className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
//             <Button type="submit">Filter</Button>
//         </Form>
//     </Formik>

// </div>

//   {/* <div className='px-10 relative overflow-x-auto    h-screen'>

//             <Table className=' dark:text-black'>

//       {data.map((row, index) => (
//         <>
//             <Table.Head className='sticky top-0'>

// <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Machine</Table.HeadCell>
// <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Book Number</Table.HeadCell>

//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Design Number</Table.HeadCell>

//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Fabric</Table.HeadCell>

//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Article</Table.HeadCell>

//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Dates</Table.HeadCell>
//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Round Numbers</Table.HeadCell>

//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Total Received</Table.HeadCell>

//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Total Cutting Issued</Table.HeadCell>
// </Table.Head>
// <Table.Body className="divide-y">
//         <Table.Row  className="bg-white dark:border-gray-200 dark:bg-gray-300">
//           <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row._id.machine}</Table.Cell>
//           <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row._id.bookid}</Table.Cell>
//           <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row._id.designid}</Table.Cell>
//           <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row._id.data_Fabric  }</Table.Cell>
//           <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row._id.data_Article + " - " + row._id.data_Article_type }</Table.Cell>
//           <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black"> <ul className="list-disc">
//           {row.createdat_array.map((createdAt, index) => (
//           <li className='mb-[17px]' key={index}>
//             {formatDateTime(createdAt, 'DD/MM/YYYY hh:mm A')}
//           </li>
//         ))}
//       </ul></Table.Cell>
//       <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black"> <ul className="list-disc">
//           {row.roundno_array.map((roundno, index) => (
//           <li key={index} className='flex gap-2 mb-2 items-center'>
//             {roundno}  <div    className={`px-3 py-1 ${row.status_array[index] === "Active" ? "bg-green-500 text-white" : row.status_array[index]  === " Pending" ? "bg-yellow-500 text-black" :  "bg-red-500  text-white"}  rounded-xl`}>{row.status_array[index]  == "Pending" ? "In" : "Out"}</div>

//           </li>
//         ))}
//       </ul></Table.Cell>
//       <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.count}</Table.Cell>

//       <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row._id.data_issued_round}</Table.Cell>

//         </Table.Row>

//         </Table.Body>

//         </>
//        ))}

//        {data.length === 0 && (
//         <>
//                     <Table.Head className='sticky top-0'>

// <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Machine</Table.HeadCell>
// <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Book Number</Table.HeadCell>

//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Design Number</Table.HeadCell>

//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Fabric</Table.HeadCell>

//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Article</Table.HeadCell>

//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Dates</Table.HeadCell>
//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Round Numbers</Table.HeadCell>

//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Total Received</Table.HeadCell>

//   <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Total Cutting Issued</Table.HeadCell>
// </Table.Head>
// <Table.Body className="divide-y">

//         <Table.Row>
//           <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black" colSpan={7}>
//             No data found
//           </Table.Cell>
//         </Table.Row>
//         </Table.Body>

//         </>
//       )}

//   </Table>
//   </div> */}

// <div class="relative">
//         <table id='recievingDep' class="w-full text-sm text-left rtl:text-right text-gray-500 ">

//       {isDataLoading ? <MyLoader /> : data?.map((row, index) => (
//         <>
//           <thead class="text-xs text-center  text-gray-700 uppercase bg-gray-50 sticky top-0">
//           <tr>
//           <th scope="col" class="px-6 py-3">
//                   #
//               </th>
//               <th scope="col" class="px-6 py-3">
//                   Machine
//               </th>
//               <th scope="col" class="px-6 py-3">
//                   B#
//               </th>
//               <th scope="col" class="px-6 py-3">
//                   D#
//               </th>

//               <th scope="col" class="px-6 py-3">
//                   Fabric
//               </th>
//               <th scope="col" class="px-6 py-3">
//                   Articles
//               </th>
//               <th scope="col" class="px-6 py-3">
//                   Article Type
//               </th>
//               <th scope="col" class="px-6 py-3">
//               Created
//               </th>
//               <th scope="col" class="px-6 py-3">
//               Round Number
//               </th>
//               <th scope="col" class="px-6 py-3">
//               Status
//               </th>
//               <th scope="col" class="px-6 py-3">
//               Total Received
//               </th>

//               <th scope="col" class="px-6 py-3">
//               Total Cutting Issued
//               </th>

//           </tr>
//           </thead>
//           <tbody >
//         {
//           row.createdat_array.map((createdAt, index) =>
//           {
//             row.roundno_array.sort((a, b) => a - b);
//             return(
//         <tr  className=" border-gray-300 border-b text-center   ">
//           {/* if machine is same only show one time */}
//           <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{index+1}</td>

//             {index === 0 ? (
//               <td className=" whitespace-nowrap font-medium text-gray-900 dark:text-black">{row._id.machine}</td>
//             ) :  <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}

//         {/* if bookid same only show one time */}
//           {row.bookid.indexOf(row.bookid[index]) === index  ? (
//                  <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.bookid[index]} </td>
//           ) : <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}

//      {row.designid.indexOf(row.designid[index]) === index  ? (
//           <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.designid[index]} </td>
//         ) : <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}

// {row.data_Fabric.indexOf(row.data_Fabric[index]) === index  ? (
//           <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.data_Fabric[index]}</td>
//         ) : <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}

// {row.data_Article.indexOf(row.data_Article[index]) === index  ? (
//           <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"> {row.data_Article[index] } </td>
//         ) : <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}

// {row.data_Article_type.indexOf(row.data_Article_type[index]) === index  ? (
//     <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"> {row.data_Article_type[index]} </td>
//   ) : <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}

//           <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{formatDateTime(createdAt, 'DD/MM/YYYY hh:mm A')} </td>

//           <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.roundno_array[index]}  </td>

//           <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black flex justify-center items-center "><div    className={`w-20 px-3 py-1 ${row.status_array[index] === "Active" ? "bg-green-500 text-white" : row.status_array[index]  === " Pending" ? "bg-yellow-500 text-black" :  "bg-red-500  text-white"}  rounded-xl`}>{row.status_array[index]  == "Pending" ? "In" : "Out"}</div>  </td>

//           {index === 0 ? (
//               <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.count}</td>
//             ) :  <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}

//      {row.data_issued_round.indexOf(row.data_issued_round[index]) === index  ? (
//       <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.data_issued_round[index]}</td>
//     ) : <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>}
//         </tr>
//            )})}
//            </tbody>
//         </>
//        ))}

//       {!isDataLoading && data?.length === 0 && (
//         <tbody>
//           <tr>
//             <td class="px-6 py-4" colSpan={7}>
//               No data found
//             </td>
//           </tr>
//         </tbody>
//       )}

//         </table>
//     </div>

//   {/* {data.length > 0 && (
//           <Pagination
//            theme={PaginationTheme}
//             currentPage={currentPage}
//             totalPages={totalPages}
//             onPageChange={onPageChange}
//           />
//         )} */}
// </div>
// </div>

//   )
// }

// export default ReceivingDepartment
