import React, { useEffect, useState } from 'react'
import SubHeading from '../../components/SubHeading'
import Card from '../../components/Card'
import { Tabs, TextInput, Button, Table, Dropdown, Pagination, Modal } from 'flowbite-react';
import SearchInput from '../../components/SearchInput'
import { IoIosQrScanner, IoMdQrScanner } from "react-icons/io";
import { MdPendingActions } from "react-icons/md";

import axios from 'axios';
import { PaginationTheme } from '../../theme/PaginationTheme';
import { ModalTheme } from '../../theme/ModalTheme';
import useScanDetection from 'use-scan-detection-react18';
import { toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import formatDateTime from '../../helpers/Datetime';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import { FaRegFilePdf } from 'react-icons/fa';
import MyLoader from '../../components/Loader/MyLoader';

const API_URL = process.env.REACT_APP_API_URL;

function OutScanner() {
    useScanDetection({
        onComplete: async (barcode) => {
                const Code = barcode.replace(/Shift/g, '');
                //splite Code by ,
                const data = Code.split(',');
                const bookid = data[0];
                const designid = data[1];
                const id = data[2];
                const issueno = data[3];

                await axios.post(`${API_URL}/api/cropping/dispatch`, { bookid, designid, id, issueno })
                    .then((response) => {
                        toast.success("Dispatch Successfully");
                    })
                    .catch((error) => {
                        toast.error(error.response.data.error,)                      
                    });          
        },
    });


    return (
        <div>
            <SubHeading title={'Cropping Department Out Scanner'} />

                    <p className='text-2xl text-center font-bold mb-5'>SCAN BARCODE</p>
                    <p className='text-md text-center font-semibold mb-5'>Dispatch</p>
                    <div className='flex justify-center'>

                        <img src='/gif/scanner.gif' height={300} width={300} />
                    </div>
                

        </div>

    )
}

export default OutScanner


