import axios from 'axios';
import { PaginationTheme } from '../../theme/PaginationTheme';
import { ModalTheme } from '../../theme/ModalTheme';
import useScanDetection from 'use-scan-detection-react18';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { Article } from '@mui/icons-material';
import formatDateTime from '../../helpers/Datetime';
import React, { useEffect, useState } from 'react'
import SubHeading from '../../components/SubHeading'
import Card from '../../components/Card'
import { Tabs,TextInput,Button,Table,Dropdown, Pagination, Modal } from 'flowbite-react';
import SearchInput from '../../components/SearchInput'
import { IoMdQrScanner } from "react-icons/io";
import { useParams } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { HiOutlineExclamationCircle } from 'react-icons/hi';



const API_URL = process.env.REACT_APP_API_URL;

function Damageround() {

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [data, setData] = useState([]);
    const [openModal , setOpenModal] = useState(false);
    const [bookdata , setBookdata] = useState({});
    //get id from param
    const { id,bookid,designid } = useParams();

  
  
    // Function to handle page change
    const onPageChange = (page) => {
      setCurrentPage(page);
    };


const getRecords = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/getdamageround`, {
        params: {
          page: currentPage,
          pageSize: pageSize,
          id : id,
          bookid : decodeURIComponent(bookid),
          designid :decodeURIComponent( designid)
        },
      });
      setData(response.data.data);
      const pdata = response.data["productionresult"][0]["data"];
      setBookdata(pdata.filter((d) => d.id === parseInt(id))[0]);
      // Calculate total pages based on the total items and page size
      const totalItems = response.data.totalItems; // Replace with the actual key in your API response
      const calculatedTotalPages = Math.ceil(totalItems / pageSize);
      setTotalPages(calculatedTotalPages);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    getRecords();
  }, [currentPage, pageSize]);



  return (
    <div>
    <SubHeading title={'Damage Round'} />


    <Modal show={openModal} theme={ModalTheme} size="md" onClose={() => setOpenModal(false)} popup>
        <Modal.Header className=''  />
        <Modal.Body className=''>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 " />
            <h3 className="mb-5 text-lg font-normal text-gray-500 ">
              Are you sure you want to remove this record?
            </h3>
           <div className="flex justify-center gap-4">
              <Button color="failure"  onClick={() =>  {}}>
                {"Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => setOpenModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>




    <div className="overflow-x-auto mt-5">




  <div className='px-10'>


            <Table className=' dark:text-black'>
 
    <Table.Head>

  
    <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Book Number</Table.HeadCell>
      
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Design Number</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Article</Table.HeadCell>
      <Table.HeadCell className='dark:bg-gray-400 dark:text-black'>Round Number</Table.HeadCell>

      {/* <Table.HeadCell className='dark:bg-gray-400 dark:text-black'></Table.HeadCell> */}
    </Table.Head>
    <Table.Body className="divide-y">
      {data.map((row, index) => (
        console.log(row),
        <Table.Row  className="bg-white dark:border-gray-200 dark:bg-gray-300">
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.bookid}</Table.Cell>
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.designid}</Table.Cell>
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{bookdata.Article} {bookdata["Article-type"]}</Table.Cell>
                   
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">{row.issueno}</Table.Cell>
          {/* <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
            <button className='p-1 bg-red-500 text-white rounded'
            onClick={() => setOpenModal(true)}
            >
            <MdDelete size={30} />
            </button>
          </Table.Cell> */}
        </Table.Row>
       ))} 

       {data.length === 0 && (
        <Table.Row>
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-black" colSpan={7}>
            No data found
          </Table.Cell>
        </Table.Row>
      )}
     
    
    </Table.Body>
  </Table>
  </div>

  {data.length > 0 && (
          <Pagination
           theme={PaginationTheme}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        )}
</div>







  </div>

  )
}

export default Damageround