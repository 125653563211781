import React, { useEffect, useState } from 'react'
import SubHeading from '../../components/SubHeading'
import Card from '../../components/Card'
import { Tabs,TextInput,Button,Table,Dropdown, Pagination, Modal } from 'flowbite-react';
import SearchInput from '../../components/SearchInput'
import { IoIosQrScanner, IoMdQrScanner } from "react-icons/io";
import axios from 'axios';
import { PaginationTheme } from '../../theme/PaginationTheme';
import { ModalTheme } from '../../theme/ModalTheme';
import useScanDetection from 'use-scan-detection-react18';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { Article } from '@mui/icons-material';
import formatDateTime from '../../helpers/Datetime';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { FaRegFilePdf } from 'react-icons/fa';
import MyLoader from '../../components/Loader/MyLoader';
import { Link } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;
function MarketStore() {

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [data, setData] = useState([]);
    const [openModal , setOpenModal] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
  
  
    const [issuedround, setIssuedround] = useState(0);
    const [pendinground, setPendinground] = useState(0);
  
  
    const [todayissuedround, settodayIssuedround] = useState(0);
    const [todaypendinground, settodayPendinground] = useState(0);
  
  
    const [monthissuedround, setmonthIssuedround] = useState(0);
    const [monthpendinground, setmonthPendinground] = useState(0);
  
  
    // Function to handle page change
    const onPageChange = (page) => {
      setCurrentPage(page);
    };
  
  
  const getRecords = async () => {
    try {
      setIsDataLoading(true);
      const response = await axios.get(`${API_URL}/api/marketstore`, {
        params: {
        
        },
      });
  
      setData(response.data.data);
  
      setIssuedround(response.data.issuedround);
      setPendinground(response.data.pendinground);
     
      settodayIssuedround(response.data.todayissuedround);
      settodayPendinground(response.data.todaypendinground);
     
      setmonthIssuedround(response.data.monthissuedround);
      setmonthPendinground(response.data.monthpendinground);
      setIsDataLoading(false);
  
      // Calculate total pages based on the total items and page size
      // const totalItems = response.data.totalItems; // Replace with the actual key in your API response
      // const calculatedTotalPages = Math.ceil(totalItems / pageSize);
      // setTotalPages(calculatedTotalPages);
    } catch (error) {
      setIsDataLoading(false);
      console.error(error);
    }
  };
  
  
  useEffect(() => {
    getRecords();
  }, []);
  
  
  
  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  
  const d = new Date();
  let monthname = month[d.getMonth()];
  

useScanDetection({
    onComplete: async (barcode) =>  {
      if(openModal){
     const Code = barcode.replace(/Shift/g, '');
     //splite Code by ,
     const data = Code.split(',');
     const bookid = data[0];
     const designid = data[1];
     const id = data[2];
     const issueno = data[3];
     
    
  
     await  axios.post(`${API_URL}/api/marketstore/assign`, {bookid,designid,id,issueno})
     .then((response) => {
  
    
      setOpenModal(true)
     toast.success("Issued Successfully");
     getRecords()
     })
     .catch((error) => {
      console.log(error)
        toast.error(error.response.data.error)
      });
    }
    // else{toast.error("Assign Scanner not activated")}
    },
  });


  const [openModelForDefective, setOpenModelForDefective] = useState(false);
  const [perround , setPerround] = useState('');

  
  useScanDetection({
    onComplete: async (barcode) =>  {
      if(openModelForDefective && perround !== ''){
     const Code = barcode.replace(/Shift/g, '');
     //splite Code by ,
     const data = Code.split(',');
     const bookid = data[0];
     const designid = data[1];
     const id = data[2];
     const issueno = data[3];
     
    
  
     await  axios.post(`${API_URL}/api/marketstore/defective`, {bookid,designid,id,issueno,perround})
     .then((response) => {
  
      console.log(response)
      setOpenModelForDefective(true)
     toast.success("Defective Issued Successfully");
     getRecords()
     })
     .catch((error) => {
      console.log(error)
        toast.error(error.response.data.error)
      });
    }
    // else{toast.error("Defective Scanner not activated or Enter Per Round")}
    },
  });


  
  const handleExportTablePDF = (tableIndex) => {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a2', // Adjust format if needed
    });
  
    doc.setFontSize(22);
  
    // Table specific title
    const title = `Market Store Data - Table ${tableIndex + 1}`;
    const pageWidth = doc.internal.pageSize.getWidth();
    const titleTextWidth = doc.getTextWidth(title);
    const titleXOffset = (pageWidth - titleTextWidth) / 2;
    doc.text(title, titleXOffset, 25);
  
    // Extract headers and data for the specific table
    const tableHeaders = [
      '#', 'B#', 'D#', 'Fabric', 'Id', 'Articles', 'Article Type', 
      'Created', 'Round Number', 'Status', 'Total Received', 
      'Total Cutting Issued', 'Per Round', 'Total', 'Check'
    ];
  
    const tableData = data[tableIndex].createdat_array.map((createdAt, idx) => [
      idx + 1,
      idx === 0 ? data[tableIndex]._id.bookid : '',
      data[tableIndex].designid.indexOf(data[tableIndex].designid[idx]) === idx ? data[tableIndex].designid[idx] : '',
      data[tableIndex].data_Fabric.indexOf(data[tableIndex].data_Fabric[idx]) === idx ? data[tableIndex].data_Fabric[idx] : '',
      data[tableIndex].data_id.indexOf(data[tableIndex].data_id[idx]) === idx ? data[tableIndex].data_id[idx] : '',
      data[tableIndex].data_Article.indexOf(data[tableIndex].data_Article[idx]) === idx ? data[tableIndex].data_Article[idx] : '',
      data[tableIndex].data_Article_type.indexOf(data[tableIndex].data_Article_type[idx]) === idx ? data[tableIndex].data_Article_type[idx] : '',
      formatDateTime(createdAt, 'DD/MM/YYYY hh:mm A'),
      data[tableIndex].roundno_array[idx],
      data[tableIndex].status_array[idx] === "Active" ? "Out" : data[tableIndex].status_array[idx] === "Pending" ? "In" : "Out",
      data[tableIndex].data_issued_round.indexOf(data[tableIndex].data_issued_round[idx]) === idx ? data[tableIndex].data_issued_round[idx] : '',
      idx === 0 ? data[tableIndex].count : '',
      data[tableIndex].data_PerSuit.indexOf(data[tableIndex].data_PerSuit[idx]) === idx ? data[tableIndex].data_PerSuit[idx] : '',
      data[tableIndex].data_PerSuit.indexOf(data[tableIndex].data_PerSuit[idx]) === idx ? data[tableIndex].roundno_array.length * data[tableIndex].data_PerSuit[idx] : '',
      data[tableIndex].check_array[idx],
    ]);
  
    // Generate PDF Table
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: 30,
      styles: {
        overflow: 'linebreak',
        columnWidth: 'wrap',
      },
      columnStyles: {
        2: { columnWidth: 'auto' },
      },
    });
  
    // Save the PDF with a unique name
    doc.save(`Market-Store-Data-Table-${tableIndex + 1}.pdf`);
  };
  
  const handleFilteration = async(values) => {
    try {
      const response = await axios.get(`${API_URL}/api/marketstore`, {
        params: {
          
          bookid : values.bookid,
          designid : values.designid,
          article : values.article
        },
      });

      setData(response.data.data);
        
      setIssuedround(response.data.issuedround);
      setPendinground(response.data.pendinground);
    
      settodayIssuedround(response.data.todayissuedround);
      settodayPendinground(response.data.todaypendinground);
    
      setmonthIssuedround(response.data.monthissuedround);
      setmonthPendinground(response.data.monthpendinground);

      // Calculate total pages based on the total items and page size
      // const totalItems = response.data.totalItems; // Replace with the actual key in your API response
      // const calculatedTotalPages = Math.ceil(totalItems / pageSize);
      // setTotalPages(calculatedTotalPages);
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Something went wrong");
    }
  }

  const itemsPerPage = 2; // Number of rows per page

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = data?.slice(startIndex, endIndex) || [];
  
  return (
    <div>
    <SubHeading title={'Market Store'} />
    <div className='flex justify-center' >
      <Link to={`/market/defectivescanner`}>
<Button className='p-2 mt-2 mb-2 bg-blue-500 hover:bg-blue-700 text-white rounded  h-10'><IoIosQrScanner size={25} />&nbsp;Defective Scanner</Button>
</Link>
      <Link to={`/market/scanner`}  >
<Button className='p-2 mt-2 mb-2 bg-blue-500 ml-2 hover:bg-blue-700 text-white rounded  h-10'><IoIosQrScanner size={25} />&nbsp;Scanner</Button>
</Link>
      </div>
  
      <Modal theme={ModalTheme} show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
        <Modal.Header className=''  />
        <Modal.Body className=''>
        <p className='text-2xl text-center font-bold mb-5'>SCAN BARCODE</p>
        <div className='flex justify-center'>
          <img src='/gif/scanner.gif' height={300} width={300} />
        </div> 
        </Modal.Body>
      </Modal>
  
  
      <Modal theme={ModalTheme} show={openModelForDefective} size="md" onClose={() => setOpenModelForDefective(false)} popup>
        <Modal.Header className=''  />
        <Modal.Body className=''>
        <p className='text-2xl text-center font-bold mb-5'>SCAN DEFECTIVE BARCODE</p>
        <div className='flex justify-center'>
          <img src='/gif/scanner.gif' height={300} width={300} />
        </div> 
        <Formik initialValues={{perround}} onSubmit={(values) => {setPerround(values.perround); }}>
        {({ values, handleChange, handleSubmit, isSubmitting , setFieldValue }) => (
        <Form className='flex justify-center gap-2'>
        <Field type="number" disabled={perround!== ''} step={'any'} name="perround"  className="w-full border border-gray-300 px-4 py-2 rounded-lg mt-5" placeholder="Enter Defective Barcode Per Round" />
        <button type="submit"  className={`bg-red-500 text-white px-4 py-2 rounded-lg mt-5 disabled:${perround === ''} ${perround !== '' && 'hidden'} `}>Save</button>
        <button type="button" onClick={() => {setPerround('');setFieldValue('perround', '')}}  className={`bg-red-500 text-white px-4 py-2 rounded-lg mt-5  ${perround === '' && 'hidden'}` }>Reset</button>
        </Form>
        )}
        </Formik>
        </Modal.Body>
      </Modal>
  
  
      <div className='flex justify-end  mb-2 text-lg mr-10 '>
      <ul className='text-left flex gap-5 bg-white rounded-lg p-5'>
        <li>{monthname} :  </li>
        <li><span  className='font-bold p-2 text-white bg-green-500 rounded-lg'> {monthissuedround} </span> </li>
        <li><span  className='font-bold p-2  text-white bg-red-500 rounded-lg'> {monthpendinground} </span> </li>
        <li><span  className='font-bold p-2  text-white bg-gray-500 rounded-lg'> {monthpendinground+monthissuedround} </span> </li>
      </ul> 
  </div>
  
  <div className='flex justify-end  mb-2 text-lg mr-10 '>
      <ul className='text-left flex gap-5 bg-white rounded-lg p-5'>
        <li>Today :  </li>
        <li><span  className='font-bold p-2 text-white bg-green-500 rounded-lg'> {todayissuedround} </span> </li>
        <li><span  className='font-bold p-2  text-white bg-red-500 rounded-lg'> {todaypendinground} </span> </li>
        <li><span  className='font-bold p-2  text-white bg-gray-500 rounded-lg'> {todaypendinground+todayissuedround} </span> </li>
      </ul>
      
  </div>
  
  
  <div className='flex justify-end  mb-5 text-lg mr-10 '>
      <ul className='text-left flex gap-5 bg-white rounded-lg p-5'>
        <li>Total : </li>
        <li><span  className='font-bold p-2 text-white bg-green-500 rounded-lg'> {issuedround} </span> </li>
        <li><span  className='font-bold p-2  text-white bg-red-500 rounded-lg'> {pendinground} </span> </li>
        <li><span  className='font-bold p-2  text-white bg-gray-500 rounded-lg'> {pendinground+issuedround} </span> </li>
      </ul>
      
  </div>
  
  
  
    <div className="overflow-x-auto mt-5">
  
  
  <div className='flex items-center pb-4 ml-10 justify-start'>
  
    <Formik  initialValues={{
        bookid : '', designid : '', article : ''
      }}
      onSubmit={handleFilteration}
    >
        <Form className='flex items-center gap-2'>
        <button
              onClick={() => {
                setOpenModelForDefective(true);
              }}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 rounded"
            >     
              <IoMdQrScanner   size={35} />
            </button>

        <button
              onClick={() => {
                setOpenModal(true);
              }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded"
            >     
              <IoMdQrScanner   size={35} />
            </button>
            <Field name="bookid"  placeholder="Book Id"  className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
            <Field name="designid"  placeholder="Design Id"  className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
            <Field name="article"  placeholder="Article Number"   className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
            <Button type="submit">Filter</Button>
        </Form>
    </Formik>
    
  </div>
  <div className='px-10'>
  {isDataLoading ? (
    <MyLoader className="mt-3" />
  ) : (
    <div>
      {paginatedData?.length > 0 ? (
        paginatedData.map((row, tableIndex) => (
          <div key={tableIndex}>
            <button
              className="flex justify-center mt-2 ml-2 mb-2 bg-red-500 hover:bg-red-700 text-white rounded w-[200px] h-10 items-center gap-1"
              onClick={() => handleExportTablePDF(tableIndex)}
            >
              <FaRegFilePdf /> Export Table {tableIndex + 1} PDF
            </button>
            <Table striped hoverable>
              <Table.Head>
                <Table.HeadCell>#</Table.HeadCell>
                <Table.HeadCell>B#</Table.HeadCell>
                <Table.HeadCell>D#</Table.HeadCell>
                <Table.HeadCell>Fabric</Table.HeadCell>
                <Table.HeadCell>Id</Table.HeadCell>
                <Table.HeadCell>Articles</Table.HeadCell>
                <Table.HeadCell>Article Type</Table.HeadCell>
                <Table.HeadCell>Created</Table.HeadCell>
                <Table.HeadCell>Round Number</Table.HeadCell>
                <Table.HeadCell>Status</Table.HeadCell>
                <Table.HeadCell>Total Received</Table.HeadCell>
                <Table.HeadCell>Total Cutting Issued</Table.HeadCell>
                <Table.HeadCell>Per Round</Table.HeadCell>
                <Table.HeadCell>Total</Table.HeadCell>
                <Table.HeadCell>Check</Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {row.createdat_array.map((createdAt, index) => {
                  // Sort round number array if sorting is necessary
                  row.roundno_array.sort((a, b) => a - b);

                  return (
                    <Table.Row
                      className={`${
                        row.defective[index] ? "bg-red-300" : ""
                      } text-center`}
                      key={index}
                    >
                      <Table.Cell>{index + 1}</Table.Cell>

                      {index === 0 ? (
                        <Table.Cell>{row._id.bookid}</Table.Cell>
                      ) : (
                        <Table.Cell></Table.Cell>
                      )}

                      {row.designid.indexOf(row.designid[index]) === index ? (
                        <Table.Cell>{row.designid[index]}</Table.Cell>
                      ) : (
                        <Table.Cell></Table.Cell>
                      )}

                      {row.data_Fabric.indexOf(row.data_Fabric[index]) === index ? (
                        <Table.Cell>{row.data_Fabric[index]}</Table.Cell>
                      ) : (
                        <Table.Cell></Table.Cell>
                      )}

                      {row.data_id.indexOf(row.data_id[index]) === index ? (
                        <Table.Cell>{row.data_id[index]}</Table.Cell>
                      ) : (
                        <Table.Cell></Table.Cell>
                      )}

                      {row.data_Article.indexOf(row.data_Article[index]) === index ? (
                        <Table.Cell>{row.data_Article[index]}</Table.Cell>
                      ) : (
                        <Table.Cell></Table.Cell>
                      )}

                      {row.data_Article_type.indexOf(row.data_Article_type[index]) === index ? (
                        <Table.Cell>{row.data_Article_type[index]}</Table.Cell>
                      ) : (
                        <Table.Cell></Table.Cell>
                      )}

                      <Table.Cell>{createdAt}</Table.Cell>
                      <Table.Cell>{row.roundno_array[index]}</Table.Cell>

                      <Table.Cell>
                        <div
                          className={`w-16 px-3 py-1 ${
                            row.status_array[index] === "Active"
                              ? "bg-green-500 text-white"
                              : row.status_array[index] === "Pending"
                              ? "bg-yellow-500 text-black"
                              : "bg-red-500 text-white"
                          } rounded-xl`}
                        >
                          {row.status_array[index] === "Pending" ? "In" : "Out"}
                        </div>
                      </Table.Cell>

                      {row.data_issued_round.indexOf(row.data_issued_round[index]) === index ? (
                        <Table.Cell>{row.data_issued_round[index]}</Table.Cell>
                      ) : (
                        <Table.Cell></Table.Cell>
                      )}

                      {index === 0 ? (
                        <Table.Cell>{row.count}</Table.Cell>
                      ) : (
                        <Table.Cell></Table.Cell>
                      )}

                      {row.data_PerSuit.indexOf(row.data_PerSuit[index]) === index ? (
                        <Table.Cell>{row.data_PerSuit[index]}</Table.Cell>
                      ) : (
                        <Table.Cell></Table.Cell>
                      )}

                      {row.data_PerSuit.indexOf(row.data_PerSuit[index]) === index ? (
                        <Table.Cell>
                          {row.defective[index] === false
                            ? row.roundno_array.length * row.data_PerSuit[index]
                            : 1 * row.data_PerSuit[index]}
                        </Table.Cell>
                      ) : (
                        <Table.Cell></Table.Cell>
                      )}

                      <Table.Cell>
                        <Formik
                          enableReinitialize
                          initialValues={{ status: row.check_array[index], id: row.id_array[index] }}
                          onSubmit={async (values) => {
                            await axios.post(`${API_URL}/api/marketstore/checked`, values);
                            getRecords();
                          }}
                        >
                          {({ submitForm, setFieldValue, values }) => (
                            <Form>
                              <Field
                                name="status"
                                type="checkbox"
                                checked={values.status}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  setFieldValue("status", checked);
                                  submitForm();
                                }}
                              />
                            </Form>
                          )}
                        </Formik>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        ))
      ) : (
        <p className="text-center">No Data Found</p>
      )}

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(data.length / itemsPerPage)}
        onPageChange={handlePageChange}
      />
    </div>
  )}
</div>
  
  {/* {data.length > 0 && (
          <Pagination
           theme={PaginationTheme}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        )} */}
  </div>
  </div>
  
  )
}

export default MarketStore