import React from 'react';
import { FaBook, FaEdit, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Card = ({ key, value, url, onClick,showDeleteIcon , showEditIcon , onEditClick }) => {
  const handleTrashClick = (event) => {
    // Prevent the default link behavior
    event.preventDefault();

    // Call the provided onClick function
    if (onClick) {
      onClick();
    }
  };

  const handleEditClick = (event) => {
    // Prevent the default link behavior
    event.preventDefault();

    // Call the provided onClick function
    if (onEditClick) {
      onEditClick();
    }
  };

  return (
    <Link key={key || 1} to={url || '/'} >
      <div className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-white dark:border-gray-200 dark:hover:bg-gray-300 w-full md:w-80">
        <div className="flex items-center justify-center">
          <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-black flex items-center">
            <FaBook size={20} />&nbsp;{value || 'B#'}
          </h5>

          {showEditIcon && (
            <FaEdit className='ml-5 cursor-pointer text-blue-500 hover:text-blue-700'  size={22} onClick={handleEditClick} />
       
          )}
          {showDeleteIcon && (
            <FaTrash className='ml-2 cursor-pointer text-red-500 hover:text-red-700'  size={20} onClick={handleTrashClick} />
       
          )}

         
        </div>
      </div>
    </Link>
  );
};

export default Card;
