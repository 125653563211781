

import React,{useEffect, useState} from 'react'
import SubHeading from '../../../components/SubHeading'
import Card from '../../../components/Card'
import { CiCirclePlus } from "react-icons/ci";
import { Button, Checkbox, Label, Modal, TextInput} from 'flowbite-react';
import axios from 'axios';
import {Route, Link, Routes, useParams} from 'react-router-dom';

import { GoDash } from "react-icons/go";
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ModalTheme } from '../../../theme/ModalTheme';
import { FaEdit } from 'react-icons/fa';



const API_URL = process.env.REACT_APP_API_URL;

const ProductionArticle = () => {

  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState('');
  const [productionData, setProductionData] = useState([]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [DesignNoForDelete,setDesignNoForDelete] = useState();

  const params = useParams();
  const [_idNo, set_idNo] = useState(0);
  const [DNo, setDNo] = useState("");


  function onCloseModal() {
    setOpenModal(false);
    setTitle('');
  }

  function onCloseModal() {
    setOpenModal(false);
    setTitle('');
  }



  const addProduction = async (title) => {
 
    try {
      const response = await axios.post(`${API_URL}/api/punching/production/article`, {
        data: {
          title: "D#"+title,
          key: decodeURIComponent(params.key)
        }
      });
      setOpenModal(false);   
        toast.success("Record Added Successfully", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      
     
      getProduction();
      
    } catch (error) {
      toast.error(error.response.data.error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };


  const getProduction = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/punching/production/article/`+encodeURIComponent(params.key));
       setProductionData(response.data.data[0][decodeURIComponent(params.key)]);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    setTitle("D#");

    getProduction();
  }, []);

  const deleteData = async (design) => {
    try {
    
      const response = await axios.delete(`${API_URL}/api/punching/production/article/${encodeURIComponent(params.key)}/${encodeURIComponent(design)}`);
      
      if (response.status === 200) {
        toast.success("Record Deleted Successfully", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } else {
        console.error("Deletion failed with status:", response.status);
      }

      setOpenDeleteModal(false);
      setDesignNoForDelete("");
      getProduction()

    } catch (error) {
      console.error(error);
    }
  };

  const [openEditModal, setOpenEditModal] = useState(false);
  return (
    <div>
    <SubHeading title={'Production '+decodeURIComponent(params.key)} />


    <div className="flex gap-2 justify-center gird grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
    <Button color="blue" className=' h-14 mb-2' onClick={() => setOpenModal(true)}><CiCirclePlus size={37}/></Button>

    </div>


    <Modal theme={ModalTheme} show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
        <Formik
            onSubmit={
              (values) => {
                const { title , shade } = values;
                if(shade !== ""){
                  const titleShade = title+"-"+shade;
                  addProduction(titleShade);
                  return
                }
                
               addProduction(values.title);
              }
            }
            initialValues={{
              title: "",shade:""
            }}
            validationSchema={
              Yup.object({
                title: Yup.string()
                .min(1, "Title must be at least 1 character")
                .max(5, "Title cannot be more than 5 characters")
                .required("Title is required"),
              shade: Yup.string()
                .min(1, "Shade must be at least 1 character")
                .max(4, "Shade cannot be more than 4 characters")
                .matches(/^[^,|-]*$/, "Shade cannot contain ',', 'or', or '-'")
              })
            }
          >
          <Form >

          
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900">Enter Design Number</h3>
            <div>
              
              <div className='flex justify-center'>
              <p className='text-2xl  mt-1 px-2'>{"D#"}</p>
              <Field
                id="title"
                type="text"
                placeholder="Design No."
                name='title'
                className="w-full h-10 input border border-gray-300 rounded-lg input-bordered focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
              />
              
              <GoDash size={37} />
              <Field
                type="text"
                placeholder="Shade"
                name='shade'
                className="w-full h-10 input border border-gray-300 rounded-lg input-bordered focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
              />
              </div>
              <ErrorMessage name="title" component="div" className="text-red-500" />
              <ErrorMessage name="shade" component="div" className="text-red-500" />
            </div>
           
           
            <div className="w-full">
              <Button type="submit">Add</Button>
            </div>

          </div>
          </Form>
          </Formik>
        </Modal.Body>
      </Modal>



      {/* delete prompt */}
      
<Modal theme={ModalTheme} show={openDeleteModal} size="md" onClose={() => setOpenDeleteModal(false)} popup>
        <Modal.Header className=''  />
        <Modal.Body className=''>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 " />
            <h3 className="mb-5 text-lg font-normal text-gray-500 ">
              Are you sure you want to remove this record enter {DNo} ?
            </h3>
            <input type="text" value={DesignNoForDelete} onChange={(e) => setDesignNoForDelete(e.target.value)} required className='mb-5 h-10 w-30 border border-gray-300 '/>
            <div className="flex justify-center gap-4">
              <Button color="failure" disabled={!DesignNoForDelete || DesignNoForDelete.trim() !== DNo} onClick={() =>  deleteData(DNo)}>
                {"Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => setOpenDeleteModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end */}


            {/* edit prompt */}
      
<Modal theme={ModalTheme} show={openEditModal} size="md" onClose={() => setOpenEditModal(false)} popup>
        <Modal.Header className=''  />
        <Modal.Body className=''>
        
        <Formik initialValues={{ title: DNo.split("-")[0].replace("D#", "") , shade: DNo.split("-")[1] }} validationSchema={Yup.object({ 
            title: Yup.string()
            .min(1, "Title must be at least 1 character")
            .max(5, "Title cannot be more than 5 characters")
            .required("Title is required"),
          shade: Yup.string()
            .min(1, "Shade must be at least 1 character")
            .max(4, "Shade cannot be more than 4 characters")
            .matches(/^[^,|-]*$/, "Shade cannot contain ',', 'or', or '-'")
         })}
        
        onSubmit={
          async(values) => {
            try {
              const { title , shade } = values;
                const titleShade = shade !== "" ? title+"-"+shade : title
              const response = await axios.put(`${API_URL}/api/punching/production/article/${encodeURIComponent(params.key)}/${encodeURIComponent(DNo)}/${encodeURIComponent(titleShade)}`);
              
              if (response.status === 200) {
                toast.success("Record Updated Successfully", {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
              } else {
                console.error("Update failed with status:", response.status);
              }
        
              setOpenEditModal(false);
              getProduction()
        
            } catch (error) {
              toast.error(error.response.data.error)
            }
          }}
        
        >
        <Form>
          <div className="text-center">
            <FaEdit className="mx-auto mb-4 h-14 w-14 text-gray-400 " />
            <h3 className="mb-5 text-lg font-normal text-gray-500 ">
              {/* update record instruction */}
              Are you sure you want to update this record ?
            </h3>
 
            <div className='flex justify-center'>
              <p className='text-2xl  mt-1 px-2'>{"D#"}</p>
              <Field
                id="title"
                type="text"
                placeholder="Design No."
                name='title'
                className="w-full h-10 input border border-gray-300 rounded-lg input-bordered focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
              />
              
              <GoDash size={37} />
              <Field
                type="text"
                placeholder="Shade"
                name='shade'
                className="w-full h-10 input border border-gray-300 rounded-lg input-bordered focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
              />
              </div>
              <ErrorMessage name="title" component="div" className="text-red-500" />
              <ErrorMessage name="shade" component="div" className="text-red-500" />
              
            
            <div className="flex mt-5 justify-center gap-4">
              <Button type='submit' color="failure">
                {"yes, update"}
              </Button>
              <Button type='button' color="gray" onClick={() => setOpenEditModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
          </Form>
          </Formik>
        </Modal.Body>
      </Modal>
      {/* end */}
    

    <div className="px-10 flex gap-20 justify-center flex-wrap items-center  ">
    

 {Object.keys(productionData).map((key, index) => (
  <div key={index} className="flex justify-center items-center">
        <Card showDeleteIcon={true} showEditIcon={true} onEditClick={()=>{setDNo(key); setOpenEditModal(true)}} onClick={()=>{setDNo(key); setOpenDeleteModal(true)}} key={index} value={key} url={`/Punching/Production/${ encodeURIComponent(params.key)}/${encodeURIComponent(key)}`}  />
    </div>
      ))}
  </div>
  </div>
  )
}

export default ProductionArticle