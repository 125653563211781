// MarketSelection
import React from 'react';
import LinkButton from '../../components/LinkButton';
import SubHeading from '../../components/SubHeading';


const MarketSelection = () => {
  return (
    <div>
      <SubHeading title={'Market Department'} />

      <div className="flex  flex-col md:flex-row gap-8 justify-center items-center ">
        <LinkButton
          url={'/Market/Store'}
          buttonName={'Store'}
        />

      <LinkButton
          url={'/Market/Dispatch'}
          buttonName={'Dispatch'}
        />


       
        </div>

   

      
    </div>
  );
};

export default MarketSelection;
