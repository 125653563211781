import React, { useEffect, useState } from 'react'
import SubHeading from '../../../components/SubHeading'
import axios from 'axios';
import useScanDetection from 'use-scan-detection-react18';
import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';

const API_URL = process.env.REACT_APP_API_URL;
function DefectiveScanner() {

  const [perround , setPerround] = useState('');

  
  useScanDetection({
    onComplete: async (barcode) =>  {
     const Code = barcode.replace(/Shift/g, '');
     //splite Code by ,
     const data = Code.split(',');
     const bookid = data[0];
     const designid = data[1];
     const id = data[2];
     const issueno = data[3];
    
     await  axios.post(`${API_URL}/api/marketstore/defective`, {bookid,designid,id,issueno,perround})
     .then((response) => {
      console.log(response)
     toast.success("Defective Issued Successfully");
     })
     .catch((error) => {
      console.log(error)
        toast.error(error.response.data.error)
      });
    },
  });




  
  return (
    <div>
    <SubHeading title={'Market Store'} />
    
        <p className='text-2xl text-center font-bold mb-5'>SCAN DEFECTIVE BARCODE</p>
        <div className='flex justify-center'>
          <img src='/gif/scanner.gif' height={300} width={300} />
        </div> 
        <Formik initialValues={{perround}} onSubmit={(values) => {setPerround(values.perround); }}>
        {({ values, handleChange, handleSubmit, isSubmitting , setFieldValue }) => (
        <Form className='flex justify-center gap-2'>
        <Field type="number" disabled={perround!== ''} step={'any'} name="perround"  className="w-full border border-gray-300 px-4 py-2 rounded-lg mt-5" placeholder="Enter Defective Barcode Per Round" />
        <button type="submit"  className={`bg-red-500 text-white px-4 py-2 rounded-lg mt-5 disabled:${perround === ''} ${perround !== '' && 'hidden'} `}>Save</button>
        <button type="button" onClick={() => {setPerround('');setFieldValue('perround', '')}}  className={`bg-red-500 text-white px-4 py-2 rounded-lg mt-5  ${perround === '' && 'hidden'}` }>Reset</button>
        </Form>
        )}
        </Formik>
     
  


  </div>
  
  )
}

export default DefectiveScanner