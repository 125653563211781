import React, { useEffect, useState } from 'react'
import SubHeading from '../../components/SubHeading'
import Card from '../../components/Card'
import { Accordion,Button,Dropdown,Modal,Pagination,Table,Tabs } from 'flowbite-react';

import { CustomFlowbiteTheme } from 'flowbite-react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import useScanDetection from 'use-scan-detection-react18';
import { Link, useLocation, useParams } from 'react-router-dom';
import { MdOutlineDriveFileMove } from 'react-icons/md';
import { ModalTheme } from '../../theme/ModalTheme';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { HiOutlineExclamationCircle } from "react-icons/hi";
import { FaTrash } from "react-icons/fa";
import { jwtDecode } from 'jwt-decode';
import { PaginationTheme } from '../../theme/PaginationTheme';

const API_URL = process.env.REACT_APP_API_URL;

function Productionid() {

  const user = jwtDecode(localStorage.getItem('token'));

    const params = useParams();
const location=useLocation();


    const machine = decodeURIComponent(params.machine);
    const department = decodeURIComponent(params.department);

    const [data, setData] = useState([ ]);
    const [openDeleteModal,setDeleteModal] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    const [totalstiches , setTotalStich] = useState(0);
    const [totalstichamount, setTotalStichAmount] = useState(0);

    const [pendingtotalstiches, setPendingTotalStich] = useState(0);
    const [totalpendingrounds, setTotalPendingRounds] = useState(0);
    

    
    const onPageChange = (page) => {
      setCurrentPage(page);
    };

    const getRec = async () => {
      try {
    
          const response = await axios.get(`${API_URL}/api/productionsetonmachine`, {
                    params: {
                      machine: machine, 
                      department: department,
                      page: currentPage,
                      pageSize: pageSize,
                    }      
                  });
   console.log(response.data.totalstichamount)
    const allProductionData = response.data["data"];
    setTotalStich(response.data.totalstiches)
    setTotalStichAmount(response.data.totalstichamount)
    setPendingTotalStich(response.data.pendingtotalStiches)
    setTotalPendingRounds(response.data.totalpendinground)
    setData(allProductionData);
    const totalItems = response.data.totalItems; 
    const calculatedTotalPages = Math.ceil(totalItems / pageSize);
    setTotalPages(calculatedTotalPages);
    loadTotalStich(allProductionData);
      } catch(error) {
        console.error(error);
      }
    };


    useEffect(() => {
      getRec();
    }, [currentPage, pageSize]);


    const [barCode , setBarCode] = useState('no record');

    // useScanDetection({
    //   onComplete: async (barcode) =>  {
    //    const Code = barcode.replace(/Shift/g, '');
    //    //splite Code by ,
    //    const data = Code.split(',');
    //    const bookid = data[0];
    //    const designid = data[1];
    //    const id = data[2];

    //    setBarCode(Code);
    //    try{
    //     const response  = await axios.post(`${API_URL}/api/production/issue`, {bookid,designid,id,machine,department});
  
        
    //       toast.success("Issued Successfully", {
    //         position: 'top-right',
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: 'light',
    //       });
    //       getRec();
  
    //    }catch(error){
    //     toast.error(error.response.data.error, {
    //       position: 'top-right',
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: 'light',
    //     });
    //    }
    //   },
    // });

    const [openModal, setOpenModal] = useState(false);

    const [machines, setMachines] = useState([]);

    const [id , setId] = useState('');

    const getMachines = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/department/machines`);
        console.log(response,"get response")
        setMachines(response.data.machine_data.filter((machine) => machine.department === department ));
      console.log(machines,"machine_data");
      

      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      getMachines();
   
    }, []);



    const loadTotalStich = (data) => {
      data.forEach((item, index) => {
        data[index].data["Stich Amount"] =  ( data[index].data["Stiches"]  / 1000 *  (data[index].data["Given Rate"] || 0 ) )
        
      
      });
      setData(data);
    };



    const handleRateChange = (e, index) => {
      const value = parseFloat(e.target.value);
      const newData = [...data];
      newData[index].data["Given Rate"] = value;
      newData[index].data["Stich Amount"] = newData[index].data["Stiches"] / 1000 * value; // Calculate Stich Amount based on Stiches and Given Rate
      
      setData(newData); 

    };



    const deleteItem = async () => {
      try {
        const response = await axios.delete(`${API_URL}/api/productionsetonmachine/${encodeURIComponent(id)}`);
        toast.success("Deleted Successfully",);
        getRec();
        setDeleteModal(false);
      } catch (error) {
        toast.error(error.response.data.error, );
      }
    };


    const save = async () => {
      try {
        setgivenrateloading(true);
        console.log(data);
        const response = await axios.post(`${API_URL}/api/productionsetonmachine/setgivenrate`, 
          {
            data: data
          },
        );
          toast.success("Saved Successfully",);
          getRec();
          setGivenRate(0);
          setgivenrateloading(false);

        }
      catch (error) {
        setgivenrateloading(false);
        toast.error(error.response.data.error, );
      }

    }

    const [isChecked, setIsChecked] = useState(false);
    const [givenRate , setGivenRate] = useState(0);


    const handleCheckAll = (e) => {
      const isChecked = e.target.checked;
      const updatedData = data.map((item) => ({
        ...item,
        checked: isChecked
      }));
      setData(updatedData);
      setIsChecked(isChecked); // Update isChecked state
    };
  
    // Function to handle individual checkbox click
    const handleSingleCheck = (index) => {
      const newData = [...data];
      newData[index].checked = !newData[index].checked;
      setData(newData);
      const anyChecked = newData.some(item => item.checked);
      setIsChecked(anyChecked); // Update isChecked state
    };

    const setGivenRatetoChecked =(value)=>{

      const newData = [...data];
      newData.forEach((item, index) => {
        if (item.checked) {
          item.data["Given Rate"] = value;
          handleRateChange({ target: { value } }, index);
        }
      });
      setData(newData);
      setGivenRate(value);
    }

console.log(data,"dateComes");

    const [givenrateloading , setgivenrateloading] = useState(false);

  return (
    <div>
   <Modal show={openDeleteModal} size="md" onClose={() => setDeleteModal(false)} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this product?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => deleteItem()}>
                {"Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => setDeleteModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
  <Modal show={openModal} size="md" theme={ModalTheme} popup={true} onClose={() => setOpenModal(false)}>
    <Modal.Header>Assign Machine</Modal.Header>
    <Modal.Body>
        <Formik initialValues={{machine: machine,id : id}} 
        enableReinitialize
        validationSchema={
          Yup.object().shape({
            machine: Yup.string().notOneOf([machine], 'Machine already assigned').required('Machine is required'),
          })}
        onSubmit={ async (values) => {
            try {
                const response = await axios.put(`${API_URL}/api/production/assignmachine`, values);
                console.log(response.data);
                setOpenModal(false);
                toast.success('Machine assigned successfully.');
                getRec();
            } catch (error) {
                console.error(error);
            }
        }}>
          <Form>
            <div className="mb-6">
              
              <Field as="select" name="machine" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">

                {machines.map((item) => (
                   
                  <option value={item.name+"-"+item.head}>{item.name+"-"+item.head}</option>
                ))}
              </Field>
              <ErrorMessage name="machine" component="div" className="text-red-500" />
              <button type='submit' className='mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>Send</button>
            </div>
            </Form>
        </Formik>
    </Modal.Body>
  </Modal>


    <SubHeading title={department + " - " + machine} enablebreadcrumb={false} >
    <div className="flex justify-center items-center ">
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li className="inline-flex items-center">
            <Link
              to={'/'}
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-black dark:hover:text-black"
            >
              <svg
                className="w-3 h-3 me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
              </svg>
              Home
            </Link>
          </li>

          <li >
                <div className="flex items-center">
                  <svg
                    className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                  </svg>
               
                    <Link
                      to={"/Production"}
                      // state={{programming:location?.state?.programming?"programming":"other",department:location?.state?.department}}
                      className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-blue"
                    >
                      Production
                    </Link>
                    <svg
                    className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                  </svg>
                  
                    <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Department</span>
                  
                  
                </div>
              </li>
        </ol>
      </nav>
    </div>
    </SubHeading>



<div className='w-full justify-between flex p-2'>
    <div className='bg-white shadow-lg p-5 rounded mt-5 text-md text-black font-bold text-left  '>
<p  className='mb-4'>Total Stich : <span  className='font-bold p-2  text-white bg-green-500 rounded-lg'>{totalstiches.toFixed(4)}</span></p>
<p>Total Stich Amount:  <span  className='font-bold p-2  text-white bg-green-500 rounded-lg'>{totalstichamount.toFixed(4)}</span></p>
</div>

<div className='bg-white shadow-lg p-5 mt-5 text-md rounded text-black font-bold text-left  '>
<p className='mb-4'>Total Pending Stich : <span  className='font-bold p-2  text-white bg-red-500 rounded-lg'> {pendingtotalstiches.toFixed(4)}</span></p>
<p>Total Pending Round:  <span  className='font-bold p-2  text-white bg-red-500 rounded-lg'>{totalpendingrounds}</span></p>

</div>

</div>

{(user?.user?.role === 'admin' || user?.role === 'admin')   &&
<div className="flex mb-2  gap-2">
      
      <button
      onClick={() => save()}
      className=' flex  bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>Save</button>
      

      <select
      onChange={(e) =>setPageSize(e.target.value) }
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2">

        <option value={10}>10</option>
        <option value={15}>15</option>
        <option value={20}>20</option>
        <option value={25}>25</option>
        <option value={30}>30</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
        </select>
     
     {isChecked &&  <input type='number' placeholder='Enter Given Rate' className=' border-gray-300 rounded focus:ring-0 p-2  ' 
     value={givenRate}
     onChange={(e) => {
      setGivenRatetoChecked(e.target.value)
     }}
     /> }
   
</div>    
}


<div className='flex items-center pb-4 justify-start mt-3'>

  <Formik  initialValues={{
      bookid : '', designid : '', articletype : ''
  }}
  onSubmit={ async(values) => {
      try {
     
        const response = await axios.get(`${API_URL}/api/productionsetonmachine`, {
          params: {
            machine: machine, 
            department: department,
            page: currentPage,
            pageSize: pageSize,
            bookid: values.bookid,
            designid: values.designid,
            articletype: values.articletype
          }      
        });
console.log(response.data.totalstichamount)
const allProductionData = response.data["data"];
setTotalStich(response.data.totalstiches)
setTotalStichAmount(response.data.totalstichamount)
setPendingTotalStich(response.data.pendingtotalStiches)
setTotalPendingRounds(response.data.totalpendinground)
setData(allProductionData);
const totalItems = response.data.totalItems; 
const calculatedTotalPages = Math.ceil(totalItems / pageSize);
setTotalPages(calculatedTotalPages);
loadTotalStich(allProductionData);

        } catch (error) {
          console.error(error);
        }
  }}
  >
      <Form className='flex items-center gap-2'>
          <Field name="bookid"  placeholder="Book Id"  className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
          <Field name="designid"  placeholder="Design Id"  className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
          <Field name="articletype"  placeholder="Article Type"   className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
          <Button type="submit">Filter</Button>
      </Form>
  </Formik>
  


</div>

<div class="relative overflow-x-auto">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 table_component">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
            <th scope="col" class="px-6 py-3">
            <input type='checkbox' className='border-gray-300 rounded focus:ring-0'
            onChange={(e) => handleCheckAll(e)}
            />
                    </th>
                <th scope="col" class="px-6 py-3">
                    Book Number
                </th>
                <th scope="col" class="px-6 py-3">
                    Design Number
                </th>
                <th scope="col" class="px-6 py-3">
                    Fabric
                </th>
                <th scope="col" class="px-6 py-3">
                    Article
                </th>
                <th scope="col" class="px-6 py-3">
                    Round No.
                </th>
                <th scope="col" class="px-6 py-3">
                    Stich
                </th>
                <th scope="col" class="px-6 py-3">
                  Given rate
                </th>
                <th scope="col" class="px-6 py-3">
                    Status
                </th>

                {/* <th scope="col" class="px-6 py-3">
                    Total Stich
                </th> */}
                <th scope="col" class="px-6 py-3">
                    Stich Amount
                </th>
                {/* {user.user.role === 'admin' && */}
                <th scope="col" class="px-6 py-3">
                    
                </th>
                {/* } */}
            </tr>
        </thead>
        <tbody>



        {!givenrateloading && data.map((item,index) => (
           
                   <tr key={index} class="bg-white border-b ">
                    
                     <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                     <input
                    type='checkbox'
                    className='border-gray-300 rounded focus:ring-0'
                    checked={item.checked || false}
                    onChange={() => handleSingleCheck(index)}
                  />
                   </th>
                   <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                       {item.bookid}
                   </th>
                   <td class="px-6 py-4">
                   {item.designid}
                   </td>
                   <td class="px-6 py-4">
                       {item.data.Fabric}
                   </td>
                   <td class="px-6 py-4">
                   {item.data.Article}      {item.data["Article-type"]}
                   </td>
                   <td class="px-6 py-4">
                   {item.roundno}
                   </td>
                   <td class="px-6 py-4">
                   {item.data.Stiches}
                   </td>
                   <td class="px-6 py-4">
                 {item.data["Given Rate"] || 0}
                   </td>
                   <td class="px-6 py-4">
                   <div    className={`px-3 py-1 ${item.status === "Active" ? "bg-green-500 text-white" :   "bg-red-500  text-white"}  rounded-xl`}>{item.status == "Pending" ? "In" : "Out"}</div> 
                   </td>
                   {/* <td class="px-6 py-4">
                   {item.data.Stiches * 1}
                   </td> */}
                   <td class="px-6 py-4">
                    <input type="number" step={"any"}
                    value={item.data["Stich Amount"].toFixed(4) || 0}
                    placeholder='Stich Amount' disabled className=" border-2 border-gray-300 rounded-md p-2"  />
                   </td>
                   <td class="px-6 py-4 flex items-center">
                 
                      <button  disabled={item.status === "Active"} className={` bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${item.status === "Active" ? "cursor-not-allowed opacity-50" : ""} ${item.status === "In" ? "cursor-not-allowed opacity-50" : ""}  `} onClick={() => { setId(item._id); setOpenModal(true)}}>
                      <MdOutlineDriveFileMove  size={30} />
                      </button>
                   {(user?.role === 'admin' || user?.user?.role === 'admin') &&
                    
                      <button className='bg-red-500 hover:bg-red-700 ml-3 text-white font-bold py-2 px-4 rounded' onClick={() => { setId(item._id); setDeleteModal(true)}}>
                      <FaTrash size={30}/>
                      </button>
                   }
                    </td>
               </tr>
          
            ))}

              {givenrateloading && (
              <div className="flex justify-center items-center">
                Loading... Applying given rate
              </div>
            )}
         
           
        </tbody>
    </table>
</div>


{data.length > 0 && (
          <Pagination
           theme={PaginationTheme}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        )}



  </div>

  )
}

export default Productionid






