import logo from "./logo.svg";
import { useEffect, useState } from "react";
import "./App.css";
import Index from "./pages/Home/Index";
import ProductionIndex from "./pages/ProductionDepartment/Index";
import Menu from "./pages/PunchingDepartment/Menu";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import Header from "./components/Header";
import Production from "./pages/PunchingDepartment/Production/Production";
import Sampling from "./pages/PunchingDepartment/Sampling/Sampling";
import SamplingArticle from "./pages/PunchingDepartment/Sampling/SamplingArticle";
import ProductionArticle from "./pages/PunchingDepartment/Production/ProductionArticle";
import Cutting from "./pages/CuttingDepartment/Cutting";
import CuttingArticle from "./pages/CuttingDepartment/CuttingArticle";
import SubNavbar from "./components/SubNavbar";
import NavbarComponent from "./components/NavbarComponent";
import CuttingArticleId from "./pages/CuttingDepartment/CuttingArticleId";
import Cropping from "./pages/CroppingDepartment/Cropping";
import Finishing from "./pages/FinishingDepartment/Finishing";
import { setTheme } from "flowbite-react/lib/esm/theme-store";
import ProductionArticleTable from "./pages/PunchingDepartment/Production/ProductionArticleTable";
import MarketReceivedTable from "./pages/MarketReceived/MarketReceivedTable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArticlesTypes from "./pages/Admin/ArticlesTypes/ArticlesTypes";
import Setting from "./pages/Admin/Setting";
import Colors from "./pages/Admin/Colors/Colors";
import ProductionQuantityTypes from "./pages/Admin/ProductionQuantityTypes/ProductionQuantityTypes";
import Fabrics from "./pages/Admin/Fabrics/Fabrics";
import PrsuitTypes from "./pages/Admin/PrsuitTypes/PrsuitTypes";
import MarketQuantityTypes from "./pages/Admin/MarketQuantityTypes/MarketQuantityTypes";
import ProductionTypes from "./pages/Admin/ProductionTypes/ProductionTypes";
import Departments from "./pages/Admin/Departments/Departments";
import Machines from "./pages/Admin/Machines/Machines";
import BarcodePrint from "./pages/CuttingDepartment/BarcodePrint";
import CuttingScanner from "./pages/CuttingDepartment/CuttingScanner";
import Login from "./pages/Auth/Login";
import AuthGuard from "./pages/Auth/AuthGuard";
import Users from "./pages/Admin/Users/Users";
import Roles from "./pages/Admin/Roles/Roles";
import PercumintDepartment from "./pages/PercumintDepartment/PercumintDepartment";
import Productionid from "./pages/ProductionDepartment/Productionid";
import Vendor from "./pages/Admin/Vendors/Vendor";
import ReceivingDepartment from "./pages/ReceivingDepartment/ReceivingDepartment";
import Damageround from "./pages/CuttingDepartment/Damageround";
import Issueround from "./pages/CuttingDepartment/Issueround";
import MarketSelection from "./pages/MarketDepartment/MarketSelection";
import MarketStore from "./pages/MarketDepartment/MarketStore";
import MarketDispatch from "./pages/MarketDepartment/MarketDispatch";
import CuttingReport from "./pages/CuttingDepartment/CuttingReport";
import ReceivingScanner from "./pages/ReceivingDepartment/ReceivingScanner";
import OutScanner from "./pages/CroppingDepartment/OutScanner";
import InScanner from "./pages/CroppingDepartment/InScanner";
import MarketScanner from "./pages/MarketDepartment/MarketScanner/MarketScanner";
import DefectiveScanner from "./pages/MarketDepartment/MarketScanner/DefectiveScanner";

function App() {
  useEffect(() => {
    // Force light mode
    document.body.style.backgroundColor = "#F3F4F6";
    document.body.style.color = "black";
    setTheme("light");
    // Add more styles as needed
  }, []); // Run this effect only once when the component mounts

  console.log(process.env.REACT_APP_API_URL, "==process.env.REACT_APP_API_URL");
  

  return (
    <div className="App">
      <ToastContainer />

      <Router>
        <NavbarComponent />

        <div className="">
          <section class="bg-gray-100 dark:bg-gray-100 h-screen">
            <div class=" mx-auto  text-center text-gray-900 dark:text-black lg:py-16 lg:px-2">
              <Routes>
                <Route
                  path="/"
                  exact
                  element={
                    <AuthGuard>
                      <Index />
                    </AuthGuard>
                  }
                />
                <Route path="/Cutting" element={<Cutting />} />
                <Route path="/Cutting/:key" element={<CuttingArticle />} />
              
                <Route
                  path="/Cutting/:key/:title"
                  element={<CuttingArticleId />}
                />

                <Route
                  path="/Cutting/:key/:title/cuttingreport/data/record"
                  element={<CuttingReport />}
                />
   

                  <Route path="/Cutting/damage/:bookid/:designid/:id" element={<Damageround />} />
                  <Route path="/Cutting/issue/:bookid/:designid/:id" element={<Issueround />} />
                <Route path="/Percumint" element={<PercumintDepartment />} />
                <Route path="/Punching" element={<Menu />} />
                <Route path="/Punching/Sampling" element={<Sampling />} />
                <Route path="/Punching/Production" element={<Production />} />
                <Route path="/Cropping" element={<Cropping />} />
                <Route path="/cropping/outscanner" element={<OutScanner />} />
                <Route path="/cropping/inscanner" element={<InScanner />} />



                <Route path="/Finishing" element={<Finishing />} />

                <Route path="/Production" exact  element={<ProductionIndex />} />
                <Route path="/production/:department/:machine" element={<Productionid />} />


                <Route
                  path="/Punching/Sampling/Article"
                  element={<SamplingArticle />}
                />
                <Route
                  path="/Punching/Production/:key"
                  element={<ProductionArticle />}
                />
                <Route
                  path="/Punching/Production/:key/:title"
                  element={<ProductionArticleTable />}
                />

                <Route
                  path="/MarketReceived"
                  element={<MarketReceivedTable />}
                />

<Route
                  path="/market/scanner"
                 element={<MarketScanner/>}
                />
                <Route
                  path="/market/defectivescanner"
                 element={<DefectiveScanner/>}
                />
                <Route
                  path="/Market"
                 element={<MarketSelection/>}
                />

                <Route
                  path="/Market/Store"
                 element={<MarketStore/>}
                />

                <Route
                  path="/Market/Dispatch"
                 element={<MarketDispatch/>}
                />

                {/* Receiving */}
                <Route
                  path="/Receiving"
                  element={<ReceivingDepartment />}
                />
                <Route
                  path="/receiving/scanner"
                  element={<ReceivingScanner />}
                />

                <Route
                  path="/Setting/ArticlesTypes"
                  element={<ArticlesTypes />}
                />
                <Route path="/Setting/Colors" element={<Colors />} />
                <Route
                  path="/Setting/ProductionQuantityTypes"
                  element={<ProductionQuantityTypes />}
                />
                <Route
                  path="/Setting/ProductionTypes"
                  element={<ProductionTypes />}
                />
                <Route
                  path="/Setting/MarketQuantityTypes"
                  element={<MarketQuantityTypes />}
                />
                <Route path="/Setting/Fabrics" element={<Fabrics />} />
                <Route path="/Setting/PrsuitTypes" element={<PrsuitTypes />} />

                <Route path="/Setting/Departments" element={<Departments />} />

                <Route path="/Setting/Machines" element={<Machines />} />

                <Route path="/Setting/Users" element={<Users />} />

                <Route path="/Setting/Roles" element={<Roles />} />

                <Route path="/Setting/Vendors" element={<Vendor />} />

                <Route
                  path="/BarcodePrint/:round/:data/:article"
                  element={<BarcodePrint />}
                />

                <Route path="/Setting" element={<Setting />} />

                <Route
                  path="/Cutting/:key/:title/scanner"
                  element={<CuttingScanner />}
                />

                <Route path="/login" element={<Login />} />
              </Routes>
            </div>
          </section>
        </div>
      </Router>
    </div>
  );
}

export default App;
