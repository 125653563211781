import React, { useEffect, useState } from "react";
import SubHeading from "../../components/SubHeading";
import Card from "../../components/Card";
import {
  Accordion,
  Button,
  Dropdown,
  Modal,
  Table,
  Tabs,
  Pagination,
} from "flowbite-react";

import { CustomFlowbiteTheme } from "flowbite-react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { ModalTheme } from "../../theme/ModalTheme";
import useScanDetection from "use-scan-detection-react18";
import { jwtDecode } from "jwt-decode";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
import { IoMdQrScanner } from "react-icons/io";
import { BiDetail } from "react-icons/bi";
import { PaginationTheme } from "../../theme/PaginationTheme";
import formatDateTime from "../../helpers/Datetime";
import MyLoader from "../../components/Loader/MyLoader";
const API_URL = process.env.REACT_APP_API_URL;

function Index({ isHideHeading = false }) {
  // const user = jwtDecode(localStorage?.getItem("token"));
  const [user, setUser] = useState(null);

  const [department, setDepartment] = useState([]);
  const [machines, setMachines] = useState([]);

  const [selectedValue, setSelectedValue] = useState();
  const [items, setItems] = useState([]);

  const [items2, setItems2] = useState([]);
  const [selecteddesignNumber, setselectedDesignNumbers] = useState();

  const [selectDepartment, setselectDepartment] = useState("");
  const [selectMachine, setselectMachine] = useState("");

  const [pendingtotalstiches, setPendingTotalStich] = useState(0);
  const [totalpendingrounds, setTotalPendingRounds] = useState(0);

  const loc = useLocation();
  const getDepartmentMachineDropDownRecords = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/department/machines`);
      console.log(response, "machiness");

      setDepartment(
        response.data.department_data.map((item) => ({
          title: item.name,
          value: item.name,
        }))
      );
      setMachines(
        response.data.machine_data.map((item) => ({
          title: item.name,
          value: item.name,
          head: item.head,
          department: item.department,
        }))
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [productionData, setProductionData] = useState([]);

  // const getProduction = async () => {
  //   try {

  //     const response = await axios.get(`${API_URL}/api/AllProduction`, {
  //       params: {
  //         bookid : selectedValue.value,
  //         designid : selecteddesignNumber,
  //       },
  //     });
  //     const allProductionData = response.data["data"];

  //     console.log(allProductionData,selectMachine);
  //     const filteredData = allProductionData.map(item => {
  //       const newData = item.data.filter(dataItem => {
  //         return (selectDepartment === "All" ? dataItem.Department !== selectDepartment : dataItem.Department === selectDepartment) &&
  //                (selectMachine === "All" ? dataItem.Machine !== selectMachine : dataItem.Machine === selectMachine);
  //     });
  //      return { ...item, data: newData };
  //     }).filter(item => item.data.length > 0);

  //     if(filteredData.length > 0){
  //       toast.success("Record Fetched Successfully", {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: 'light',
  //       });
  //     }
  //     else{
  //       toast.error("No Record Found", {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: 'light',
  //       });
  //     }

  //     setProductionData(filteredData);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    getDepartmentMachineDropDownRecords();
  }, []);

  console.log(user, "===cajsajs:useruser");

  const [machineDepartment, setMachineDepartment] = useState([]);
  const getMachines = async (val) => {
    setselectDepartment(val);

    setMachineDepartment(machines.filter((item) => item.department === val));
    console.log(machineDepartment, "machines");
  };

  const handleInputChange = (newValue) => {
    // No need to set the input value in the state
  };
  const handleChange = (newValue) => {
    setSelectedValue(newValue);
    fetchDesignNo(newValue.value);
  };

  const getOptionValue = (option) => option.value;
  const getOptionLabel = (option) => option.label;

  const fetchDesignNo = (bookNo) => {
    const designNumbersForBook = items.filter(
      (item) => Object.keys(item)[0] === bookNo
    );
    const designNumbersArray =
      designNumbersForBook.length > 0
        ? Object.values(designNumbersForBook[0])[0]
        : [];

    const data = [];

    Object.keys(designNumbersArray).forEach((key, index) => {
      data.push({
        [Object.keys(designNumbersArray)[index]]: {
          value: designNumbersArray[key],
          label: key,
        },
      });
    });

    setItems2(data); // Set the items for the table
  };

  const selectDesignNoValue = (designNo) => {
    setselectedDesignNumbers(designNo);
  };

  const [programming, setProgramming] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [barCode, setBarCode] = useState("no record");
  useScanDetection({
    onComplete: async (barcode) => {
      if (openModal) {
        const Code = barcode.replace(/Shift/g, "");
        //splite Code by ,
        const data = Code.split(",");
        const bookid = data[0];
        const designid = data[1];
        const id = data[2];
        const roundno = data[3];

        setBarCode(Code);
        try {
          const response = await axios.post(`${API_URL}/api/production/issue`, {
            bookid,
            designid,
            id,
            machine: dataModal.title + "-" + dataModal.head,
            department: selectDepartment,
            roundno,
          });

          getRec();
          setOpenModal(true);
          getRecformachine();
          toast.success("Issued Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } catch (error) {
          toast.error(error.response.data.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        toast.error("Scanner not activated");
      }
    },
  });

  const [summaryData, setSummaryData] = useState([]);
  const [summaryDataForMachine, setSummaryDataForMachine] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const [originalSummaryData, setOriginalSummaryData] = useState();
  const [filteredSummaryData, setFilteredSummaryData] = useState();

  const getRec = async () => {
    try {
      setIsSummaryLoading(true);
      const response = await axios.get(`${API_URL}/api/productionsummary`, {
        params: {
          department: selectDepartment,
          // page: currentPage,
          // pageSize: pageSize,
        },
      });

      const allProductionData = response.data["data"];

      setOriginalSummaryData(allProductionData);
      setSummaryData(allProductionData);
      setFilteredSummaryData(allProductionData);
      setPendingTotalStich(response.data.pendingtotalStiches);
      setTotalPendingRounds(response.data.totalpendinground);
      setIsSummaryLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Error in fetching Summary");
      setIsSummaryLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage) {
      const user = jwtDecode(localStorage?.getItem("token"));
      setUser(user);
    }
  }, []);

  useEffect(() => {
    console.log("selectDepartment", selectDepartment);

    selectDepartment !== "" && getRec();
  }, [selectDepartment]);

  // useEffect(() => {
  //   getRecformachine();
  // }, [selectDepartment]);

  const getRecformachine = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/productionsummary/machine`,
        {
          params: {
            department: selectDepartment,
          },
        }
      );
      const allProductionData = response.data["data"];

      setSummaryDataForMachine(allProductionData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRecformachine();
    fetchBooks();
  }, [selectDepartment]);

  useEffect(() => {
    if (isHideHeading) {
      setProcessing(true);
      setProgramming(false);
    }
  }, [isHideHeading]);

  const [books, setBooks] = useState([]);
  const fetchBooks = () => {
    return axios.get(`${API_URL}/api/punching/production`).then((result) => {
      const allProducts = result.data.data[0].production;

      setBooks(allProducts); // Set the items for the table
    });
  };

  const [data_, setData_] = useState([]);

  const getProductRecord = async (bookid, designid) => {
    try {
      const product_response = await axios.get(
        `${API_URL}/api/production/` +
          encodeURIComponent(bookid) +
          "/" +
          encodeURIComponent(designid)
      );
      setData_(product_response.data["data"][0]["data"]);
      console.log(product_response.data["data"][0]["data"]);
    } catch (error) {
      console.error(error);
    }
  };

  const MachineFilterChange = (val) => {
    // Filter the data based on the selected machine value
    let filteredData = originalSummaryData.filter((e) => e._id.machine === val);
    console.log(filteredData, val, originalSummaryData, "testtt");

    // If no filter value is selected, show the original full dataset
    if (val === "") {
      filteredData = originalSummaryData;
    }

    // Update the filtered data in the state
    setFilteredSummaryData(filteredData);
  };

  console.log(machineDepartment, "testMachines");

  return (
    <div>
      {!isHideHeading && <SubHeading title={"Production"} />}

      <Modal
        theme={ModalTheme}
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header className="" />
        <Modal.Body className="">
          <p className="text-2xl text-center font-bold mb-5">SCAN BARCODE</p>
          <p className="text-xl text-center font-bold">
            {selectDepartment + " - " + dataModal.title + "-" + dataModal.head}
          </p>
          <div className="flex justify-center">
            <img src="/gif/scanner.gif" height={300} width={300} />
          </div>
        </Modal.Body>
      </Modal>

      <div className="flex justify-center gap-2 ">
        <div className="bg-transparent p-3 rounded flex gap-4 ">
          {/* <AsyncSelect
  className=' text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 '
     required
        cacheOptions
        placeholder="Select B#"
        defaultOptions
        value={selectedValue}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        loadOptions={(inputValue) => fetchBookNo(inputValue)}
        onInputChange={handleInputChange}
        onChange={handleChange}
      />

<select required value={selecteddesignNumber} onChange={(e) => {selectDesignNoValue(e.target.value); }} className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 '>
    <option key="default" value="" className='text-black'>
      Select D#
    </option>
    {items2.map((element, index) => (
    
      <option className='text-black' key={`option_${index}`} value={Object.keys(element)[0]}>
        {Object.keys(element)[0]}
      </option>
    ))}
  </select>

<select onChange={(e) => setselectDepartment(e.target.value)} id="countries" class="text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 ">
  <option selected>Select Department</option>
  <option value="All">All Departments</option>
  {department.map((item) => (
    <option value={item.value}>{item.title}</option>
  ))}

</select>


<select onChange={(e) => setselectMachine(e.target.value)} id="countries" class="text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 ">
  <option selected>Select Machine</option>
  <option value="All">All Machines</option>
  {selectDepartment === "All" ? (
    machines.map((item) => (
      <option value={item.value+"-"+item.head}>{item.title}-{item.head}</option>
    ))
  ) : (

    machines.filter((item) => item.department === selectDepartment).map((item) => (
      <option value={item.value+"-"+item.head}>{item.title}-{item.head}</option>
    ))

  )}
  
</select>

<Button onClick={getProduction} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
  Filter
</Button> */}

          <select
            onChange={(e) => {
              setselectDepartment(e.target.value);
              getMachines(e.target.value);
            }}
            id="countries"
            class="text-lg bg-gray-50 border border-gray-300 text-gray-900 text-[18px]  h-[50px] font-medium rounded-lg focus:ring-blue-500 w-[500px] focus:border-blue-500 block p-2.5 "
            style={{
              border: 0,
              outline: "1px solid",
              borderRight: " 16px solid transparent",
            }}
          >
            <option value={""} selected disabled>
              Select Department
            </option>
            {/* <option value="All">All Departments</option> */}
            {(user?.role === "admin" ||
              user?.role === "receiving" ||
              user?.user?.role === "admin" ||
              user?.user?.role === "receiving") &&
              department?.map((item) => (
                <option
                  // selected={loc?.state?.department === item.title}
                  value={item.value}
                >
                  {item?.title}
                </option>
              ))}

            {(user?.role === "production" ||
              user?.user?.role === "production") &&
              department
                ?.filter(
                  (item) =>
                    item.value === user?.user?.department ||
                    item.value === user?.department
                )
                ?.map((item) => (
                  <option value={item.value}>{item.title}</option>
                ))}
          </select>
        </div>
      </div>

      {!isHideHeading && selectDepartment !== "" && (
        <div className="flex gap-2 justify-center w-full">
          <a
            href={`#`}
            onClick={() => {
              if (programming) {
                //
              } else {
                setProgramming(!programming);
                setProcessing(false);
              }
            }}
            class={`block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 border ${
              programming ? "border-gray-600" : "border-white"
            }`}
          >
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">
              Programming
            </h5>
          </a>
          <a
            href={`#`}
            onClick={() => {
              if (processing) {
              } else {
                setProcessing(!processing);
                setProgramming(false);
              }
            }}
            class={`block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 border ${
              processing ? "border-gray-600" : "border-white"
            }`}
          >
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">
              Department Summary
            </h5>
          </a>
        </div>
      )}

      {programming && (
        <>
          <div className="flex items-center  my-8  justify-center">
            {/* <Formik  initialValues={{
    bookid : '', designid : '', article : ''
}}

onSubmit={ async(values) => {
    try {
      getMachines(selectDepartment);
      const response = await axios.get(`${API_URL}/api/productionsummary`, {
        params: {
          department: selectDepartment,
          bookid: values.bookid,
          designid: values.designid,
          article: values.article
        }      
      });

const allProductionData = response.data["data"];


// setSummaryData(allProductionData);


// const machi = allProductionData.map((item) => item.machine);
 
// setMachines(machines);


toast.success("Filtered Successfully", )
      } catch (error) {
        console.error(error);
      }
}}
>
    <Form className='flex items-center gap-2'>
  
        <Field name="bookid"  placeholder="Book Id"  className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
        <ErrorMessage name="bookid" component="div" className="text-red-500" />
        <Field name="designid"  placeholder="Design Id"  className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
        <ErrorMessage name="designid" component="div" className="text-red-500" />
        <Field name="article"  placeholder="Article Number"   className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5' />
        <ErrorMessage name="article" component="div" className="text-red-500" />
        <Button type="submit">Filter</Button>
    </Form>
</Formik> */}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
            {programming &&
              machineDepartment?.map((item) => {
                const totalStich = summaryDataForMachine
                  .filter(
                    (data) =>
                      data.machine === item.title + "-" + item.head &&
                      data.status === "Pending"
                  )
                  .reduce((sum, data) => sum + (data.data["Stiches"] || 0), 0);
                console.log("total stich ", totalStich);
                const gte_36lk = totalStich >= 3600000;
                const gte_12lk_lt36 =
                  totalStich >= 1200000 && totalStich < 3600000;
                const gte_6lk_lt12 =
                  totalStich >= 600000 && totalStich < 1200000;

                const bgColor = gte_36lk
                  ? "bg-green-100"
                  : gte_12lk_lt36
                  ? "bg-yellow-100"
                  : gte_6lk_lt12
                  ? "bg-red-100"
                  : "bg-white";

                return (
                  <div
                    class={`block max-w-sm p-6 ${bgColor} border border-gray-200 rounded-lg shadow hover:bg-gray-100`}
                  >
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {item.title} - {item.head}
                    </h5>
                    <hr className="mt-2 mb-2" />
                    <p class=" font-medium text-[20px] text-gray-700 dark:text-gray-400 ">
                      {" "}
                      T-Stiches : {totalStich}
                    </p>

                    <div className="flex mt-2 gap-2 justify-end">
                      <Link
                        to={`/production/${selectDepartment}/${
                          item.title + "-" + item.head
                        }`}
                        // state={{
                        //   programming: programming,
                        //   department: selectDepartment,
                        // }}
                        className="flex items-center gap-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-2 rounded"
                      >
                        <BiDetail size={30} />
                      </Link>
                      <button
                        onClick={() => {
                          setOpenModal(true);
                          setDataModal({ title: item.title, head: item.head });
                        }}
                        className="flex items-center gap-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-2 rounded"
                      >
                        <IoMdQrScanner size={30} />
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}

      {isSummaryLoading ? (
        <MyLoader />
      ) : processing && !selectDepartment ? (
        <p>Please Select Department</p>
      ) : (
        processing && (
          <>
            <div className="w-full justify-end flex p-2">
              <div className="bg-white shadow-lg p-5 mt-5 text-md rounded text-black font-bold text-left  ">
                <p className="mb-4">
                  Total Pending Stich :{" "}
                  <span className="font-bold p-2  text-white bg-red-500 rounded-lg">
                    {" "}
                    {pendingtotalstiches.toFixed(4)}
                  </span>
                </p>
                <p>
                  Total Pending Round:{" "}
                  <span className="font-bold p-2  text-white bg-red-500 rounded-lg">
                    {totalpendingrounds}
                  </span>
                </p>
              </div>
            </div>

            <Formik
              initialValues={{ bookid: "", designid: "" }}
              validationSchema={Yup.object({
                bookid: Yup.string().required("Book ID is required"),
                designid: Yup.string().required("Design ID is required"),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const response = await axios.get(
                    `${API_URL}/api/productionsummary`,
                    {
                      params: {
                        department: selectDepartment,
                        article: values.article,
                        bookid: values.bookid,
                        designid: values.designid,
                        page: 1,
                        pageSize: pageSize,
                      },
                    }
                  );
                  console.log(response.data);
                  setSummaryData(response.data["data"]);
                  toast.success("Filtered data fetched successfully");
                } catch (error) {
                  console.error("Error fetching filtered data:", error);
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => {
                const selectedBook = books.find(
                  (book) => Object.keys(book)[0] === values.bookid
                );

                // If no book matches, set bookItems to an empty array
                let bookItems = [];
                if (selectedBook) {
                  // Get the values of the selected book
                  const bookValues = Object.values(selectedBook)[0];
                  // Check if bookValues is an array
                  if (Array.isArray(bookValues)) {
                    bookItems = bookValues;
                  } else if (typeof bookValues === "object") {
                    bookItems = Object.keys(bookValues);
                  }
                }

                return (
                  <Form>
                    <div className="flex gap-2 mb-3 w-full">
                      {/* <div>
              <Field
                as='select'
                name='date'
                className='text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5'
                placeholder='Search'
              >
                <option value=''>Select Time Period</option>
                <option value='1 Month'>1 Month</option>
                <option value='3 Months'>6 Months</option>
                <option value='1 Year'>1 Year</option>
                <option value='Life Time'>Life Time</option>
                </Field>
                <ErrorMessage name="date" component="div" className="text-red-500" />
                </div> */}

                      <div>
                        <select
                          as="select"
                          name="machine"
                          onChange={(e) => MachineFilterChange(e.target.value)}
                          className="text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                        >
                          <option value="" className="text-black">
                            Select Machines
                          </option>
                          {machineDepartment.map((book) => (
                            <option
                              className="text-black"
                              value={book.value + "-" + book.head}
                            >
                              {book.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <Field
                          as="select"
                          name="bookid"
                          className="text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                        >
                          <option value="" className="text-black">
                            Select Book Id
                          </option>
                          {books.map((book) => (
                            <option
                              className="text-black"
                              value={Object.keys(book)[0]}
                            >
                              {Object.keys(book)[0]}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="bookid"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div>
                        <Field
                          as="select"
                          name="designid"
                          onChange={(e) => {
                            getProductRecord(values.bookid, e.target.value);
                            values.designid = e.target.value;
                          }}
                          className="text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                        >
                          <option value="" className="text-black">
                            Select Design Id
                          </option>
                          {bookItems.map((booki, index) => (
                            <option
                              key={index}
                              value={booki}
                              className="text-black"
                            >
                              {booki}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="designid"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div>
                        <Field
                          type="text"
                          name="article"
                          className="text-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                          placeholder="Article"
                        />
                        <ErrorMessage
                          name="article"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        disabled={isSubmitting}
                      >
                        Filter
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>

            <div class="relative ">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
                {filteredSummaryData?.map((row, index) => (
                  <>
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
                      <tr className="text-center">
                        <th scope="col" class=" px-6 py-3">
                          #
                        </th>
                        <th scope="col" class=" px-6 py-3">
                          Machine
                        </th>
                        <th scope="col" class="px-6 py-3">
                          B#
                        </th>
                        <th scope="col" class="px-6 py-3">
                          D#
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Fabric
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Articles
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Created
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Round Number
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Status
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Head
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Stiches
                        </th>

                        {/* <th scope="col" class="px-6 py-3">
                          Quantity
                        </th> */}
                        <th scope="col" class="px-6 py-3">
                          Issued Round
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Total Round
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {row.createdat_array.map((createdAt, index) => {
                        row.roundno_array.sort((a, b) => a - b);

                        return (
                          <tr className=" border-gray-300 border-b  text-center ">
                            <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                              {index + 1}
                            </td>

                            {index === 0 ? (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                                {row._id.machine}
                              </td>
                            ) : (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                            )}

                            {row.bookid.indexOf(row.bookid[index]) === index ? (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                                {row.bookid[index]}{" "}
                              </td>
                            ) : (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                            )}

                            {row.designid.indexOf(row.designid[index]) ===
                            index ? (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                                {row.designid[index]}{" "}
                              </td>
                            ) : (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                            )}

                            {row.data_Fabric.indexOf(row.data_Fabric[index]) ===
                            index ? (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                                {row.data_Fabric[index]}
                              </td>
                            ) : (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                            )}

                            {row.data_Article.indexOf(
                              row.data_Article[index]
                            ) === index ? (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                                {" "}
                                {row.data_Article[index] +
                                  " " +
                                  row.data_Article_type[index]}{" "}
                              </td>
                            ) : (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                            )}

                            <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                              {formatDateTime(createdAt, "DD/MM/YYYY hh:mm A")}{" "}
                            </td>

                            <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                              {row.roundno_array[index]}{" "}
                            </td>

                            <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black flex justify-center">
                              <div
                                className={`w-16 px-3 py-1 ${
                                  row.status_array[index] === "Active"
                                    ? "bg-green-500 text-white"
                                    : row.status_array[index] === " Pending"
                                    ? "bg-yellow-500 text-black"
                                    : "bg-red-500  text-white"
                                }  rounded-xl`}
                              >
                                {row.status_array[index] == "Pending"
                                  ? "In"
                                  : "Out"}
                              </div>{" "}
                            </td>

                            {row.data_Head.indexOf(row.data_Head[index]) ===
                            index ? (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                                {row.data_Head[index]}
                              </td>
                            ) : (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                            )}

                            {row.data_Stiches.indexOf(
                              row.data_Stiches[index]
                            ) === index ? (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                                {row.data_Stiches[index]}
                              </td>
                            ) : (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                            )}

                            {/* {row.data_Quantity.indexOf(
                              row.data_Quantity[index]
                            ) === index ? (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                                {row.data_Quantity[index]}
                              </td>
                            ) : (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                            )} */}

                            {row.data_issued_round.indexOf(
                              row.data_issued_round[index]
                            ) === index ? (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                                {row.data_issued_round[index]}
                              </td>
                            ) : (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                            )}

                            {index === 0 ? (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black">
                                {row.count}
                              </td>
                            ) : (
                              <td className="whitespace-nowrap font-medium text-gray-900 dark:text-black"></td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                ))}

                {summaryData.length === 0 && (
                  <tbody>
                    <tr>
                      <td class="px-6 py-4" colSpan={7}>
                        No data found
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>

            {/* {summaryData.length > 0 && (
            <Pagination
            theme={PaginationTheme}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          )} */}
          </>
        )
      )}

      {/* /production/${selectDepartment}/${item.title+"-"+item.head} */}
    </div>
  );
}

export default Index;
