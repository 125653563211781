import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(false);
  const [isTokenValidated, setIsTokenValidated] = useState(false);

  useEffect(() => {
    const authToken = localStorage.getItem("token");

    const fetchData = async () => {
      try {
        if (authToken) {
          const response = await fetch(API_URL + "/auth/protected", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: authToken,
            },
          });

          if (response.ok) {
            setAuth(true);
          } else {
            setAuth(false);
            navigate("/login");
          }
        } else {
          setIsTokenValidated(true);
        }
      } catch (error) {
        console.error("Error validating token:", error);
        setAuth(false);
        navigate("/login");
      } finally {
        setIsTokenValidated(true);
      }
    };

    fetchData();

    // Clean up function to prevent navigation after unmounting
    return () => {};
  }, [navigate]);

  // Render children if token is validated, otherwise return null
  return isTokenValidated && auth ? <>{children}</> : navigate("/login");
};

export default AuthGuard;
