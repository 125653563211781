import React, { useRef } from 'react'
import { useParams } from 'react-router-dom';
import Barcode from 'react-barcode';
import './print.css'; 
import ReactToPrint from 'react-to-print';
import { QRCode } from 'react-qrcode-logo';

function BarcodePrint() {
  const params = useParams();

  const ref = useRef();
  return (
    <div>
      <ReactToPrint 
      content={() => ref.current}
      
      trigger={() => (
        <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
          Print
        </button>
      )}
    />
    <div ref={ref}>
      {Array.from({ length: params.round }).map((_, index) => (
        <center key={index}>
          <div id='barcode-div'> 
        
            <QRCode size={75}  removeQrCodeBehindLogo={true}  value={decodeURIComponent(params.data)+","+(index+1)} />
            <small id='article' >{params.article} {decodeURIComponent(params.data)+","+(index+1)}</small>
            
            {/* <Barcode
              width={1}
              height={40}
              displayValue={true}
              value={decodeURIComponent(params.data)+","+(index+1)}
              id={`barcode-image`}
            /> */}
          </div>
        </center>
      ))}
    </div>
    
  </div>
  );
}


export default BarcodePrint


// import React, { useRef } from 'react';
// import { useParams } from 'react-router-dom';
// import Barcode from 'react-barcode';
// import './print.css';

// function BarcodePrint() {
//   const params = useParams();
//   const printRef = useRef();

//   // Function to handle printing for the current barcode
//   const handlePrint = () => {
//     const printWindow = window.open('', '_blank');
//     printWindow.document.write(`<html><head><title>Barcode Print</title></head>
//     <style>
//     @media print {
//       svg{
//         height: 400px;
//       }

//       #article {
//         font-size: 15px;
//       }
//     }</style>
//     <body>`);
//     printWindow.document.write(printRef.current.innerHTML);
//     printWindow.document.write('</body></html>');
//     printWindow.document.close();
//     printWindow.print();
//   };

//   // Render barcode pages based on the number of rounds
//   return (
//     <div>
//       <button id='printButton' className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={handlePrint}>Print</button>
//       <div ref={printRef}>
//         <center>
//           {Array.from({ length: parseInt(params.round) }).map((_, index) => (
//             <div key={index} className="barcode-page">
//               <p id='article'>{params.article}</p>
//               <div id='barcode-image'><Barcode displayValue={false} value={decodeURIComponent(params.data) + ',' + parseInt(index + 1)} /></div>
//             </div>
//           ))}
//         </center>
//       </div>
//     </div>
//   );
// }

// export default BarcodePrint;
