import React, { useEffect, useState } from 'react'
import SubHeading from '../../components/SubHeading';
import useScanDetection from 'use-scan-detection-react18';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;
function CuttingScanner() {

  const [barCode , setBarCode] = useState('no record');

  const params = useParams();

  useScanDetection({
    onComplete: async (barcode) =>  {
   
     const Code = barcode.replace(/Shift/g, '');
     //splite Code by ,
     const data = Code.split(',');
     const bookid = data[0];
     const designid = data[1];
     const id = data[2];
     const issueno = data[3];
     setBarCode(Code);

     if(params.key === bookid){

       await axios.post(`${API_URL}/api/cutting/issue_round`, {bookid,designid,id,issueno})
       .then((result)=>{
        toast.success("Issued Successfully", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      })
      .catch((error)=>{
        toast.error(error.response.data.error, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      })

    }
    else{
      toast.error("Invalid Book number")
    }


  }
   
  });


  return (
    <>
    <div className='flex justify-center'>
      <SubHeading title={"Cutting Scanner"} />
    </div>
 
    <div className='flex justify-center'>
      <img src='/gif/scanner.gif' height={300} width={300} />
    </div> 
    <h1>{barCode}</h1>  
    </>
  )
}

export default CuttingScanner

