import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './Loader.css'; // Import the CSS file for custom styles

const MyLoader = ({ className }) => {
  return (
    <div className={`loader-container ${className}`}  >
      <CircularProgress className="spinner" />
    </div>
  );
};

export default MyLoader;
