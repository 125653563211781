import React, { useEffect, useState } from 'react';
import { Avatar, Dropdown, Navbar } from 'flowbite-react';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';



const NavbarComponent = () => {

  const [user, setUser] = useState({});
  


  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if authentication token exists in localStorage
    const authToken = localStorage?.getItem('token');
    if (authToken) {
      const decodedUser = jwtDecode(authToken);
        setUser(decodedUser);
      // You can validate the token here if needed
      setIsAuthenticated(true);
    }
  }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <Navbar className="dark:bg-white" fluid rounded>
      <Link to="/">
        <Navbar.Brand>
          <img src="/new/nav-main-logo.png" className="mr-3 h-6 sm:h-9" alt="Logo" />
          {/* <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-black">AL Hayat Emboridery</span> */}
        </Navbar.Brand>
      </Link>

      {isAuthenticated && (
        <div className="flex md:order-2">
          <Dropdown arrowIcon={false} inline label={<Avatar alt="User settings" img="https://flowbite.com/docs/images/people/profile-picture-5.jpg" rounded />}>
            <Dropdown.Item>Profile</Dropdown.Item>
            {(user?.user?.role == 'admin' || user?.role == 'admin') &&
            <Dropdown.Item>
              <Link to="/Setting">Setting</Link>
            </Dropdown.Item>
            }
            <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
          </Dropdown>
       
        </div>
      )}
    </Navbar>
  );
};

export default NavbarComponent;
