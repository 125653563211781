
import React,{ useEffect, useState }  from 'react'
import Card from '../../../components/Card'
import SubHeading from '../../../components/SubHeading'
import SearchInput from '../../../components/SearchInput'
import { CiCirclePlus } from "react-icons/ci";
import { Button, Checkbox, Label, Modal, Pagination, TextInput} from 'flowbite-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { IoIosRefresh } from "react-icons/io";
import { ModalTheme } from '../../../theme/ModalTheme';
import { PaginationTheme } from '../../../theme/PaginationTheme';
const API_URL = process.env.REACT_APP_API_URL;

const Production = () => {
  
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState('');
  const [productionData, setProductionData] = useState([]);
  const [search, setSearch] = useState('');


  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [totalPages, setTotalPages] = useState(1);


  function onCloseModal() {
    setOpenModal(false);
    setTitle('');
  }
  const addProduction = async (title) => {
 
    try {
      const response = await axios.post(`${API_URL}/api/punching/production`, {
        data: {
          title: "B#"+title
        }
      });
      setOpenModal(false);
    
        toast.success("Record Added Successfully", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      
     
      getProduction();
     
    } catch (error) {
     
      toast.error(error.response.data.error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const indexOfLastPost = currentPage * pageSize;
  const indexOfFirstPost = indexOfLastPost - pageSize;
  const currentProductionData = productionData.slice(indexOfFirstPost, indexOfLastPost);


  const getProduction = async (searchValue) => {
    try {
      const response = await axios.get(`${API_URL}/api/punching/production`,
        {
          params: {
            search : searchValue ? "B#"+searchValue : ""
          }
        }
      );
      setProductionData(response.data.data[0].production.reverse());
      setTotalPages(Math.ceil(response.data.data[0].production.length / pageSize));
      
    
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setTitle("B#");

    getProduction()
  }, []);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [BookNoForDelete,setBookNoForDelete] = useState();
  const [_idNo, set_idNo] = useState(0);

  const [BNo, setBNo] = useState("");

  const deleteData = async (title) => {
    try {
      const response = await axios.delete(`${API_URL}/api/punching/production/`+encodeURIComponent(title));
      
      if (response.status === 200) {
        toast.success("Record Deleted Successfully", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } else {
        console.error("Deletion failed with status:", response.status);
      }
      setOpenDeleteModal(false);
      setBookNoForDelete("");  
      getProduction()
    } catch (error) {
      console.error(error);
    }
  };




  return (
    <div>
     <SubHeading title={'Production'} />
    
   <div className="flex gap-2 justify-center gird grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
    <Button color="blue" className=' h-14 mb-2' onClick={() => setOpenModal(true)}><CiCirclePlus size={37}/></Button>
    <Button color="blue" className=' h-14 mb-2' onClick={() => {setSearch("");getProduction("")}}><IoIosRefresh size={37}/></Button>
  
    <div className='mb-8 w-96 '>
      
    <form onSubmit={(e) => {e.preventDefault(); getProduction(search)}}>   
        <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only ">Search</label>
        <div class="relative">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
            </div>
            <input value={search} onChange={(e) => setSearch(e.target.value)} required type="search" id="default-search" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500  " placeholder="Search..." />
            <button type="submit" class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 ">Search</button>
        </div>
    </form>
    </div>

 
    </div>

      {/* delete prompt */}
      
<Modal theme={ModalTheme} show={openDeleteModal} size="md" onClose={() => setOpenDeleteModal(false)} popup>
        <Modal.Header className=''  />
        <Modal.Body className=''>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 " />
            <h3 className="mb-5 text-lg font-normal text-gray-500 ">
               Are you sure you want to remove this record enter {BNo} ?
            </h3>
            <input type="text" value={BookNoForDelete} onChange={(e) => setBookNoForDelete(e.target.value)} required className='mb-5 h-10 w-30 border border-gray-300 '/>
            <div className="flex justify-center gap-4">
              <Button color="failure"disabled={(!BookNoForDelete || BookNoForDelete.trim() !== BNo)} onClick={() =>  deleteData(BNo)}>
                {"Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => setOpenDeleteModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end */}


    <Modal theme={ModalTheme} show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <Formik
            onSubmit={
              (values) => {
                
               addProduction(values.title);
              }
            }
            initialValues={{
              title: "",
            }}
            validationSchema={
              Yup.object({
                title: Yup.string().min(1, "Book number cannot be less than 1").max(5, "Book number cannot be more than 5 characters").required("Title is required"),
              })
            }
          >
          <Form >

          
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900">Enter Booking Number</h3>
            <div>
              
              <div className='flex justify-center'>
              <p className='text-2xl  mt-1 px-2'>{"B#"}</p>
              <Field
                id="title"
                type="number"
                placeholder="Enter Booking Number"
                name='title'
                className="w-full h-10 input border border-gray-300 rounded-lg input-bordered focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
              />
              </div>
              
              <ErrorMessage name='title' component="div" className='text-red-500' />
            </div>
           
           
            <div className="w-full">
              <Button type="submit">Add</Button>
            </div>

          </div>
          </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    
    
      <div className=" px-10 flex flex-wrap gap-2 justify-center items-center">
      {currentProductionData.map((production, index) => {
          const key = Object.keys(production)[0];
          return (
            <div key={index} className="flex justify-center items-center">
              <Card showDeleteIcon={true} url={`/Punching/Production/${ encodeURIComponent(key)}`} onClick={() => {setOpenDeleteModal(true); setBNo(key);}} value={`${key}`} />
            </div>
          );
        })}
</div>
<Pagination
theme={PaginationTheme}
className='mt-5' currentPage={currentPage} totalPages={totalPages} onPageChange={(page) => setCurrentPage(page)} />
    

    </div>
  )
}

export default Production