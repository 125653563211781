const ButtonGroup = ({ items, selected, onChange }) => {
    if(!selected) selected = items[0].id || items[0].key;
    return(
        <div className="flex gap-5">
            {items?.map(item => {
                const id = item.id || item.key;
                if(id === selected){
                    return(
                        <div title="Already Selected" className="bg-white rounded-md border border-gray-600 cursor-pointer min-h-[70px] px-4 flex justify-center items-center min-w-[200px] text-xl text-black font-bold">
                            {item.title || item?.name}
                        </div>
                    )
                }
                return(
                    <div onClick={()=>onChange(item.id)} className="bg-white/90 rounded-md cursor-pointer border border-white min-h-[70px] px-4 flex justify-center items-center min-w-[200px] text-xl text-gray-700 font-bold">
                        {item.title || item?.name}
                    </div>
                )
            })}
        </div>
    )
}

export default ButtonGroup;